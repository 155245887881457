import React from 'react';

const translation = {
	"noInternetConnection": {
		"en": "No interntet connection",
		"es": "Sin conexión interntet",
		"zh": "没有互联网连接",
		"hi": "कोई इंटरनेट कनेक्शन नहीं",
		"ar": "لا يوجد اتصال انترنت",
		"pt": "Sem conexão com a internet",
		"bn": "কোন ইন্টারনেট সংযোগ নেই",
		"ru": "Нет интернтета связи",
		"ja": "インターンテット接続なし",
		"de": "Keine Internetverbindung",
		"ms": "Tiada sambungan interntet",
		"vi": "Không có kết nối interntet",
		"fr": "Pas de connexion internet",
		"tr": "İnternet bağlantısı yok",
		"it": "Nessuna connessione a Internet",
		"th": "ไม่มีการเชื่อมต่ออินเทอร์เน็ต",
		"fa": "بدون اتصال بین شبکه ای",
		"pl": "Brak połączenia internetowego",
		"uk": "Немає інтернет -з'єднання",
		"uz": "Internetga ulanish yo'q",
		"ro": "Nu există conexiune interntet",
		"az": "İnternet bağlantısı yoxdur",
		"hr": "Nema interne veze",
		"bg": "Няма вътрешна връзка",
		"sr": "Нема интерне везе",
		"hy": "Միջցանցային կապ չկա",
		"eu": "Ez dago internetet konexiorik",
		"be": "Няма міжтэрэнтавай сувязі",
		"ca": "No hi ha connexió interna",
		"cs": "Žádné připojení k internetu",
		"da": "Ingen internetforbindelse",
		"nl": "Geen internetverbinding",
		"et": "Interneti -ühendust pole",
		"tl": "Walang koneksyon sa interntet",
		"gl": "Non hai conexión internetet",
		"ka": "ინტერენტული კავშირი არ არის",
		"el": "Δεν υπάρχει διασύνδεση μεταξύ δικτύων",
		"hu": "Nincs internetkapcsolat",
		"is": "Engin nettenging",
		"lv": "Nav interneta savienojuma",
		"lt": "Nėra interneto ryšio",
		"mk": "Нема интернет конекција",
		"no": "Ingen internettforbindelse",
		"sk": "Žiadne internetové pripojenie",
		"sl": "Brez interne povezave",
		"sv": "Ingen internetanslutning",
		"fi": "Ei Internet -yhteyttä",
		"af": "Geen internetverbinding nie",
		"sq": "Asnjë lidhje ndërlidhëse",
		"am": "ምንም የበይነመረብ ግንኙነት የለም",
		"my": "အင်တာနက်ဆက်သွယ်မှုမရှိပါ",
		"gu": "કોઈ ઇન્ટરનેટ કનેક્શન નથી",
		"he": "אין חיבור אינטרנט",
		"id": "Tidak ada koneksi internet",
		"kn": "ಇಂಟರ್‌ನೆಟ್ ಸಂಪರ್ಕವಿಲ್ಲ",
		"kk": "Интернет байланысы жоқ",
		"km": "គ្មានការតភ្ជាប់អ៊ីនធឺណិតទេ",
		"ky": "Интернет байланышы жок",
		"lo": "ບໍ່ມີການເຊື່ອມຕໍ່ອິນເຕີເນັດ",
		"ml": "ഇന്റർനെറ്റ് കണക്ഷൻ ഇല്ല",
		"mr": "इंटरनेट कनेक्शन नाही",
		"mn": "Интернэт холболт байхгүй байна",
		"ne": "कुनै इन्टरनेट जडान छैन",
		"pa": "ਕੋਈ ਇੰਟਰਨੈਟ ਕਨੈਕਸ਼ਨ ਨਹੀਂ",
		"si": "ඉන්ටර්නෙට් සම්බන්ධතාවයක් නොමැත",
		"sw": "Hakuna muunganisho wa ndani",
		"ta": "இன்டர்நெட் இணைப்பு இல்லை",
		"te": "ఇంటర్‌నెట్ కనెక్షన్ లేదు",
		"ur": "کوئی انٹرنیٹ کنکشن نہیں۔",
		"zu": "Alukho uxhumano lwe-interntet",
	},
	"pleaseLogin": {
		"en": "Please login",
		"es": "Por favor Iniciar sesión",
		"zh": "请登录",
		"hi": "कृपया लॉगिन करें",
		"ar": "الرجاء تسجيل الدخول",
		"pt": "Por favor entre",
		"bn": "দয়া করে লগইন করুন",
		"ru": "Пожалуйста, войдите",
		"ja": "ログインしてください",
		"de": "Bitte loggen Sie sich ein",
		"ms": "Sila daftar masuk",
		"vi": "Vui lòng hãy đăng nhập",
		"fr": "Veuillez vous connecter",
		"tr": "Lütfen giriş yapın",
		"it": "Accedete, per favore",
		"th": "กรุณาเข้าสู่ระบบ",
		"fa": "لطفا وارد شوید",
		"pl": "Proszę się zalogować",
		"uk": "Увійдіть, будь ласка",
		"uz": "Iltimos, kiring",
		"ro": "Va rugam sa va logati",
		"az": "Zəhmət olmasa daxil olun",
		"hr": "Molimo prijavite se",
		"bg": "Моля влезте",
		"sr": "Молим вас пријавите се",
		"hy": "Խնդրում ենք մուտք գործել",
		"eu": "Mesedez saioa hasi",
		"be": "Калі ласка, увайдзіце",
		"ca": "Si us plau inicieu sessió",
		"cs": "Prosím přihlašte se",
		"da": "Vær venlig at logge ind",
		"nl": "Log alsjeblieft in",
		"et": "Palun logige sisse",
		"tl": "Paki-login",
		"gl": "Inicia sesión",
		"ka": "Გთხოვთ შეხვიდეთ",
		"el": "ΠΑΡΑΚΑΛΩ ΣΥΝΔΕΘΕΙΤΕ",
		"hu": "Kérjük jelentkezzen be",
		"is": "Vinsamlegast skráðu þig inn",
		"lv": "Lūdzu, piesakieties",
		"lt": "Prašome prisijungti",
		"mk": "Ве молиме најавете се",
		"no": "Vennligst Logg inn",
		"sk": "Prosím prihláste sa",
		"sl": "Prosim prijavite se",
		"sv": "Vänligen logga in",
		"fi": "Kirjaudu sisään, ole hyvä",
		"af": "Meld asseblief aan",
		"sq": "Ju lutemi identifikohuni",
		"am": "እባክዎ ይግቡ",
		"my": "ကျေးဇူးပြု၍ ဝင်ပါ",
		"gu": "કૃપા કરીને પ્રવેશ કરો",
		"he": "אנא התחבר",
		"id": "Silahkan masuk",
		"kn": "ದಯವಿಟ್ಟು ಲಾಗಿನ್ ಆಗಿ",
		"kk": "Жүйеге кіріңіз",
		"km": "សូមចូល",
		"ky": "Сураныч, кириңиз",
		"lo": "ກະລຸນາເຂົ້າສູ່ລະບົບ",
		"ml": "ദയവായി ലോഗിൻ ചെയ്യുക",
		"mr": "कृपया लॉगिन करा",
		"mn": "Нэвтрэнэ үү",
		"ne": "कृपया लगइन गर्नुहोस्",
		"pa": "ਕਿਰਪਾ ਕਰਕੇ ਲਾਗਇਨ ਕਰੋ",
		"si": "කරුණාකර පිවිසෙන්න",
		"sw": "Tafadhali ingia",
		"ta": "தயவு செய்து உள்நுழைக",
		"te": "దయచేసి లాగిన్ చేయండి",
		"ur": "برائے مہربانی لاگ ان کریں",
		"zu": "Sicela ungene ngemvume",
	},
	"serverIsTemporarilyShutDownPleaseWaitForAMoment": {
		"en": "Server is temporarily shut down. Please wait for a moment.",
		"es": "El servidor está apagado temporalmente. Espere un momento por favor.",
		"zh": "服务器暂时关闭。请稍等。",
		"hi": "सर्वर अस्थायी रूप से बंद है। कृप्या कुछ क्षण प्रतीक्षा करें।",
		"ar": "تم إغلاق الخادم مؤقتًا. يرجى الانتظار لحظة.",
		"pt": "O servidor está temporariamente encerrado. Por favor aguarde um momento.",
		"bn": "সার্ভার সাময়িকভাবে বন্ধ। অনুগ্রহ করে কিছুক্ষণ অপেক্ষা করুন.",
		"ru": "Сервер временно отключен. Пожалуйста, подождите секунду.",
		"ja": "サーバーは一時的にシャットダウンされます。ちょっと待ってください。",
		"de": "Server ist vorübergehend heruntergefahren. Bitte warten Sie einen Moment.",
		"ms": "Pelayan dimatikan buat sementara waktu. Tunggu sebentar.",
		"vi": "Máy chủ tạm thời ngừng hoạt động. Xin hãy đợi một lát.",
		"fr": "Le serveur est temporairement arrêté. Veuillez patienter un instant.",
		"tr": "Sunucu geçici olarak kapatılmıştır. Lütfen biraz bekleyin.",
		"it": "Il server è temporaneamente spento. Per favore aspetta un momento.",
		"th": "เซิร์ฟเวอร์ถูกปิดชั่วคราว กรุณารอสักครู่",
		"fa": "سرور موقتاً خاموش است لطفا یک لحظه صبر کنید.",
		"pl": "Serwer jest tymczasowo wyłączony. Proszę chwilę zaczekać.",
		"uk": "Сервер тимчасово вимкнено. Будь ласка, почекайте хвилинку.",
		"uz": "Server vaqtincha o'chirilgan. Iltimos, bir daqiqa kutib turing.",
		"ro": "Serverul este oprit temporar. Te rog asteapta un moment.",
		"az": "Server müvəqqəti olaraq bağlanır. Zəhmət olmasa bir an gözləyin.",
		"hr": "Poslužitelj je privremeno isključen. Molimo pričekajte trenutak.",
		"bg": "Сървърът е временно изключен. Моля, изчакайте за момент.",
		"sr": "Сервер је привремено искључен. Сачекајте тренутак.",
		"hy": "Սերվերը ժամանակավորապես անջատված է: Խնդրում ենք մի պահ սպասել:",
		"eu": "Zerbitzaria aldi baterako itzalita dago. Mesedez itxaron une batez.",
		"be": "Сервер часова адключаны. Калі ласка, пачакайце хвілінку.",
		"ca": "El servidor està tancat temporalment. Espereu un moment.",
		"cs": "Server je dočasně vypnut. Počkejte chvíli.",
		"da": "Serveren lukkes midlertidigt. Vent et øjeblik.",
		"nl": "Server is tijdelijk uitgeschakeld. Wacht aub een moment.",
		"et": "Server on ajutiselt välja lülitatud. Palun oota hetk.",
		"tl": "Pansamantalang nakasara ang server. Mangyaring maghintay sandali.",
		"gl": "O servidor está apagado temporalmente. Agarda un momento.",
		"ka": "სერვერი დროებით გამორთულია. გთხოვთ დაელოდოთ ცოტა ხანს.",
		"el": "Ο διακομιστής κλείνει προσωρινά. Παρακαλώ περιμένετε λίγο.",
		"hu": "A szerver ideiglenesen le van állítva. Kérjük, várjon egy pillanatot.",
		"is": "Þjóni er lokað tímabundið. Vinsamlegast bíddu í smá stund.",
		"lv": "Serveris īslaicīgi tiek izslēgts. Lūdzu, uzgaidiet brīdi.",
		"lt": "Serveris laikinai išjungtas. Prašome palaukti.",
		"mk": "Серверот е привремено исклучен. Ве молиме почекајте малку.",
		"no": "Serveren stenges midlertidig. Vent et øyeblikk.",
		"sk": "Server je dočasne vypnutý. Počkajte chvíľu.",
		"sl": "Strežnik je začasno zaustavljen. Prosim, počakajte trenutek.",
		"sv": "Servern stängs tillfälligt av. Vänta ett ögonblick.",
		"fi": "Palvelin on tilapäisesti suljettu. Odota hetki.",
		"af": "Bediener word tydelik gesluit. Wag 'n rukkie.",
		"sq": "Serveri është mbyllur përkohësisht. Ju lutemi prisni një moment.",
		"am": "አገልጋይ ለጊዜው ተዘግቷል። እባክዎን ትንሽ ይጠብቁ።",
		"my": "Server ကိုယာယီပိတ်ထားသည်။ ကျေးဇူးပြု၍ ခဏစောင့်ပါ။",
		"gu": "સર્વર અસ્થાયી રૂપે બંધ છે. કૃપા કરીને એક ક્ષણ માટે રાહ જુઓ.",
		"he": "השרת נסגר באופן זמני. בבקשה תחכה רגע.",
		"id": "Server dimatikan sementara. Mohon tunggu sebentar.",
		"kn": "ಸರ್ವರ್ ಅನ್ನು ತಾತ್ಕಾಲಿಕವಾಗಿ ಸ್ಥಗಿತಗೊಳಿಸಲಾಗಿದೆ. ದಯವಿಟ್ಟು ಒಂದು ಕ್ಷಣ ಕಾಯಿರಿ.",
		"kk": "Сервер уақытша өшірілген. Бір сәт күтіңіз.",
		"km": "ម៉ាស៊ីនមេត្រូវបានបិទជាបណ្តោះអាសន្ន។ សូមរង់ចាំបន្តិច។",
		"ky": "Сервер убактылуу өчүрүлгөн. Сураныч, бир аз күтө туруңуз.",
		"lo": "ເຊີບເວີປິດຊົ່ວຄາວ. ກະລຸນາລໍຖ້າບຶດນຶ່ງ.",
		"ml": "സെർവർ താൽക്കാലികമായി അടച്ചു. ദയവായി ഒരു നിമിഷം കാത്തിരിക്കുക.",
		"mr": "सर्व्हर तात्पुरता बंद आहे. कृपया एक क्षण थांबा.",
		"mn": "Сервер түр хаагдсан байна. Түр хүлээнэ үү.",
		"ne": "सर्भर अस्थायी रूपमा बन्द छ। कृपया एक पल पर्खनुहोस्।",
		"pa": "ਸਰਵਰ ਅਸਥਾਈ ਤੌਰ ਤੇ ਬੰਦ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਪਲ ਲਈ ਉਡੀਕ ਕਰੋ.",
		"si": "සේවාදායකය තාවකාලිකව වසා දමයි. කරුණාකර මොහොතක් ඉන්න.",
		"sw": "Seva imefungwa kwa muda. Tafadhali subiri kwa muda.",
		"ta": "சேவையகம் தற்காலிகமாக மூடப்பட்டது. தயவுசெய்து சிறிது நேரம் காத்திருங்கள்.",
		"te": "సర్వర్ తాత్కాలికంగా ఆపివేయబడింది. దయచేసి ఒక్క క్షణం వేచి ఉండండి.",
		"ur": "سرور عارضی طور پر بند ہے۔ براہ کرم ایک لمحے کا انتظار کریں۔",
		"zu": "Iseva ivaliwe okwesikhashana. Sicela ulinde isikhashana.",
	},
	"legal": {
		"en": "Legal",
		"es": "Legal",
		"zh": "合法的",
		"hi": "कानूनी",
		"ar": "قانوني",
		"pt": "Jurídico",
		"bn": "আইনগত",
		"ru": "Юридический",
		"ja": "法的",
		"de": "Rechtliches",
		"ms": "Undang-undang",
		"vi": "Hợp pháp",
		"fr": "Légal",
		"tr": "Yasal",
		"it": "legale",
		"th": "ถูกกฎหมาย",
		"fa": "مجاز",
		"pl": "Prawny",
		"uk": "Юридичні",
		"uz": "Huquqiy",
		"ro": "Legal",
		"az": "Qanuni",
		"hr": "Pravna",
		"bg": "Правни",
		"sr": "Правни",
		"hy": "Իրավաբանական",
		"eu": "Legezkoa",
		"be": "Юрыдычныя",
		"ca": "Legal",
		"cs": "Právní",
		"da": "gyldige",
		"nl": "legaal",
		"et": "Seaduslik",
		"tl": "Ligal",
		"gl": "Legal",
		"ka": "იურიდიული",
		"el": "Νομικός",
		"hu": "Jogi",
		"is": "Löglegt",
		"lv": "Juridiski",
		"lt": "Teisėta",
		"mk": "Правно",
		"no": "Lovlig",
		"sk": "Právne",
		"sl": "Pravna",
		"sv": "Rättslig",
		"fi": "Lakisääteinen",
		"af": "Wettig",
		"sq": "Juridike",
		"am": "ሕጋዊ",
		"my": "ဥပဒေရေးရာ",
		"gu": "કાયદેસર",
		"he": "משפטי",
		"id": "Hukum",
		"kn": "ಕಾನೂನು",
		"kk": "Заңды",
		"km": "ស្របច្បាប់",
		"ky": "Юридикалык",
		"lo": "ຖືກກົດາຍ",
		"ml": "നിയമപരമായ",
		"mr": "कायदेशीर",
		"mn": "Хууль ёсны",
		"ne": "कानूनी",
		"pa": "ਕਨੂੰਨੀ",
		"si": "නීතිමය",
		"sw": "Kisheria",
		"ta": "சட்ட",
		"te": "చట్టపరమైన",
		"ur": "قانونی",
		"zu": "Ezomthetho",
	},
	"termsOfServices": {
		"en": "Terms of Services",
		"es": "Términos de servicios",
		"zh": "服务条款",
		"hi": "सेवाओं की शर्तें",
		"ar": "شروط الخدمة",
		"pt": "Termos de serviços",
		"bn": "পরিষেবার শর্তাবলী",
		"ru": "Условия предоставления услуг",
		"ja": "利用規約",
		"de": "Nutzungsbedingungen",
		"ms": "Syarat Perkhidmatan",
		"vi": "Điều khoản Dịch vụ",
		"fr": "Conditions de services",
		"tr": "Hizmet şartları",
		"it": "Termini di servizio",
		"th": "เงื่อนไขการบริการ",
		"fa": "شرایط خدمات",
		"pl": "Warunki korzystania z usług",
		"uk": "Умови надання послуг",
		"uz": "Xizmat ko'rsatish shartlari",
		"ro": "Termeni și condiții",
		"az": "Xidmət Şərtləri",
		"hr": "Uvjeti usluga",
		"bg": "Общи условия",
		"sr": "Услови услуге",
		"hy": "Termառայությունների պայմանները",
		"eu": "Zerbitzuen baldintzak",
		"be": "Умовы прадастаўлення паслуг",
		"ca": "Condicions del servei",
		"cs": "Podmínky služeb",
		"da": "Betingelser for tjenester",
		"nl": "Servicevoorwaarden",
		"et": "Teenuste tingimused",
		"tl": "Mga Tuntunin ng Serbisyo",
		"gl": "Condicións de servizo",
		"ka": "მომსახურების პირობები",
		"el": "Όροι Παροχής Υπηρεσιών",
		"hu": "Szolgáltatási feltételek",
		"is": "Þjónustuskilmálar",
		"lv": "Pakalpojumu noteikumi",
		"lt": "Paslaugų sąlygos",
		"mk": "Услови на услуги",
		"no": "Vilkår for tjenester",
		"sk": "Podmienky služieb",
		"sl": "Pogoji storitev",
		"sv": "Användarvillkor",
		"fi": "Palveluehdot",
		"af": "Diensvoorwaardes",
		"sq": "Kushtet e Shërbimeve",
		"am": "የአገልግሎት ውሎች",
		"my": "ဝန်ဆောင်မှုဆိုင်ရာစည်းမျဉ်းများ",
		"gu": "સેવાની શરતો",
		"he": "תנאי שירות",
		"id": "Persyaratan Layanan",
		"kn": "ಸೇವೆಗಳ ನಿಯಮಗಳು",
		"kk": "Қызмет көрсету шарттары",
		"km": "ល័ក្ខខ័ណ្ឌនៃសេវាកម្ម",
		"ky": "Тейлөө шарттары",
		"lo": "ເງື່ອນໄຂການໃຫ້ບໍລິການ",
		"ml": "സേവന നിബന്ധനകൾ",
		"mr": "सेवा अटी",
		"mn": "Үйлчилгээний нөхцөл",
		"ne": "सेवाका सर्तहरु",
		"pa": "ਸੇਵਾਵਾਂ ਦੇ ਨਿਯਮ",
		"si": "සේවා කොන්දේසි",
		"sw": "Masharti ya Huduma",
		"ta": "சேவை விதிமுறைகள்",
		"te": "సేవల నిబంధనలు",
		"ur": "خدمات کی شرائط",
		"zu": "Imibandela Yokusebenza",
	},
	"privacyPolicy": {
		"en": "Privacy Policy",
		"es": "Política de privacidad",
		"zh": "隐私政策",
		"hi": "गोपनीयता नीति",
		"ar": "سياسة الخصوصية",
		"pt": "Política de Privacidade",
		"bn": "গোপনীয়তা নীতি",
		"ru": "Политика конфиденциальности",
		"ja": "プライバシーポリシー",
		"de": "Datenschutz-Bestimmungen",
		"ms": "Dasar Privasi",
		"vi": "Chính sách bảo mật",
		"fr": "Politique de confidentialité",
		"tr": "Gizlilik Politikası",
		"it": "politica sulla riservatezza",
		"th": "นโยบายความเป็นส่วนตัว",
		"fa": "سیاست حفظ حریم خصوصی",
		"pl": "Polityka prywatności",
		"uk": "Політика конфіденційності",
		"uz": "Maxfiylik siyosati",
		"ro": "Politica de Confidențialitate",
		"az": "Gizlilik Siyasəti",
		"hr": "Politika privatnosti",
		"bg": "Политика за поверителност",
		"sr": "Правила о приватности",
		"hy": "Գաղտնիության քաղաքականություն",
		"eu": "Pribatutasun politika",
		"be": "Палітыка прыватнасці",
		"ca": "Política de privacitat",
		"cs": "Zásady ochrany osobních údajů",
		"da": "Fortrolighedspolitik",
		"nl": "Privacybeleid",
		"et": "Privaatsuspoliitika",
		"tl": "Patakaran sa Pagkapribado",
		"gl": "Política de Privacidade",
		"ka": "Კონფიდენციალურობის პოლიტიკა",
		"el": "Πολιτική απορρήτου",
		"hu": "Adatvédelmi irányelvek",
		"is": "Friðhelgisstefna",
		"lv": "Privātuma politika",
		"lt": "Privatumo politika",
		"mk": "Политика за приватност",
		"no": "Personvernerklæring",
		"sk": "Zásady ochrany osobných údajov",
		"sl": "Politika zasebnosti",
		"sv": "Integritetspolicy",
		"fi": "Tietosuojakäytäntö",
		"af": "Privaatheidsbeleid",
		"sq": "Politika e privatësisë",
		"am": "የ ግል የሆነ",
		"my": "ကိုယ်ရေးအချက်အလက်မူဝါဒ",
		"gu": "ગોપનીયતા નીતિ",
		"he": "מדיניות פרטיות",
		"id": "Kebijakan pribadi",
		"kn": "ಗೌಪ್ಯತಾ ನೀತಿ",
		"kk": "Құпиялылық саясаты",
		"km": "គោលការណ៍​ភាព​ឯកជន",
		"ky": "Купуялык саясаты",
		"lo": "ນະໂຍບາຍຄວາມເປັນສ່ວນຕົວ",
		"ml": "സ്വകാര്യതാനയം",
		"mr": "गोपनीयता धोरण",
		"mn": "Нууцлалын бодлого",
		"ne": "गोपनीयता नीति",
		"pa": "ਪਰਾਈਵੇਟ ਨੀਤੀ",
		"si": "රහස්යතා ප්රතිපත්තිය",
		"sw": "Sera ya faragha",
		"ta": "தனியுரிமை கொள்கை",
		"te": "గోప్యతా విధానం",
		"ur": "رازداری کی پالیسی",
		"zu": "Inqubomgomo yobumfihlo",
	},
	"faq": {
		"en": "FAQ",
		"es": "Preguntas más frecuentes",
		"zh": "常问问题",
		"hi": "सामान्य प्रश्न",
		"ar": "التعليمات",
		"pt": "Perguntas frequentes",
		"bn": "প্রায়শই জিজ্ঞাসিত প্রশ্নাবলী",
		"ru": "Вопросы-Ответы",
		"ja": "よくある質問",
		"de": "FAQ",
		"ms": "Soalan Lazim",
		"vi": "Câu hỏi thường gặp",
		"fr": "FAQ",
		"tr": "SSS",
		"it": "FAQ",
		"th": "คำถามที่พบบ่อย",
		"fa": "سوالات متداول",
		"pl": "FAQ",
		"uk": "FAQ",
		"uz": "Tss",
		"ro": "FAQ",
		"az": "FAQ",
		"hr": "Pitanja",
		"bg": "ЧЗВ",
		"sr": "ФАК",
		"hy": "ՀՏՀ",
		"eu": "ohiko galderak",
		"be": "Пытанні і адказы",
		"ca": "Preguntes freqüents",
		"cs": "FAQ",
		"da": "Ofte stillede spørgsmål",
		"nl": "FAQ",
		"et": "KKK",
		"tl": "FAQ",
		"gl": "FAQ",
		"ka": "ხშირად დასმული კითხვები",
		"el": "Συχνές ερωτήσεις",
		"hu": "GYIK",
		"is": "Algengar spurningar",
		"lv": "Bieži uzdotie jautājumi",
		"lt": "DUK",
		"mk": "Најчесто поставувани прашања",
		"no": "FAQ",
		"sk": "FAQ",
		"sl": "Pogosta vprašanja",
		"sv": "Vanliga frågor",
		"fi": "Usein kysytyt kysymykset",
		"af": "FAQ",
		"sq": "FAQ",
		"am": "በየጥ",
		"my": "အမြဲမေးလေ့ရှိသောမေးခွန်းများ",
		"gu": "પ્રશ્નો",
		"he": "שאלות נפוצות",
		"id": "FAQ",
		"kn": "FAQ",
		"kk": "Жиі қойылатын сұрақтар",
		"km": "សំណួរគេសួរញឹកញាប់",
		"ky": "FAQ",
		"lo": "ຄຳ ຖາມທີ່ຖືກຖາມເລື້ອຍ",
		"ml": "പതിവുചോദ്യങ്ങൾ",
		"mr": "वारंवार विचारले जाणारे प्रश्न",
		"mn": "FAQ",
		"ne": "सामान्य प्रश्न",
		"pa": "ਅਕਸਰ ਪੁੱਛੇ ਜਾਂਦੇ ਸਵਾਲ",
		"si": "නිති අසන පැණ",
		"sw": "Maswali Yanayoulizwa Sana",
		"ta": "அடிக்கடி கேட்கப்படும் கேள்விகள்",
		"te": "ఎఫ్ ఎ క్యూ",
		"ur": "عمومی سوالات",
		"zu": "Imibuzo evame ukubuzwa",
	},
	"whitepaper": {
		"en": "Whitepaper",
		"es": "Papel blanco",
		"zh": "白皮书",
		"hi": "सफ़ेद कागज",
		"ar": "ورق ابيض",
		"pt": "Papel branco",
		"bn": "সাদা কাগজ",
		"ru": "Белая бумага",
		"ja": "白書",
		"de": "Weißes Papier",
		"ms": "Kertas putih",
		"vi": "Giấy trắng",
		"fr": "Papier blanc",
		"tr": "Beyaz kağıt",
		"it": "Carta bianca",
		"th": "กระดาษสีขาว",
		"fa": "کاغذ سفید",
		"pl": "Biały papier",
		"uk": "Білий папір",
		"uz": "Oq qog'oz",
		"ro": "Hartie alba",
		"az": "Ağ kağız",
		"hr": "Bijeli papir",
		"bg": "Бяла хартия",
		"sr": "Бели папир",
		"hy": "Սպիտակ թուղթ",
		"eu": "Paper zuria",
		"be": "Белая папера",
		"ca": "Paper blanc",
		"cs": "Bílý papír",
		"da": "Hvidt papir",
		"nl": "Wit papier",
		"et": "Valge paber",
		"tl": "Puting papel",
		"gl": "Papel branco",
		"ka": "Თეთრი ქაღალდი",
		"el": "Λευκό χαρτί",
		"hu": "Fehér papír",
		"is": "Hvítur pappír",
		"lv": "Baltā grāmata",
		"lt": "Baltas popierius",
		"mk": "Бела хартија",
		"no": "Hvitt papir",
		"sk": "Biely papier",
		"sl": "Bel papir",
		"sv": "Vitt papper",
		"fi": "Valkoinen paperi",
		"af": "Wit papier",
		"sq": "Letër të bardhë",
		"am": "ነጭ ወረቀት",
		"my": "စက္ကူဖြူ",
		"gu": "સફેદ કાગળ",
		"he": "נייר לבן",
		"id": "Kertas putih",
		"kn": "ಶ್ವೇತಪತ್ರ",
		"kk": "Ақ қағаз",
		"km": "ក្រដាស​ស",
		"ky": "Ак кагаз",
		"lo": "ເຈ້ຍ​ສີ​ຂາວ",
		"ml": "വെളുത്ത പേപ്പർ",
		"mr": "पांढरा कागद",
		"mn": "Цагаан цаас",
		"ne": "सेताे कागज",
		"pa": "ਵ੍ਹਾਈਟ ਪੇਪਰ",
		"si": "සුදු කඩදාසිය",
		"sw": "Karatasi ya karatasi",
		"ta": "வெள்ளை காகிதம்",
		"te": "తెల్ల కాగితం",
		"ur": "سفید کاغذ",
		"zu": "Iphepha elimhlophe",
	},
	"contractAddress": {
		"en": "Contract Address",
		"es": "Dirección del contrato",
		"zh": "合约地址",
		"hi": "अनुबंध का पता",
		"ar": "عنوان العقد",
		"pt": "Endereço do Contrato",
		"bn": "চুক্তির ঠিকানা",
		"ru": "Адрес контракта",
		"ja": "契約住所",
		"de": "Vertragsadresse",
		"ms": "Alamat Kontrak",
		"vi": "Địa chỉ hợp đồng",
		"fr": "Adresse du contrat",
		"tr": "Sözleşme Adresi",
		"it": "Indirizzo del contratto",
		"th": "ที่อยู่สัญญา",
		"fa": "آدرس قرارداد",
		"pl": "Adres umowy",
		"uk": "Адреса договору",
		"uz": "Shartnoma manzili",
		"ro": "Adresa contractului",
		"az": "Müqavilə Ünvanı",
		"hr": "Adresa ugovora",
		"bg": "Адрес на договора",
		"sr": "Адреса уговора",
		"hy": "Պայմանագրի հասցեն",
		"eu": "Kontratuaren helbidea",
		"be": "Адрас дамовы",
		"ca": "Adreça del contracte",
		"cs": "Adresa smlouvy",
		"da": "Kontraktsadresse",
		"nl": "Contractadres:",
		"et": "Lepingu aadress",
		"tl": "Address ng Kontrata",
		"gl": "Enderezo do contrato",
		"ka": "კონტრაქტის მისამართი",
		"el": "Διεύθυνση συμβολαίου",
		"hu": "Szerződés címe",
		"is": "Heimilisfang samnings",
		"lv": "Līguma adrese",
		"lt": "Sutarties adresas",
		"mk": "Адреса на договорот",
		"no": "Kontraktsadresse",
		"sk": "Adresa zmluvy",
		"sl": "Naslov pogodbe",
		"sv": "Kontraktsadress",
		"fi": "Sopimuksen osoite",
		"af": "Kontrakadres",
		"sq": "Adresa e Kontratës",
		"am": "የውል አድራሻ",
		"my": "စာချုပ်လိပ်စာ",
		"gu": "કરારનું સરનામું",
		"he": "כתובת החוזה",
		"id": "Alamat Kontrak",
		"kn": "ಒಪ್ಪಂದದ ವಿಳಾಸ",
		"kk": "Келісімшарт мекенжайы",
		"km": "អាស័យដ្ឋានកិច្ចសន្យា",
		"ky": "Келишим дареги",
		"lo": "ທີ່ຢູ່ສັນຍາ",
		"ml": "കരാർ വിലാസം",
		"mr": "कराराचा पत्ता",
		"mn": "Гэрээний хаяг",
		"ne": "ठेगाना ठेगाना",
		"pa": "ਇਕਰਾਰਨਾਮੇ ਦਾ ਪਤਾ",
		"si": "කොන්ත්රාත් ලිපිනය",
		"sw": "Anwani ya Mkataba",
		"ta": "ஒப்பந்த முகவரி",
		"te": "కాంట్రాక్ట్ చిరునామా",
		"ur": "معاہدے کا پتہ۔",
		"zu": "Ikheli Lesivumelwano",
	},
	"career": {
		"en": "Career",
		"es": "Carrera profesional",
		"zh": "职业",
		"hi": "आजीविका",
		"ar": "حياة مهنية",
		"pt": "Carreira",
		"bn": "ক্যারিয়ার",
		"ru": "Карьера",
		"ja": "キャリア",
		"de": "Karriere",
		"ms": "Kerjaya",
		"vi": "Sự nghiệp",
		"fr": "Carrière",
		"tr": "Kariyer",
		"it": "Carriera",
		"th": "อาชีพ",
		"fa": "حرفه",
		"pl": "Kariera zawodowa",
		"uk": "Кар'єра",
		"uz": "Karyera",
		"ro": "Carieră",
		"az": "Karyera",
		"hr": "Karijera",
		"bg": "Кариера",
		"sr": "Цареер",
		"hy": "Կարիերա",
		"eu": "Karrera",
		"be": "Кар'ера",
		"ca": "Carrera",
		"cs": "Kariéra",
		"da": "Karriere",
		"nl": "Carrière",
		"et": "Karjäär",
		"tl": "Karera",
		"gl": "Carreira",
		"ka": "კარიერა",
		"el": "Καριέρα",
		"hu": "Karrier",
		"is": "Starfsferill",
		"lv": "Karjera",
		"lt": "Karjera",
		"mk": "Кариера",
		"no": "Karriere",
		"sk": "Kariéra",
		"sl": "Kariera",
		"sv": "Karriär",
		"fi": "Ura",
		"af": "Loopbaan",
		"sq": "Karrierë",
		"am": "ሙያ",
		"my": "အသက်မွေးဝမ်းကျောင်း",
		"gu": "કારકિર્દી",
		"he": "קריירה",
		"id": "Karier",
		"kn": "ವೃತ್ತಿ",
		"kk": "Мансап",
		"km": "អាជីព",
		"ky": "Мансап",
		"lo": "ອາຊີບ",
		"ml": "കരിയർ",
		"mr": "करिअर",
		"mn": "Ажил мэргэжил",
		"ne": "क्यारियर",
		"pa": "ਕਰੀਅਰ",
		"si": "වෘත්තිය",
		"sw": "Kazi",
		"ta": "தொழில்",
		"te": "కెరీర్",
		"ur": "کیریئر",
		"zu": "Umsebenzi",
	},
	"businessLogIn": {
		"en": "Business Log In",
		"es": "Inicio de sesión comercial",
		"zh": "企业登录",
		"hi": "व्यापार लॉग इन",
		"ar": "دخول الأعمال",
		"pt": "Login da empresa",
		"bn": "ব্যবসা লগ ইন",
		"ru": "Бизнес Войти",
		"ja": "ビジネスログイン",
		"de": "Geschäftslogin",
		"ms": "Log Masuk Perniagaan",
		"vi": "Đăng nhập Doanh nghiệp",
		"fr": "Connexion professionnelle",
		"tr": "İş Girişi",
		"it": "Accedi",
		"th": "เข้าสู่ระบบธุรกิจ",
		"fa": "تجارت وارد شوید",
		"pl": "Zaloguj się",
		"uk": "Увійти у бізнес",
		"uz": "Biznesga kirish",
		"ro": "Autentificare de afaceri",
		"az": "İş Giriş",
		"hr": "Poslovna prijava",
		"bg": "Вход за бизнес",
		"sr": "Бусинесс Лог ин",
		"hy": "Բիզնես Մուտք գործեք",
		"eu": "Enpresa Saioa hasi",
		"be": "Уваход у бізнэс",
		"ca": "Inici de sessió empresarial",
		"cs": "Obchodní přihlášení",
		"da": "Forretnings log ind",
		"nl": "Zakelijk inloggen",
		"et": "Ettevõte Logi sisse",
		"tl": "Mag-log In ang Negosyo",
		"gl": "Iniciar sesión empresarial",
		"ka": "ბიზნეს შესვლა",
		"el": "Επιχείρηση Σύνδεση",
		"hu": "Üzleti bejelentkezés",
		"is": "Viðskipti Innskráning",
		"lv": "Uzņēmuma pieteikšanās",
		"lt": "Verslo prisijungimas",
		"mk": "Бизнис Пријавете се",
		"no": "Business Logg inn",
		"sk": "Obchodné prihlásenie",
		"sl": "Poslovna prijava",
		"sv": "Företag Logga in",
		"fi": "Yritys Kirjaudu sisään",
		"af": "Sake -aanmelding",
		"sq": "Biznes Identifikohu",
		"am": "የንግድ ሥራ መግቢያ",
		"my": "Business Log In ဝင်ပါ",
		"gu": "વ્યવસાય લોગ ઇન કરો",
		"he": "כניסה לעסקים",
		"id": "Masuk Bisnis",
		"kn": "ವ್ಯಾಪಾರ ಲಾಗಿನ್",
		"kk": "Бизнеске кіру",
		"km": "ចូលអាជីវកម្ម",
		"ky": "Бизнес Кирүү",
		"lo": "ເຂົ້າສູ່ລະບົບທຸລະກິດ",
		"ml": "ബിസിനസ് ലോഗിൻ",
		"mr": "व्यवसाय लॉग इन करा",
		"mn": "Бизнес нэвтрэх",
		"ne": "व्यापार लग इन गर्नुहोस्",
		"pa": "ਵਪਾਰ ਲੌਗ ਇਨ ਕਰੋ",
		"si": "ව්‍යාපාර ලොග් ඉන්",
		"sw": "Ingia Biashara",
		"ta": "வணிக உள்நுழைவு",
		"te": "వ్యాపార లాగిన్",
		"ur": "کاروباری لاگ ان۔",
		"zu": "Ibhizinisi Ngena",
	},
	"weArePreparingForThisFeatureNowComingSoon": {
		"en": "We are preparting for this feature now. Coming soon!",
		"es": "Nos estamos preparando para esta función ahora. ¡Próximamente, en breve, pronto!",
		"zh": "我们现在正在准备这个功能。即将推出！",
		"hi": "हम अभी इस सुविधा के लिए तैयारी कर रहे हैं। जल्द आ रहा है!",
		"ar": "نحن نعد هذه الميزة الآن. قريبا!",
		"pt": "Estamos preparando este recurso agora. Em breve!",
		"bn": "আমরা এখন এই বৈশিষ্ট্যটির জন্য প্রস্তুতি নিচ্ছি। শীঘ্রই আসছে!",
		"ru": "Сейчас мы готовимся к этой функции. Скоро будет!",
		"ja": "現在、この機能の準備をしています。近日公開！",
		"de": "Wir bereiten uns jetzt auf diese Funktion vor. Demnächst!",
		"ms": "Kami sedang mempersiapkan ciri ini sekarang. Akan datang!",
		"vi": "Chúng tôi đang chuẩn bị cho tính năng này ngay bây giờ. Sắp có!",
		"fr": "Nous préparons cette fonctionnalité maintenant. Bientôt disponible!",
		"tr": "Şimdi bu özellik için hazırlanıyoruz. Çok yakında!",
		"it": "Ci stiamo preparando per questa funzione ora. Prossimamente!",
		"th": "เรากำลังเตรียมการสำหรับคุณลักษณะนี้ในขณะนี้ เร็ว ๆ นี้!",
		"fa": "ما در حال آماده شدن برای این ویژگی هستیم. به زودی!",
		"pl": "Przygotowujemy się teraz do tej funkcji. Wkrótce!",
		"uk": "Ми зараз готуємось до цієї функції. Незабаром!",
		"uz": "Biz hozir bu xususiyatga tayyorgarlik ko'ryapmiz. Tez kunda!",
		"ro": "Pregătim acum pentru această funcție. In curand!",
		"az": "İndi bu xüsusiyyətə hazırlaşırıq. Tezliklə!",
		"hr": "Trenutno se pripremamo za ovu značajku. Dolazi uskoro!",
		"bg": "Вече подготвяме тази функция. Очаквайте скоро!",
		"sr": "Тренутно се припремамо за ову функцију. Долази ускоро!",
		"hy": "Մենք այժմ պատրաստվում ենք այս գործառույթի համար: Շուտով!",
		"eu": "Ezaugarri hau prestatzen ari gara orain. Laister!",
		"be": "Мы рыхтуемся да гэтай функцыі зараз. Хутка!",
		"ca": "Ara estem preparant aquesta funció. Pròximament!",
		"cs": "Tuto funkci nyní připravujeme. Již brzy!",
		"da": "Vi forbereder nu denne funktion. Kommer snart!",
		"nl": "We bereiden ons nu voor op deze functie. Komt binnenkort!",
		"et": "Praegu valmistume selle funktsiooni jaoks ette. Tulekul!",
		"tl": "Nagsisimula na kami para sa tampok na ito ngayon. Malapit na!",
		"gl": "Agora estamos preparando esta función. Proximamente!",
		"ka": "ჩვენ ახლა ვემზადებით ამ ფუნქციისთვის. Მალე!",
		"el": "Προετοιμαζόμαστε για αυτήν τη λειτουργία τώρα. Ερχομαι συντομα!",
		"hu": "Erre a funkcióra most készülünk. Hamarosan!",
		"is": "Við erum að undirbúa þennan eiginleika núna. Væntanlegt!",
		"lv": "Šobrīd mēs gatavojamies šai funkcijai. Drīzumā!",
		"lt": "Dabar ruošiamės šiai funkcijai. Netrukus!",
		"mk": "Сега се подготвуваме за оваа функција. Наскоро!",
		"no": "Vi forbereder denne funksjonen nå. Kommer snart!",
		"sk": "Túto funkciu práve pripravujeme. Už čoskoro!",
		"sl": "Zdaj se pripravljamo na to funkcijo. Prihaja kmalu!",
		"sv": "Vi förbereder den här funktionen nu. Kommer snart!",
		"fi": "Valmistaudumme nyt tähän ominaisuuteen. Tulossa pian!",
		"af": "Ons berei nou voor vir hierdie funksie. Kom binnekort!",
		"sq": "Tani po përgatitemi për këtë veçori. Vjen së shpejti!",
		"am": "እኛ ለዚህ ባህሪ አሁን እንጀምራለን። በቅርብ ቀን!",
		"my": "အခုဒီအင်္ဂါရပ်အတွက်ကြိုတင်ပြင်ဆင်နေပါတယ်။ မကြာမီလာမည်!",
		"gu": "અમે હવે આ સુવિધા માટે તૈયારી કરી રહ્યા છીએ. ટૂક સમયમાં આવી રહ્યું છે!",
		"he": "אנו מכינים תכונה זו כעת. בקרוב!",
		"id": "Kami sedang mempersiapkan fitur ini sekarang. Segera akan datang!",
		"kn": "ನಾವು ಈಗ ಈ ವೈಶಿಷ್ಟ್ಯಕ್ಕಾಗಿ ಸಿದ್ಧತೆ ನಡೆಸುತ್ತಿದ್ದೇವೆ. ಶೀಘ್ರದಲ್ಲೇ ಬರಲಿದೆ!",
		"kk": "Біз қазір бұл функцияға дайындық үстіндеміз. Жақында шығады!",
		"km": "យើងកំពុងរៀបចំជាមុនសម្រាប់លក្ខណៈពិសេសនេះឥឡូវនេះ។ មកដល់ក្នុងពេលឆាប់ៗនេះ!",
		"ky": "Биз азыр бул функцияга даярданып жатабыз. Келе жакында!",
		"lo": "ພວກເຮົາກໍາລັງກະກຽມສໍາລັບຄຸນນະສົມບັດນີ້ໃນປັດຈຸບັນ. ໄວ Coming ນີ້!",
		"ml": "ഈ ഫീച്ചറിനായി ഞങ്ങൾ ഇപ്പോൾ തയ്യാറെടുക്കുകയാണ്. ഉടൻ വരുന്നു!",
		"mr": "आम्ही आता या वैशिष्ट्यासाठी तयारी करत आहोत. लवकरच येत आहे!",
		"mn": "Бид энэ онцлогийг одоо бэлдэж байна. Тун удахгүй!",
		"ne": "हामी अब यो सुविधा को लागी तयारी गरीरहेका छौं। आउदैछ!",
		"pa": "ਅਸੀਂ ਹੁਣ ਇਸ ਵਿਸ਼ੇਸ਼ਤਾ ਲਈ ਤਿਆਰੀ ਕਰ ਰਹੇ ਹਾਂ. ਆਨ ਵਾਲੀ!",
		"si": "අපි දැන් මෙම විශේෂාංගය සඳහා සූදානම් වෙමින් සිටිමු. ඉක්මණින් පැමිණෙන!",
		"sw": "Tunajitayarisha kwa huduma hii sasa. Inakuja hivi karibuni!",
		"ta": "இந்த அம்சத்திற்கு நாங்கள் இப்போது தயாராகி வருகிறோம். விரைவில் வரும்!",
		"te": "మేము ఇప్పుడు ఈ ఫీచర్ కోసం సిద్ధం చేస్తున్నాము. త్వరలో!",
		"ur": "ہم ابھی اس فیچر کی تیاری کر رہے ہیں۔ جلد آرہا ہے!",
		"zu": "Silungiselela lesi sici manje. Kuyeza maduze!",
	},
	"frequentlyAskedQuestions": {
		"en": "Frequently Asked Questions",
		"es": "Preguntas frecuentes",
		"zh": "经常问的问题",
		"hi": "सामान्यतःपूछे जाने वाले प्रश्न",
		"ar": "أسئلة مكررة",
		"pt": "perguntas frequentes",
		"bn": "সচরাচর জিজ্ঞাস্য",
		"ru": "Часто задаваемые вопросы",
		"ja": "よくある質問",
		"de": "Häufig gestellte Fragen",
		"ms": "Soalan Lazim",
		"vi": "Các câu hỏi thường gặp",
		"fr": "Questions fréquemment posées",
		"tr": "Sıkça Sorulan Sorular",
		"it": "Domande frequenti",
		"th": "คำถามที่พบบ่อย",
		"fa": "سوالات متداول",
		"pl": "Często Zadawane Pytania",
		"uk": "Питання що часто задаються",
		"uz": "tez-tez so'raladigan savollar",
		"ro": "întrebări frecvente",
		"az": "Tez-tez soruşulan suallar",
		"hr": "Često postavljana pitanja",
		"bg": "често задавани въпроси",
		"sr": "Често постављана питања",
		"hy": "Հաճախակի տրվող հարցեր",
		"eu": "Galdera arruntak",
		"be": "Часта задаваныя пытанні",
		"ca": "Preguntes freqüents",
		"cs": "Často kladené otázky",
		"da": "Ofte stillede spørgsmål",
		"nl": "Veel Gestelde Vragen",
		"et": "Korduma kippuvad küsimused",
		"tl": "Mga Madalas Itanong",
		"gl": "Preguntas máis frecuentes",
		"ka": "ხშირად დასმული შეკითხვები",
		"el": "Συχνές Ερωτήσεις",
		"hu": "Gyakran Ismételt Kérdések",
		"is": "Algengar spurningar",
		"lv": "bieži uzdotie jautājumi",
		"lt": "Dažnai užduodami klausimai",
		"mk": "Најчесто поставувани прашања",
		"no": "ofte stilte spørsmål",
		"sk": "často kladené otázky",
		"sl": "Pogosto zastavljena vprašanja",
		"sv": "Vanliga frågor",
		"fi": "Usein Kysytyt Kysymykset",
		"af": "Gereelde vrae",
		"sq": "Pyetje të Shpeshta",
		"am": "ተደጋግሞ የሚነሱ ጥያቄዎች",
		"my": "အမေးများသောမေးခွန်းများ",
		"gu": "વારંવાર પૂછાતા પ્રશ્નો",
		"he": "שאלות נפוצות",
		"id": "Pertanyaan yang Sering Diajukan",
		"kn": "ಪದೇ ಪದೇ ಕೇಳಲಾಗುವ ಪ್ರಶ್ನೆಗಳು",
		"kk": "Жиі Қойылатын Сұрақтар",
		"km": "សំណួរ​ដែល​សួរ​ញឹកញាប់",
		"ky": "Көп берилүүчү суроолор",
		"lo": "ຄໍາ​ຖາມ​ທີ່​ຖືກ​ຖາມ​ເລື້ອຍໆ",
		"ml": "പതിവായി ചോദിക്കുന്ന ചോദ്യങ്ങൾ",
		"mr": "सतत विचारले जाणारे प्रश्न",
		"mn": "Түгээмэл асуултууд",
		"ne": "बारम्बार सोधिने प्रश्नहरू",
		"pa": "ਅਕਸਰ ਪੁੱਛੇ ਜਾਣ ਵਾਲੇ ਸਵਾਲ",
		"si": "නිතර අසන ප්‍රශ්න",
		"sw": "maswali yanayoulizwa mara kwa mara",
		"ta": "அடிக்கடி கேட்கப்படும் கேள்விகள்",
		"te": "తరచుగా అడుగు ప్రశ్నలు",
		"ur": "اکثر پوچھے گئے سوالات",
		"zu": "imibuzo ejwayelekile ukubuzwa",
	},
	"takeControlOfYourFinance": {
		"en": "Take control of your finance.",
		"es": "Toma el control de tus finanzas.",
		"zh": "掌控您的财务。",
		"hi": "अपने वित्त पर नियंत्रण रखें।",
		"ar": "تحكم في تمويلك.",
		"pt": "Assuma o controle de suas finanças.",
		"bn": "আপনার অর্থের নিয়ন্ত্রণ নিন।",
		"ru": "Возьмите под контроль свои финансы.",
		"ja": "あなたの財政を管理しなさい。",
		"de": "Übernehmen Sie die Kontrolle über Ihre Finanzen.",
		"ms": "Kendalikan kewangan anda.",
		"vi": "Kiểm soát tài chính của bạn.",
		"fr": "Prenez le contrôle de vos finances.",
		"tr": "Finansmanınızın kontrolünü elinize alın.",
		"it": "Prendi il controllo della tua finanza.",
		"th": "ควบคุมการเงินของคุณ",
		"fa": "کنترل امور مالی خود را در دست بگیرید.",
		"pl": "Przejmij kontrolę nad swoimi finansami.",
		"uk": "Візьміть під контроль свої фінанси.",
		"uz": "Moliyangizni nazorat qiling.",
		"ro": "Preia controlul asupra finanțelor tale.",
		"az": "Maliyyənizə nəzarət edin.",
		"hr": "Preuzmite kontrolu nad svojim financijama.",
		"bg": "Поемете контрола над финансите си.",
		"sr": "Преузмите контролу над својим финансијама.",
		"hy": "Վերցրեք ձեր ֆինանսների վերահսկողությունը:",
		"eu": "Hartu zure finantzaren kontrola.",
		"be": "Вазьміце пад свой кантроль свае фінансы.",
		"ca": "Pren el control de les teves finances.",
		"cs": "Převezměte kontrolu nad svými financemi.",
		"da": "Tag kontrol over din økonomi.",
		"nl": "Neem de controle over uw financiën.",
		"et": "Hallake oma rahandust.",
		"tl": "Kontrolin ang iyong pananalapi.",
		"gl": "Toma o control das túas finanzas.",
		"ka": "მიიღეთ კონტროლი თქვენს ფინანსებზე.",
		"el": "Πάρτε τον έλεγχο των οικονομικών σας.",
		"hu": "Irányítsd a pénzügyeidet.",
		"is": "Taktu stjórn á fjármálum þínum.",
		"lv": "Kontrolējiet savas finanses.",
		"lt": "Kontroliuokite savo finansus.",
		"mk": "Преземете ја контролата врз вашите финансии.",
		"no": "Ta kontroll over økonomien din.",
		"sk": "Prevezmite kontrolu nad svojimi financiami.",
		"sl": "Prevzemite nadzor nad svojimi financami.",
		"sv": "Ta kontroll över din ekonomi.",
		"fi": "Hallitse rahoitustasi.",
		"af": "Neem beheer oor u finansies.",
		"sq": "Merrni kontrollin e financave tuaja.",
		"am": "ፋይናንስዎን ይቆጣጠሩ።",
		"my": "သင်၏ငွေကြေးကိုထိန်းချုပ်ပါ။",
		"gu": "તમારા નાણા પર નિયંત્રણ રાખો.",
		"he": "קח שליטה על הכספים שלך.",
		"id": "Kendalikan keuangan Anda.",
		"kn": "ನಿಮ್ಮ ಹಣಕಾಸಿನ ಮೇಲೆ ಹಿಡಿತ ಸಾಧಿಸಿ.",
		"kk": "Қаржыны бақылауға алыңыз.",
		"km": "គ្រប់គ្រងហិរញ្ញវត្ថុរបស់អ្នក។",
		"ky": "Каржыңызды көзөмөлгө алыңыз.",
		"lo": "ຄວບຄຸມການເງິນຂອງເຈົ້າ.",
		"ml": "നിങ്ങളുടെ ധനകാര്യത്തിന്റെ നിയന്ത്രണം ഏറ്റെടുക്കുക.",
		"mr": "आपल्या फायनान्सवर नियंत्रण ठेवा.",
		"mn": "Санхүүгээ хяналтандаа байлгаарай.",
		"ne": "आफ्नो वित्त को नियन्त्रण लिनुहोस्।",
		"pa": "ਆਪਣੇ ਵਿੱਤ ਤੇ ਨਿਯੰਤਰਣ ਰੱਖੋ.",
		"si": "ඔබේ මුදල් පාලනය කරන්න.",
		"sw": "Chukua udhibiti wa fedha zako.",
		"ta": "உங்கள் நிதியைக் கட்டுப்படுத்துங்கள்.",
		"te": "మీ ఫైనాన్స్‌ని నియంత్రించండి.",
		"ur": "اپنے فنانس کو کنٹرول کریں۔",
		"zu": "Lawula ezezimali zakho.",
	},
	"noMatterWhoYouAre": {
		"en": "No matter who you are,",
		"es": "No importa quién eres,",
		"zh": "不管你是谁，",
		"hi": "कोई फर्क नहीं पड़ता कि आप कौन हैं,",
		"ar": "لا يهم من انت،",
		"pt": "Não importa quem você é,",
		"bn": "আপনি কে কোন ব্যাপার,",
		"ru": "Не имеет значения кто ты,",
		"ja": "君が誰であろうと、",
		"de": "Egal wer du bist,",
		"ms": "Tidak kira siapa anda,",
		"vi": "Cho dù bạn là ai,",
		"fr": "Peu importe qui tu es,",
		"tr": "Kim olursan ol,",
		"it": "Non importa chi tu sia,",
		"th": "ไม่ว่าคุณจะเป็นใคร",
		"fa": "مهم نیست کی هستی،",
		"pl": "Nie ważne kim jesteś,",
		"uk": "Хто б ти не був,",
		"uz": "Kim bo'lishingizdan qat'iy nazar,",
		"ro": "Nu contează cine ești,",
		"az": "Kim olmağından asılı olmayaraq,",
		"hr": "Bez obzira tko ste,",
		"bg": "Без значение кой сте,",
		"sr": "Без обзира ко си,",
		"hy": "Անկախ նրանից, թե ով ես դու,",
		"eu": "Ez zara axola nor zaren,",
		"be": "Незалежна ад таго, хто ты,",
		"ca": "No importa qui siguis,",
		"cs": "Nezáleží na tom, kdo jsi,",
		"da": "Uanset hvem du er,",
		"nl": "Het maakt niet uit wie je bent,",
		"et": "Pole tähtis, kes sa oled,",
		"tl": "Maging sino ka man,",
		"gl": "Non importa quen sexas,",
		"ka": "ვინც არ უნდა იყო,",
		"el": "Δεν έχει σημασία ποιος είσαι,",
		"hu": "Nem számít, ki vagy,",
		"is": "Sama hver þú ert,",
		"lv": "Nav svarīgi, kas tu esi,",
		"lt": "Nesvarbu, kas tu esi,",
		"mk": "Без разлика кој си,",
		"no": "Uansett hvem du er,",
		"sk": "Bez ohľadu na to, kto ste,",
		"sl": "Ne glede na to, kdo ste,",
		"sv": "Oavsett vem du är,",
		"fi": "Ei väliä kuka olet,",
		"af": "Maak nie saak wie jy is nie,",
		"sq": "Pavarësisht se kush jeni,",
		"am": "ምንም ብትሆኑም ፣",
		"my": "သင်ဘယ်သူပဲဖြစ်ဖြစ်၊",
		"gu": "ભલે તમે કોણ છો,",
		"he": "לא משנה מי אתה,",
		"id": "Tidak peduli siapa Anda,",
		"kn": "ನೀವು ಯಾರೇ ಆಗಿರಲಿ,",
		"kk": "Сіз кім болсаңыз да,",
		"km": "មិនថាអ្នកជាអ្នកណាទេ",
		"ky": "Ким экениңе карабай,",
		"lo": "ບໍ່ວ່າເຈົ້າຈະເປັນໃຜ,",
		"ml": "നിങ്ങൾ ആരായാലും പ്രശ്നമില്ല,",
		"mr": "आपण कोण आहात हे महत्त्वाचे नाही,",
		"mn": "Та хэн ч байсан хамаагүй,",
		"ne": "जे भए पनि तिमी को हौ,",
		"pa": "ਕੋਈ ਫਰਕ ਨਹੀਂ ਪੈਂਦਾ ਕਿ ਤੁਸੀਂ ਕੌਣ ਹੋ,",
		"si": "ඔබ කවුරු වුවත් කමක් නැත,",
		"sw": "Haijalishi wewe ni nani,",
		"ta": "நீங்கள் யாராக இருந்தாலும் பரவாயில்லை",
		"te": "మీరు ఎవరు ఉన్నా,",
		"ur": "اس سے کوئی فرق نہیں پڑتا کہ آپ کون ہیں ،",
		"zu": "Akunandaba ukuthi ungubani,",
	},
	"whatYourNationalityIs": {
		"en": "What your nationality is,",
		"es": "Cual es tu nacionalidad",
		"zh": "你的国籍是什么，",
		"hi": "आपकी राष्ट्रीयता क्या है,",
		"ar": "ما هي جنسيتك",
		"pt": "Qual é a sua nacionalidade,",
		"bn": "আপনার জাতীয়তা কি,",
		"ru": "Какая у тебя национальность,",
		"ja": "あなたの国籍は何ですか、",
		"de": "Welche Nationalität haben Sie,",
		"ms": "Apa kewarganegaraan anda,",
		"vi": "Quốc tịch của bạn là gì,",
		"fr": "Quelle est votre nationalité,",
		"tr": "Milliyetin ne,",
		"it": "Qual è la tua nazionalità,",
		"th": "สัญชาติของคุณคืออะไร",
		"fa": "ملیت شما چیست ،",
		"pl": "Jaka jest Twoja narodowość,",
		"uk": "Якої ви національності,",
		"uz": "Sizning millatingiz nima,",
		"ro": "Care este naționalitatea ta,",
		"az": "Milliyyətiniz nədir",
		"hr": "Koje ste nacionalnosti,",
		"bg": "Каква сте националност,",
		"sr": "Које сте националности,",
		"hy": "Ինչ ազգություն ունեք,",
		"eu": "Zein den zure nazionalitatea,",
		"be": "Якая вы нацыянальнасць,",
		"ca": "Quina és la vostra nacionalitat,",
		"cs": "Jaká je vaše národnost,",
		"da": "Hvad er din nationalitet",
		"nl": "Wat uw nationaliteit is,",
		"et": "Mis on teie kodakondsus,",
		"tl": "Ano ang iyong nasyonalidad,",
		"gl": "Cal é a túa nacionalidade,",
		"ka": "რა ეროვნების ხარ,",
		"el": "Ποια είναι η εθνικότητά σου,",
		"hu": "Mi a nemzetiséged,",
		"is": "Hvert þjóðerni þitt er,",
		"lv": "Kāda ir jūsu tautība,",
		"lt": "Kokia jūsų tautybė,",
		"mk": "Која е вашата националност,",
		"no": "Hva er din nasjonalitet",
		"sk": "Aká je vaša národnosť,",
		"sl": "Kakšne ste narodnosti,",
		"sv": "Vad är din nationalitet,",
		"fi": "Mikä on kansalaisuutesi,",
		"af": "Wat u nasionaliteit is,",
		"sq": "Cila është nacionaliteti juaj,",
		"am": "ዜግነትዎ ምንድነው ፣",
		"my": "မင်းရဲ့နိုင်ငံသားကဘာလဲ၊",
		"gu": "તમારી રાષ્ટ્રીયતા શું છે,",
		"he": "מה הלאום שלך,",
		"id": "Apa kewarganegaraan Anda,",
		"kn": "ನಿಮ್ಮ ರಾಷ್ಟ್ರೀಯತೆ ಏನು,",
		"kk": "Сіздің ұлтыңыз қандай,",
		"km": "សញ្ជាតិរបស់អ្នកជាអ្វី",
		"ky": "Сиздин улутуңуз кандай,",
		"lo": "ສັນຊາດຂອງເຈົ້າແມ່ນຫຍັງ,",
		"ml": "നിങ്ങളുടെ ദേശീയത എന്താണ്,",
		"mr": "तुमचे राष्ट्रीयत्व काय आहे,",
		"mn": "Таны иргэншил юу вэ,",
		"ne": "तपाइँको राष्ट्रीयता के हो,",
		"pa": "ਤੁਹਾਡੀ ਕੌਮੀਅਤ ਕੀ ਹੈ,",
		"si": "ඔබේ ජාතිකත්වය කුමක්ද,",
		"sw": "Utaifa wako ni upi,",
		"ta": "உங்கள் தேசியம் என்ன,",
		"te": "మీ జాతీయత ఏమిటి,",
		"ur": "آپ کی قومیت کیا ہے ،",
		"zu": "Ubuzwe bakho buyini,",
	},
	"whatYourEthnicityIs": {
		"en": "What your ethnicity is,",
		"es": "Cuál es tu etnia,",
		"zh": "你的种族是什么，",
		"hi": "आपकी जाति क्या है,",
		"ar": "ما هو عرقك ،",
		"pt": "Qual é a sua etnia,",
		"bn": "তোমার জাতিসত্তা কি,",
		"ru": "Какая у вас национальность,",
		"ja": "あなたの民族は何ですか、",
		"de": "Was ist Ihre ethnische Zugehörigkeit,",
		"ms": "Apa etnik anda,",
		"vi": "Dân tộc của bạn là gì,",
		"fr": "Quelle est votre ethnie,",
		"tr": "etnik kökenin ne,",
		"it": "Qual è la tua etnia,",
		"th": "เชื้อชาติของคุณคืออะไร",
		"fa": "قومیت شما چیست ،",
		"pl": "Jakie jest twoje pochodzenie etniczne,",
		"uk": "Якої ви національності,",
		"uz": "Sizning millatingiz nima,",
		"ro": "Care este etnia ta,",
		"az": "Etnik mənsubiyyətiniz nədir,",
		"hr": "Koje ste nacionalnosti,",
		"bg": "Какъв е твоят етнос,",
		"sr": "Које сте националности,",
		"hy": "Ինչ ազգություն ունեք,",
		"eu": "Zein da zure etnia,",
		"be": "Якая ў вас этнічная прыналежнасць,",
		"ca": "Quina és la teva ètnia,",
		"cs": "Jaké je vaše etnikum,",
		"da": "Hvad er din etnicitet,",
		"nl": "Wat uw etniciteit is,",
		"et": "Mis on teie rahvus,",
		"tl": "Ano ang iyong etniko,",
		"gl": "Cal é a túa etnia,",
		"ka": "რა ეროვნების ხარ,",
		"el": "Ποια είναι η εθνικότητά σου,",
		"hu": "Mi az etnikai hovatartozásod,",
		"is": "Hver þjóðerni þitt er,",
		"lv": "Kāda ir jūsu tautība,",
		"lt": "Kokia jūsų tautybė,",
		"mk": "Која е вашата етничка припадност,",
		"no": "Hva din etnisitet er,",
		"sk": "Aké je vaše etnikum,",
		"sl": "Kakšne ste narodnosti,",
		"sv": "Vad är din etnicitet,",
		"fi": "Mikä on etnisyytesi,",
		"af": "Wat jou etnisiteit is,",
		"sq": "Cila është përkatësia juaj etnike,",
		"am": "ጎሳዎ ምንድነው ፣",
		"my": "မင်းရဲ့လူမျိုးကဘာလဲ",
		"gu": "તમારી વંશીયતા શું છે,",
		"he": "מה האתניות שלך,",
		"id": "Apa etnis Anda,",
		"kn": "ನಿಮ್ಮ ಜನಾಂಗೀಯತೆ ಏನು,",
		"kk": "Сіздің ұлтыңыз қандай,",
		"km": "តើជនជាតិរបស់អ្នកគឺជាអ្វី",
		"ky": "Сиздин улутуңуз кандай,",
		"lo": "ຊົນເຜົ່າຂອງເຈົ້າແມ່ນຫຍັງ,",
		"ml": "നിങ്ങളുടെ വംശീയത എന്താണ്,",
		"mr": "तुमची जातीयता काय आहे,",
		"mn": "Таны яс үндэс юу вэ,",
		"ne": "तपाइँको जातीयता के हो,",
		"pa": "ਤੁਹਾਡੀ ਜਾਤੀ ਕੀ ਹੈ,",
		"si": "ඔබේ ජාතිය කුමක්ද,",
		"sw": "Kabila lako ni nini,",
		"ta": "உங்கள் இனம் என்ன,",
		"te": "మీ జాతి ఏమిటి,",
		"ur": "آپ کی نسل کیا ہے ،",
		"zu": "Yini ubuhlanga bakho,",
	},
	"whatYourReligionIs": {
		"en": "What your religion is,",
		"es": "Cuál es tu religión",
		"zh": "你的宗教是什么，",
		"hi": "आपका धर्म क्या है,",
		"ar": "ما هو دينك",
		"pt": "Qual é a sua religião,",
		"bn": "তোমার ধর্ম কি,",
		"ru": "Какая у тебя религия,",
		"ja": "あなたの宗教は何ですか、",
		"de": "Was deine Religion ist,",
		"ms": "Apa agama anda,",
		"vi": "Tôn giáo của bạn là gì,",
		"fr": "Quelle est votre religion,",
		"tr": "Dininiz nedir,",
		"it": "Qual è la tua religione,",
		"th": "ศาสนาของคุณคืออะไร",
		"fa": "دین شما چیست ،",
		"pl": "Jaka jest twoja religia,",
		"uk": "Яка твоя релігія,",
		"uz": "Sizning diningiz nima,",
		"ro": "Care este religia ta,",
		"az": "Dininiz nədir",
		"hr": "Koja je tvoja religija,",
		"bg": "Каква е твоята религия,",
		"sr": "Која је твоја религија,",
		"hy": "Ո՞րն է ձեր կրոնը,",
		"eu": "Zein da zure erlijioa,",
		"be": "Якая ў вас рэлігія,",
		"ca": "Quina és la vostra religió,",
		"cs": "Jaké je vaše náboženství,",
		"da": "Hvad er din religion,",
		"nl": "Wat uw religie is,",
		"et": "Mis on teie religioon,",
		"tl": "Ano ang iyong relihiyon,",
		"gl": "Cal é a túa relixión,",
		"ka": "როგორია შენი რელიგია,",
		"el": "Ποια είναι η θρησκεία σου,",
		"hu": "Mi a vallásod,",
		"is": "Hver trú þín er,",
		"lv": "Kāda ir tava reliģija,",
		"lt": "Kokia tavo religija,",
		"mk": "Која е вашата религија,",
		"no": "Hva er din religion,",
		"sk": "Aké je tvoje náboženstvo,",
		"sl": "Kakšna je vaša vera,",
		"sv": "Vad är din religion,",
		"fi": "Mikä on sinun uskontosi,",
		"af": "Wat is jou godsdiens,",
		"sq": "Cila eshte feja jote,",
		"am": "ሃይማኖትህ ምንድነው ፣",
		"my": "မင်းရဲ့ဘာသာကဘာလဲ၊",
		"gu": "તમારો ધર્મ શું છે,",
		"he": "מהי הדת שלך,",
		"id": "Apa agamamu,",
		"kn": "ನಿಮ್ಮ ಧರ್ಮ ಯಾವುದು,",
		"kk": "Сіздің дініңіз қандай,",
		"km": "សាសនារបស់អ្នកជាអ្វី",
		"ky": "Сенин диниң эмне,",
		"lo": "ສາສະ ໜາ ຂອງເຈົ້າແມ່ນຫຍັງ,",
		"ml": "നിങ്ങളുടെ മതം എന്താണ്,",
		"mr": "तुमचा धर्म काय आहे,",
		"mn": "Таны шашин гэж юу вэ,",
		"ne": "तिम्रो धर्म के हो,",
		"pa": "ਤੁਹਾਡਾ ਧਰਮ ਕੀ ਹੈ,",
		"si": "ඔබේ ආගම කුමක්ද,",
		"sw": "Dini yako ni ipi,",
		"ta": "உங்கள் மதம் என்ன",
		"te": "మీ మతం ఏమిటి,",
		"ur": "تمہارا مذہب کیا ہے ،",
		"zu": "Iyini inkolo yakho,",
	},
	"whatYourGenderIs": {
		"en": "What your gender is,",
		"es": "Cuál es tu género,",
		"zh": "你的性别是什么，",
		"hi": "आपका लिंग क्या है,",
		"ar": "ما هو جنسك ،",
		"pt": "Qual é o seu gênero,",
		"bn": "আপনার লিঙ্গ কি,",
		"ru": "Ваш пол,",
		"ja": "あなたの性別は何ですか、",
		"de": "Welches Geschlecht hast du,",
		"ms": "Apa jantina anda,",
		"vi": "Giới tính của bạn là gì,",
		"fr": "Quel est votre sexe,",
		"tr": "Cinsiyetin ne,",
		"it": "Qual è il tuo genere,",
		"th": "เพศของคุณคืออะไร",
		"fa": "جنسیت شما چیست ،",
		"pl": "Jaka jest twoja płeć,",
		"uk": "Яка твоя стать,",
		"uz": "Sizning jinsingiz qanday,",
		"ro": "Care este genul tău,",
		"az": "Cinsiyyətiniz nədir,",
		"hr": "Koji je tvoj spol,",
		"bg": "Какъв е твоят пол,",
		"sr": "Који је твој пол,",
		"hy": "Որն է ձեր սեռը,",
		"eu": "Zein den zure generoa,",
		"be": "Які ў цябе пол,",
		"ca": "Quin és el teu gènere,",
		"cs": "Jaké je vaše pohlaví,",
		"da": "Hvad er dit køn,",
		"nl": "Wat uw geslacht is,",
		"et": "Mis on teie sugu,",
		"tl": "Ano ang iyong kasarian,",
		"gl": "Cal é o teu xénero,",
		"ka": "როგორია შენი სქესი,",
		"el": "Ποιο είναι το φύλο σου,",
		"hu": "Mi a nemed,",
		"is": "Hvert kyn þitt er,",
		"lv": "Kāds ir jūsu dzimums,",
		"lt": "Kokia tavo lytis,",
		"mk": "Кој е вашиот пол,",
		"no": "Hva er kjønnet ditt,",
		"sk": "Aké je vaše pohlavie,",
		"sl": "Kakšnega spola si,",
		"sv": "Vad är ditt kön,",
		"fi": "Mikä on sukupuolesi,",
		"af": "Wat is jou geslag,",
		"sq": "Cila është gjinia juaj,",
		"am": "ጾታዎ ምንድነው ፣",
		"my": "မင်းရဲ့လိင်အမျိုးအစားကဘာလဲ",
		"gu": "તમારું લિંગ શું છે,",
		"he": "מה המגדר שלך,",
		"id": "Apa jenis kelamin Anda,",
		"kn": "ನಿಮ್ಮ ಲಿಂಗ ಯಾವುದು,",
		"kk": "Сіздің жынысыңыз қандай,",
		"km": "តើភេទរបស់អ្នកគឺជាអ្វី",
		"ky": "Сенин жынысың кандай,",
		"lo": "ເພດຂອງເຈົ້າແມ່ນຫຍັງ,",
		"ml": "നിങ്ങളുടെ ലിംഗഭേദം എന്താണ്,",
		"mr": "तुमचे लिंग काय आहे,",
		"mn": "Таны хүйс хэд вэ,",
		"ne": "तपाइँको लिंग के हो,",
		"pa": "ਤੁਹਾਡਾ ਲਿੰਗ ਕੀ ਹੈ,",
		"si": "ඔබේ ලිංගභේදය කුමක්ද,",
		"sw": "Jinsia yako ni nini,",
		"ta": "உங்கள் பாலினம் என்ன,",
		"te": "మీ లింగం ఏమిటి,",
		"ur": "آپ کی جنس کیا ہے ،",
		"zu": "Yini ubulili bakho,",
	},
	"whatYourSocialClassIs": {
		"en": "What your social class is,",
		"es": "Cual es tu clase social",
		"zh": "你的社会阶层是什么，",
		"hi": "आपका सामाजिक वर्ग क्या है,",
		"ar": "ما هي طبقتك الاجتماعية ،",
		"pt": "Qual é a sua classe social,",
		"bn": "আপনার সামাজিক শ্রেণী কি,",
		"ru": "Каков ваш социальный класс,",
		"ja": "あなたの社会階級は何ですか、",
		"de": "Welche soziale Schicht hast du,",
		"ms": "Apa kelas sosial anda,",
		"vi": "Tầng lớp xã hội của bạn là gì,",
		"fr": "Quelle est votre classe sociale,",
		"tr": "Sosyal sınıfınız nedir,",
		"it": "Qual è la tua classe sociale,",
		"th": "ชนชั้นทางสังคมของคุณคืออะไร",
		"fa": "طبقه اجتماعی شما چیست ،",
		"pl": "Jaka jest twoja klasa społeczna,",
		"uk": "Який у вас соціальний клас,",
		"uz": "Sizning ijtimoiy tabaqangiz qanday?",
		"ro": "Care este clasa ta socială,",
		"az": "Sosial sinifiniz nədir,",
		"hr": "Koji je vaš društveni sloj,",
		"bg": "Каква е вашата социална класа,",
		"sr": "Која је ваша друштвена класа,",
		"hy": "Ո՞րն է ձեր սոցիալական դասը,",
		"eu": "Zein da zure klase soziala,",
		"be": "Які ваш сацыяльны клас,",
		"ca": "Quina és la vostra classe social,",
		"cs": "Jaká je vaše sociální třída,",
		"da": "Hvad er din sociale klasse,",
		"nl": "Wat uw sociale klasse is,",
		"et": "Milline on teie sotsiaalne klass,",
		"tl": "Ano ang iyong klase sa lipunan,",
		"gl": "Cal é a túa clase social,",
		"ka": "როგორია შენი სოციალური კლასი,",
		"el": "Ποια είναι η κοινωνική σας τάξη,",
		"hu": "Mi a társadalmi osztályod,",
		"is": "Hver er félagsstéttin þín,",
		"lv": "Kāda ir jūsu sociālā šķira,",
		"lt": "Kokia jūsų socialinė klasė,",
		"mk": "Која е вашата социјална класа,",
		"no": "Hva er din sosiale klasse",
		"sk": "Aká je vaša sociálna trieda,",
		"sl": "Kakšen je vaš družbeni sloj,",
		"sv": "Vad är din sociala klass,",
		"fi": "Mikä on sosiaalinen luokkasi,",
		"af": "Wat is u sosiale klas,",
		"sq": "Cila është klasa juaj shoqërore,",
		"am": "የእርስዎ ማህበራዊ ክፍል ምንድነው ፣",
		"my": "မင်းရဲ့လူမှုအဆင့်အတန်းကဘာလဲ။",
		"gu": "તમારો સામાજિક વર્ગ કેવો છે,",
		"he": "מה המעמד החברתי שלך,",
		"id": "Apa kelas sosial Anda,",
		"kn": "ನಿಮ್ಮ ಸಾಮಾಜಿಕ ವರ್ಗ ಯಾವುದು",
		"kk": "Сіздің әлеуметтік тап қандай?",
		"km": "តើថ្នាក់សង្គមរបស់អ្នកគឺជាអ្វី",
		"ky": "Сиздин социалдык классыңыз кандай,",
		"lo": "ຊັ້ນສັງຄົມຂອງເຈົ້າແມ່ນຫຍັງ,",
		"ml": "നിങ്ങളുടെ സാമൂഹിക വർഗം എന്താണ്,",
		"mr": "तुमचा सामाजिक वर्ग काय आहे,",
		"mn": "Танай нийгмийн анги хэд вэ?",
		"ne": "तपाइँको सामाजिक वर्ग के हो,",
		"pa": "ਤੁਹਾਡੀ ਸਮਾਜਿਕ ਸ਼੍ਰੇਣੀ ਕੀ ਹੈ,",
		"si": "ඔබේ සමාජ පන්තිය කුමක්ද,",
		"sw": "Jamii yako ya kijamii ni nini,",
		"ta": "உங்கள் சமூக வகுப்பு என்ன",
		"te": "మీ సామాజిక తరగతి ఏమిటి",
		"ur": "آپ کا سماجی طبقہ کیا ہے ،",
		"zu": "Yini isigaba sakho senhlalo,",
	},
	"whatYourBeliefIs": {
		"en": "What your belief is,",
		"es": "¿Cuál es tu creencia?",
		"zh": "你的信念是什么，",
		"hi": "आपका क्या विश्वास है,",
		"ar": "ما هو ايمانك",
		"pt": "Qual é a sua crença,",
		"bn": "তোমার বিশ্বাস কি,",
		"ru": "Во что ты веришь,",
		"ja": "あなたの信念は何ですか、",
		"de": "Was ist dein Glaube,",
		"ms": "Apa kepercayaan anda,",
		"vi": "Niềm tin của bạn là gì,",
		"fr": "Quelle est votre croyance,",
		"tr": "İnancınız nedir,",
		"it": "qual è la tua convinzione,",
		"th": "ความเชื่อของคุณคืออะไร",
		"fa": "اعتقاد شما چیست ،",
		"pl": "Jakie jest twoje przekonanie,",
		"uk": "Яка ваша віра,",
		"uz": "Sizning e'tiqodingiz nima,",
		"ro": "Care este credința ta,",
		"az": "İnancınız nədir",
		"hr": "Koje je tvoje uvjerenje,",
		"bg": "Каква е твоята вяра,",
		"sr": "У шта верујете,",
		"hy": "Ո՞րն է քո հավատը,",
		"eu": "Zein da zure ustea,",
		"be": "У што вы верыце,",
		"ca": "Quina és la teva creença,",
		"cs": "Jaké je vaše přesvědčení,",
		"da": "Hvad er din tro,",
		"nl": "Wat is uw overtuiging,",
		"et": "Mis on teie usk,",
		"tl": "Ano ang iyong paniniwala,",
		"gl": "Cal é a túa crenza,",
		"ka": "როგორია თქვენი რწმენა,",
		"el": "Ποια είναι η πεποίθησή σου,",
		"hu": "Mi a hited,",
		"is": "Hver trú þín er,",
		"lv": "Kāda ir tava pārliecība,",
		"lt": "Koks tavo tikėjimas,",
		"mk": "Кое е вашето верување,",
		"no": "Hva er din tro,",
		"sk": "Aké je tvoje presvedčenie,",
		"sl": "Kakšno je vaše prepričanje,",
		"sv": "Vad är din tro,",
		"fi": "Mikä on uskosi,",
		"af": "Wat jou geloof is,",
		"sq": "Cili është besimi juaj,",
		"am": "እምነትዎ ምንድነው ፣",
		"my": "မင်းရဲ့ယုံကြည်ချက်ကဘာလဲ",
		"gu": "તમારી માન્યતા શું છે,",
		"he": "מהי האמונה שלך,",
		"id": "Apa keyakinan Anda,",
		"kn": "ನಿಮ್ಮ ನಂಬಿಕೆ ಏನು,",
		"kk": "Сіздің сеніміңіз қандай,",
		"km": "តើអ្វីទៅជាជំនឿរបស់អ្នក,",
		"ky": "Сиздин ишенимиңиз кандай,",
		"lo": "ຄວາມເຊື່ອຂອງເຈົ້າແມ່ນຫຍັງ,",
		"ml": "നിങ്ങളുടെ വിശ്വാസം എന്താണ്,",
		"mr": "तुमचा विश्वास काय आहे,",
		"mn": "Таны итгэл үнэмшил юу вэ,",
		"ne": "तिम्रो बिश्वास के हो,",
		"pa": "ਤੁਹਾਡਾ ਵਿਸ਼ਵਾਸ ਕੀ ਹੈ,",
		"si": "ඔබේ විශ්වාසය කුමක්ද,",
		"sw": "Imani yako ni nini,",
		"ta": "உங்கள் நம்பிக்கை என்ன,",
		"te": "మీ నమ్మకం ఏమిటి,",
		"ur": "آپ کا عقیدہ کیا ہے ،",
		"zu": "Iyini inkolelo yakho,",
	},
	"doesntMatter": {
		"en": "Doesn't matter.",
		"es": "No importa.",
		"zh": "没关系。",
		"hi": "कोई फर्क नहीं पड़ता।",
		"ar": "لا يهم.",
		"pt": "Não importa.",
		"bn": "ব্যাপার না।",
		"ru": "Неважно.",
		"ja": "関係ありません。",
		"de": "Ist egal.",
		"ms": "Tidak penting.",
		"vi": "Không thành vấn đề.",
		"fr": "Peu importe.",
		"tr": "Önemli değil.",
		"it": "Non importa.",
		"th": "ไม่เป็นไร",
		"fa": "مهم نیست",
		"pl": "Nie ma znaczenia.",
		"uk": "Не має значення.",
		"uz": "Bu muhim emas.",
		"ro": "Nu contează.",
		"az": "Fərqi yoxdur.",
		"hr": "Nije važno.",
		"bg": "Няма значение.",
		"sr": "Није важно.",
		"hy": "Նշանակություն չունի:",
		"eu": "Ez du axola.",
		"be": "Няважна.",
		"ca": "No importa.",
		"cs": "Nevadí.",
		"da": "Det er ligegyldigt.",
		"nl": "Maakt niet uit.",
		"et": "Pole tähtis.",
		"tl": "Hindi mahalaga.",
		"gl": "Non importa.",
		"ka": "არ აქვს მნიშვნელობა.",
		"el": "Δεν πειράζει.",
		"hu": "Nem számít.",
		"is": "Skiptir ekki máli.",
		"lv": "Nav nozīmes.",
		"lt": "Nesvarbu.",
		"mk": "Не е важно.",
		"no": "Spiller ingen rolle.",
		"sk": "To je jedno.",
		"sl": "Ni važno.",
		"sv": "Spelar ingen roll.",
		"fi": "Ei väliä.",
		"af": "Maak nie saak nie.",
		"sq": "Nuk ka rendesi.",
		"am": "ምንም አይደለም።",
		"my": "အရေးမပါဘူး။",
		"gu": "વાંધો નથી.",
		"he": "לא משנה.",
		"id": "Tidak masalah.",
		"kn": "ಪರವಾಗಿಲ್ಲ.",
		"kk": "Маңызды емес.",
		"km": "មិនមានបញ្ហាទេ។",
		"ky": "Мааниси жок.",
		"lo": "ບໍ່ເປັນຫຍັງ.",
		"ml": "സാരമില്ല.",
		"mr": "काही फरक पडत नाही.",
		"mn": "Энэ хамаагүй.",
		"ne": "फरक पर्दैन।",
		"pa": "ਕੋਈ ਫਰਕ ਨਹੀਂ ਪੈਂਦਾ.",
		"si": "කමක් නැහැ.",
		"sw": "Haijalishi.",
		"ta": "பரவாயில்லை",
		"te": "పట్టింపు లేదు.",
		"ur": "کوئی فرق نہیں پڑتا۔",
		"zu": "Akunandaba.",
	},
	"allMenAreCreatedEqual": {
		"en": "All men are created equal,",
		"es": "Todos los hombres son creados iguales,",
		"zh": "所有的人都生来平等，",
		"hi": "सभी पुरुष समान हैं,",
		"ar": "كل البشر خلقوا متساويين،",
		"pt": "Todos os homens são criados iguais,",
		"bn": "সব পুরুষদের সমান তৈরি করা হয়,",
		"ru": "Все люди созданы равными,",
		"ja": "全ての人は平等に創られている、",
		"de": "Alle Menschen sind gleich geschaffen,",
		"ms": "Semua lelaki diciptakan sama,",
		"vi": "Tất cả đàn ông được tạo ra bình đẳng,",
		"fr": "Tous les hommes sont créés égaux,",
		"tr": "Bütün insanlar eşit yaratılmıştır,",
		"it": "Tutti gli uomini sono creati uguali,",
		"th": "มนุษย์ทุกคนถูกสร้างมาเท่าเทียมกัน",
		"fa": "همه انسانها مساوی افریده شده اند،",
		"pl": "Wszyscy ludzie są równi,",
		"uk": "Усі люди створені рівними,",
		"uz": "Hamma odamlar teng yaratilgan,",
		"ro": "Toți oamenii sunt creați egali,",
		"az": "Bütün insanlar bərabər yaradılmışdır,",
		"hr": "Svi ljudi su stvoreni jednaki,",
		"bg": "Всички хора са създадени равни,",
		"sr": "Сви људи су створени једнаки,",
		"hy": "Բոլոր տղամարդիկ ստեղծված են հավասար,",
		"eu": "Gizon guztiak berdinak dira,",
		"be": "Усе людзі створаны роўнымі,",
		"ca": "Tots els homes es creen iguals,",
		"cs": "Všichni lidé jsou si rovni",
		"da": "Alle mænd er skabt lige,",
		"nl": "Alle mannen zijn gelijk geschapen,",
		"et": "Kõik mehed on loodud võrdseks,",
		"tl": "Lahat ng tao ay ginawang pantay-pantay,",
		"gl": "Todos os homes son iguais,",
		"ka": "ყველა კაცი შექმნილია თანაბრად,",
		"el": "Όλοι οι άνθρωποι δημιουργούνται ίσοι,",
		"hu": "Minden ember egyenlőnek teremtetett,",
		"is": "Allir menn eru skapaðir jafnir,",
		"lv": "Visi vīrieši ir radīti vienādi,",
		"lt": "Visi vyrai sukurti lygūs,",
		"mk": "Сите мажи се создадени еднакви,",
		"no": "Alle mennesker er skapt like,",
		"sk": "Všetci ľudia sú si rovní,",
		"sl": "Vsi moški so ustvarjeni enaki,",
		"sv": "Alla män är skapade lika,",
		"fi": "Kaikki ihmiset on luotu tasavertaisiksi,",
		"af": "Alle mense is gelyk geskape,",
		"sq": "Të gjithë njerëzit janë krijuar të barabartë,",
		"am": "ሁሉም ሰዎች እኩል ተፈጥረዋል ፣",
		"my": "လူသားအားလုံးကိုတန်းတူဖန်တီးထားတယ်၊",
		"gu": "બધા માણસો સમાન બનાવવામાં આવ્યા છે,",
		"he": "כל הגברים נבראו שווים,",
		"id": "Semua manusia diciptakan sama,",
		"kn": "ಎಲ್ಲಾ ಪುರುಷರನ್ನು ಸಮಾನವಾಗಿ ಸೃಷ್ಟಿಸಲಾಗಿದೆ,",
		"kk": "Барлық адамдар тең жаратылған,",
		"km": "បុរសទាំងអស់ត្រូវបានបង្កើតឡើងស្មើៗគ្នា",
		"ky": "Бардык эркектер бирдей жаратылган,",
		"lo": "ຜູ້ຊາຍທຸກຄົນຖືກສ້າງຂື້ນມາເທົ່າທຽມກັນ,",
		"ml": "എല്ലാ മനുഷ്യരും തുല്യരായി സൃഷ്ടിക്കപ്പെട്ടിരിക്കുന്നു,",
		"mr": "सर्व पुरुष समान बनले आहेत,",
		"mn": "Бүх эрчүүд адил тэгш бүтээгдсэн,",
		"ne": "सबै पुरुष समान बनाइएका छन्,",
		"pa": "ਸਾਰੇ ਮਨੁੱਖ ਬਰਾਬਰ ਬਣਾਏ ਗਏ ਹਨ,",
		"si": "සියලුම මිනිසුන් සමාන ලෙස නිර්මාණය වී ඇත,",
		"sw": "Wanaume wote wameumbwa sawa,",
		"ta": "எல்லா ஆண்களும் சமமாக படைக்கப்பட்டனர்,",
		"te": "పురుషులందరూ సమానంగా సృష్టించబడ్డారు,",
		"ur": "تمام انسان برابر پیدا کیے گئے ہیں ،",
		"zu": "Bonke abantu badalwe belingana,",
	},
	"whenItComesToCryptocurrency": {
		"en": "When it comes to cryptocurrency.",
		"es": "Cuando se trata de criptomonedas.",
		"zh": "当谈到加密货币时。",
		"hi": "जब क्रिप्टोकरेंसी की बात आती है।",
		"ar": "عندما يتعلق الأمر بالعملات المشفرة.",
		"pt": "Quando se trata de criptomoeda.",
		"bn": "যখন ক্রিপ্টোকারেন্সির কথা আসে।",
		"ru": "Что касается криптовалюты.",
		"ja": "暗号通貨に関しては。",
		"de": "Wenn es um Kryptowährungen geht.",
		"ms": "Apabila berkaitan dengan cryptocurrency.",
		"vi": "Khi nói đến tiền điện tử.",
		"fr": "Quand il s'agit de crypto-monnaie.",
		"tr": "Kripto para birimine gelince.",
		"it": "Quando si tratta di criptovalute.",
		"th": "เมื่อพูดถึงสกุลเงินดิจิทัล",
		"fa": "وقتی صحبت از ارز رمزنگاری می شود.",
		"pl": "Jeśli chodzi o kryptowaluty.",
		"uk": "Що стосується криптовалюти.",
		"uz": "Kriptovalyuta haqida gap ketganda.",
		"ro": "Când vine vorba de criptomonede.",
		"az": "Kriptovalyutaya gəldikdə.",
		"hr": "Što se tiče kriptovalute.",
		"bg": "Що се отнася до криптовалутата.",
		"sr": "Када је у питању криптовалута.",
		"hy": "Երբ խոսքը վերաբերում է կրիպտոարժույթին:",
		"eu": "Kriptokonferentziari dagokionez.",
		"be": "Што тычыцца криптовалюты.",
		"ca": "Quan es tracta de criptomonedes.",
		"cs": "Pokud jde o kryptoměnu.",
		"da": "Når det kommer til kryptovaluta.",
		"nl": "Als het om cryptovaluta gaat.",
		"et": "Kui rääkida krüptorahast.",
		"tl": "Pagdating sa cryptocurrency.",
		"gl": "Cando se trata de criptocurrency.",
		"ka": "როდესაც საქმე ეხება კრიპტოვალუტას.",
		"el": "Όταν πρόκειται για κρυπτονόμισμα.",
		"hu": "Ami a kriptovalutát illeti.",
		"is": "Þegar kemur að cryptocurrency.",
		"lv": "Runājot par kriptovalūtu.",
		"lt": "Kalbant apie kriptovaliutą.",
		"mk": "Кога станува збор за крипто валута.",
		"no": "Når det gjelder kryptovaluta.",
		"sk": "Pokiaľ ide o kryptomenu.",
		"sl": "Ko gre za kriptovalute.",
		"sv": "När det gäller kryptovaluta.",
		"fi": "Mitä tulee kryptovaluuttaan.",
		"af": "As dit kom by kriptokurrency.",
		"sq": "Kur bëhet fjalë për kriptomonedhën.",
		"am": "ወደ ክሪፕቶሪ ሲመጣ።",
		"my": "cryptocurrency နှင့်ပတ်သက်လာလျှင်",
		"gu": "જ્યારે ક્રિપ્ટોકરન્સીની વાત આવે છે.",
		"he": "כשזה מגיע לקריפטו.",
		"id": "Ketika datang ke cryptocurrency.",
		"kn": "ಕ್ರಿಪ್ಟೋ ಕರೆನ್ಸಿಯ ವಿಷಯಕ್ಕೆ ಬಂದರೆ.",
		"kk": "Криптовалютаға қатысты.",
		"km": "នៅពេលដែលវាមកដល់ cryptocurrency ។",
		"ky": "Бул cryptocurrency келгенде.",
		"lo": "ເມື່ອເວົ້າເຖິງ cryptocurrency.",
		"ml": "ക്രിപ്‌റ്റോകറൻസിയുടെ കാര്യം വരുമ്പോൾ.",
		"mr": "जेव्हा क्रिप्टोकरन्सीचा प्रश्न येतो.",
		"mn": "Криптовалютын тухайд.",
		"ne": "जब यो cryptocurrency को लागी आउँछ।",
		"pa": "ਜਦੋਂ ਇਹ ਕ੍ਰਿਪਟੋਕੁਰੰਸੀ ਦੀ ਗੱਲ ਆਉਂਦੀ ਹੈ.",
		"si": "ගුප්ත ව්යවහාර මුදල් ගැන කතා කරන විට.",
		"sw": "Linapokuja suala la cryptocurrency.",
		"ta": "கிரிப்டோகரன்சிக்கு வரும்போது.",
		"te": "క్రిప్టోకరెన్సీ విషయానికి వస్తే.",
		"ur": "جب یہ cryptocurrency کی بات آتی ہے۔",
		"zu": "Uma kukhulunywa nge-cryptocurrency.",
	},
	"itDoesntDiscriminate": {
		"en": "It doesn't discriminate,",
		"es": "No discrimina",
		"zh": "不歧视，",
		"hi": "यह भेदभाव नहीं करता,",
		"ar": "لا تميز ،",
		"pt": "Não discrimina,",
		"bn": "এটা বৈষম্য করে না,",
		"ru": "Он не различает,",
		"ja": "それは差別しません、",
		"de": "Es diskriminiert nicht,",
		"ms": "Ia tidak membeza-bezakan,",
		"vi": "Nó không phân biệt đối xử,",
		"fr": "Il ne fait pas de discrimination,",
		"tr": "Ayrımcılık yapmaz,",
		"it": "non discrimina,",
		"th": "มันไม่เลือกปฏิบัติ",
		"fa": "تبعیض قائل نمی شود ،",
		"pl": "Nie dyskryminuje,",
		"uk": "Це не дискримінує,",
		"uz": "Bu kamsitmaydi,",
		"ro": "Nu discriminează,",
		"az": "Ayrı -seçkilik etməz,",
		"hr": "Ne diskriminira,",
		"bg": "Не дискриминира,",
		"sr": "Не дискриминише,",
		"hy": "Այն խտրականություն չի դնում,",
		"eu": "Ez du diskriminatzen,",
		"be": "Гэта не дыскрымінуе,",
		"ca": "No discrimina,",
		"cs": "Nediskriminuje,",
		"da": "Det diskriminerer ikke,",
		"nl": "Het discrimineert niet,",
		"et": "See ei diskrimineeri,",
		"tl": "Hindi ito nagtatangi,",
		"gl": "Non discrimina,",
		"ka": "ის არ აკეთებს დისკრიმინაციას,",
		"el": "Δεν κάνει διακρίσεις,",
		"hu": "Nem diszkriminál,",
		"is": "Það mismunar ekki,",
		"lv": "Tas nediskriminē,",
		"lt": "Tai nediskriminuoja,",
		"mk": "Не прави дискриминација,",
		"no": "Det diskriminerer ikke,",
		"sk": "Nediskriminuje to,",
		"sl": "Ne diskriminira,",
		"sv": "Det diskriminerar inte,",
		"fi": "Se ei syrji,",
		"af": "Dit diskrimineer nie,",
		"sq": "Nuk diskriminon,",
		"am": "አድሎ አያደርግም ፣",
		"my": "ဒါဟာခွဲခြားတာမဟုတ်ဘူး၊",
		"gu": "તે ભેદભાવ કરતો નથી,",
		"he": "זה לא מפלה,",
		"id": "Tidak membeda-bedakan,",
		"kn": "ಇದು ತಾರತಮ್ಯ ಮಾಡುವುದಿಲ್ಲ,",
		"kk": "Бұл кемсіту емес,",
		"km": "វាមិនរើសអើង,",
		"ky": "Бул басмырлоого жол бербейт,",
		"lo": "ມັນບໍ່ໄດ້ ຈຳ ແນກ,",
		"ml": "അത് വിവേചനം കാണിക്കുന്നില്ല,",
		"mr": "तो भेदभाव करत नाही,",
		"mn": "Энэ нь ялгаварлан гадуурхдаггүй,",
		"ne": "यसले भेदभाव गर्दैन,",
		"pa": "ਇਹ ਭੇਦਭਾਵ ਨਹੀਂ ਕਰਦਾ,",
		"si": "එය වෙනස්කම් කරන්නේ නැත,",
		"sw": "Haibagui,",
		"ta": "இது பாகுபாடு இல்லை,",
		"te": "ఇది వివక్ష చూపదు,",
		"ur": "یہ امتیازی سلوک نہیں کرتا ،",
		"zu": "Akubandlululi,",
	},
	"whentherYouAreFromVenezuela": {
		"en": "Whether you are from Venezuela,",
		"es": "Si eres de Venezuela,",
		"zh": "无论你来自委内瑞拉，",
		"hi": "चाहे आप वेनेजुएला से हों,",
		"ar": "سواء كنت من فنزويلا ،",
		"pt": "Se você é da Venezuela,",
		"bn": "আপনি ভেনিজুয়েলা থেকে হন,",
		"ru": "Будь вы из Венесуэлы,",
		"ja": "あなたがベネズエラ出身であるかどうかにかかわらず、",
		"de": "Ob Sie aus Venezuela kommen,",
		"ms": "Sama ada anda dari Venezuela,",
		"vi": "Cho dù bạn đến từ Venezuela,",
		"fr": "Que vous soyez du Venezuela,",
		"tr": "İster Venezuela'lı olun,",
		"it": "Che tu sia venezuelano,",
		"th": "ไม่ว่าคุณจะมาจากเวเนซุเอลา",
		"fa": "خواه اهل ونزوئلا هستید ،",
		"pl": "Czy jesteś z Wenezueli,",
		"uk": "Чи ви з Венесуели,",
		"uz": "Siz Venesueladan bo'lsangiz ham,",
		"ro": "Fie că sunteți din Venezuela,",
		"az": "İstər Venesuelalı olsan,",
		"hr": "Bilo da ste iz Venezuele,",
		"bg": "Независимо дали сте от Венецуела,",
		"sr": "Било да сте из Венецуеле,",
		"hy": "Անկախ նրանից, թե դուք Վենեսուելայից եք,",
		"eu": "Venezuelakoa zaren ala ez,",
		"be": "Вы з Венесуэлы,",
		"ca": "Tant si sou de Veneçuela,",
		"cs": "Ať už jste z Venezuely,",
		"da": "Uanset om du er fra Venezuela,",
		"nl": "Of je nu uit Venezuela komt,",
		"et": "Ükskõik, kas olete pärit Venezuelast,",
		"tl": "Kung ikaw man ay mula sa Venezuela,",
		"gl": "Se es de Venezuela,",
		"ka": "ხართ თუ არა ვენესუელადან,",
		"el": "Είτε είστε από τη Βενεζουέλα,",
		"hu": "Akár Venezuelából származik,",
		"is": "Hvort sem þú ert frá Venesúela,",
		"lv": "Neatkarīgi no tā, vai esat no Venecuēlas,",
		"lt": "Nesvarbu, ar esate iš Venesuelos,",
		"mk": "Без разлика дали сте од Венецуела,",
		"no": "Enten du er fra Venezuela,",
		"sk": "Či už ste z Venezuely,",
		"sl": "Ali ste iz Venezuele,",
		"sv": "Oavsett om du kommer från Venezuela,",
		"fi": "Olitpa kotoisin Venezuelasta,",
		"af": "Of jy nou uit Venezuela kom,",
		"sq": "Pavarësisht nëse jeni nga Venezuela,",
		"am": "እርስዎ ከቬንዙዌላ ይሁኑ ፣",
		"my": "မင်းဗင်နီဇွဲလားကနေပဲဖြစ်ဖြစ်",
		"gu": "તમે વેનેઝુએલાના છો,",
		"he": "בין אם אתה מוונצואלה,",
		"id": "Apakah Anda berasal dari Venezuela,",
		"kn": "ನೀವು ವೆನಿಜುವೆಲಾದವರಾಗಿದ್ದರೂ,",
		"kk": "Сіз Венесуэладан болсаңыз да,",
		"km": "មិនថាអ្នកមកពីវេណេស៊ុយអេឡាទេ",
		"ky": "Сиз Венесуэладан болосузбу,",
		"lo": "ບໍ່ວ່າເຈົ້າຈະມາຈາກເວເນຊູເອລາ,",
		"ml": "നിങ്ങൾ വെനിസ്വേലയിൽ നിന്നാണെങ്കിലും,",
		"mr": "आपण व्हेनेझुएलाचे आहात,",
		"mn": "Та Венесуэлээс ирсэн эсэхээс үл хамааран",
		"ne": "चाहे तपाइँ भेनेजुएला बाट हो,",
		"pa": "ਭਾਵੇਂ ਤੁਸੀਂ ਵੈਨੇਜ਼ੁਏਲਾ ਤੋਂ ਹੋ,",
		"si": "ඔබ වෙනිසියුලාවේ වේවා,",
		"sw": "Ikiwa unatoka Venezuela,",
		"ta": "நீங்கள் வெனிசுலாவைச் சேர்ந்தவராக இருந்தாலும்,",
		"te": "మీరు వెనిజులా నుండి వచ్చినా,",
		"ur": "چاہے آپ وینزویلا سے ہیں ،",
		"zu": "Noma ngabe uvela eVenezuela,",
	},
	"orFromUnitedState": {
		"en": "Or from the United States.",
		"es": "O de Estados Unidos.",
		"zh": "或者来自美国。",
		"hi": "या संयुक्त राज्य अमेरिका से।",
		"ar": "أو من الولايات المتحدة.",
		"pt": "Ou dos Estados Unidos.",
		"bn": "অথবা মার্কিন যুক্তরাষ্ট্র থেকে।",
		"ru": "Или из США.",
		"ja": "または米国から。",
		"de": "Oder aus den USA.",
		"ms": "Atau dari Amerika Syarikat.",
		"vi": "Hoặc từ Hoa Kỳ.",
		"fr": "Ou des États-Unis.",
		"tr": "Veya Amerika Birleşik Devletleri'nden.",
		"it": "O dagli Stati Uniti.",
		"th": "หรือจากประเทศสหรัฐอเมริกา",
		"fa": "یا از ایالات متحده.",
		"pl": "Albo ze Stanów Zjednoczonych.",
		"uk": "Або зі США.",
		"uz": "Yoki AQShdan.",
		"ro": "Sau din Statele Unite.",
		"az": "Və ya ABŞ -dan.",
		"hr": "Ili iz Sjedinjenih Država.",
		"bg": "Или от САЩ.",
		"sr": "Или из Сједињених Држава.",
		"hy": "Կամ ԱՄՆ -ից:",
		"eu": "Edo Estatu Batuetatik.",
		"be": "Ці з ЗША.",
		"ca": "O dels Estats Units.",
		"cs": "Nebo ze Spojených států.",
		"da": "Eller fra USA.",
		"nl": "Of uit de Verenigde Staten.",
		"et": "Või Ameerika Ühendriikidest.",
		"tl": "O galing sa Estados Unidos.",
		"gl": "Ou dos Estados Unidos.",
		"ka": "ან შეერთებული შტატებიდან.",
		"el": "Or από τις Ηνωμένες Πολιτείες.",
		"hu": "Vagy az Egyesült Államokból.",
		"is": "Eða frá Bandaríkjunum.",
		"lv": "Vai arī no ASV.",
		"lt": "Arba iš JAV.",
		"mk": "Или од Соединетите држави.",
		"no": "Eller fra USA.",
		"sk": "Alebo z USA.",
		"sl": "Ali pa iz Združenih držav.",
		"sv": "Eller från USA.",
		"fi": "Tai Yhdysvalloista.",
		"af": "Of uit die Verenigde State.",
		"sq": "Ose nga Shtetet e Bashkuara.",
		"am": "ወይም ከአሜሪካ።",
		"my": "သို့မဟုတ်အမေရိကန်မှဖြစ်သည်။",
		"gu": "અથવા યુનાઇટેડ સ્ટેટ્સમાંથી.",
		"he": "או מארצות הברית.",
		"id": "Atau dari Amerika Serikat.",
		"kn": "ಅಥವಾ ಯುನೈಟೆಡ್ ಸ್ಟೇಟ್ಸ್ ನಿಂದ.",
		"kk": "Немесе Америка Құрама Штаттарынан.",
		"km": "ឬមកពីសហរដ្ឋអាមេរិក។",
		"ky": "Же Америка Кошмо Штаттарынан.",
		"lo": "ຫຼືຈາກສະຫະລັດ.",
		"ml": "അല്ലെങ്കിൽ അമേരിക്കയിൽ നിന്ന്.",
		"mr": "किंवा अमेरिकेतून.",
		"mn": "Эсвэл АНУ -аас ирсэн.",
		"ne": "वा संयुक्त राज्य बाट।",
		"pa": "ਜਾਂ ਸੰਯੁਕਤ ਰਾਜ ਤੋਂ.",
		"si": "නැත්නම් එක්සත් ජනපදයෙන්.",
		"sw": "Au kutoka Merika.",
		"ta": "அல்லது அமெரிக்காவில் இருந்து.",
		"te": "లేదా యునైటెడ్ స్టేట్స్ నుండి.",
		"ur": "یا امریکہ سے۔",
		"zu": "Noma kusuka e-United States.",
	},
	"whetherYouAreAWallStreetBanker": {
		"en": "Whether you are a Wall Street banker,",
		"es": "Si es banquero de Wall Street,",
		"zh": "无论你是华尔街银行家，",
		"hi": "चाहे आप वॉल स्ट्रीट बैंकर हों,",
		"ar": "سواء كنت مصرفيًا في وول ستريت ،",
		"pt": "Quer você seja um banqueiro de Wall Street,",
		"bn": "আপনি ওয়াল স্ট্রিট ব্যাংকার কিনা,",
		"ru": "Если вы банкир с Уолл-стрит,",
		"ja": "あなたがウォール街の銀行家であるかどうかにかかわらず、",
		"de": "Egal, ob Sie ein Wall Street-Banker sind,",
		"ms": "Sama ada anda seorang pegawai bank Wall Street,",
		"vi": "Cho dù bạn là chủ ngân hàng Phố Wall,",
		"fr": "Que vous soyez banquier de Wall Street,",
		"tr": "İster Wall Street bankacısı olun,",
		"it": "Che tu sia un banchiere di Wall Street,",
		"th": "ไม่ว่าคุณจะเป็นนายธนาคารในวอลล์สตรีท",
		"fa": "خواه بانکدار وال استریت باشید ،",
		"pl": "Niezależnie od tego, czy jesteś bankierem z Wall Street,",
		"uk": "Чи ви банкір з Уолл -стріт,",
		"uz": "Siz Uoll -stritdagi bankir bo'lasizmi,",
		"ro": "Fie că sunteți bancher pe Wall Street,",
		"az": "Bir Wall Street bankiri olsanız da,",
		"hr": "Bilo da ste bankar sa Wall Streeta,",
		"bg": "Независимо дали сте банкер на Уолстрийт,",
		"sr": "Било да сте банкар са Валл Стреета,",
		"hy": "Անկախ նրանից, թե դուք Ուոլ Սթրիթի բանկիր եք,",
		"eu": "Wall Street-eko bankaria izan ala ez,",
		"be": "Калі вы банкір з Уол -стрыт,",
		"ca": "Tant si sou banquer de Wall Street,",
		"cs": "Ať už jste bankéř z Wall Street,",
		"da": "Uanset om du er bankmand på Wall Street,",
		"nl": "Of u nu een Wall Street bankier bent,",
		"et": "Ükskõik, kas olete Wall Streeti pankur,",
		"tl": "Kung ikaw man ay isang taga-bangko sa Wall Street,",
		"gl": "Se es banqueiro de Wall Street,",
		"ka": "ხართ თუ არა უოლ სტრიტის ბანკირი,",
		"el": "Είτε είστε τραπεζίτης της Wall Street,",
		"hu": "Akár Wall Street -i bankár,",
		"is": "Hvort sem þú ert bankastjóri á Wall Street,",
		"lv": "Neatkarīgi no tā, vai esat Volstrītas baņķieris,",
		"lt": "Nesvarbu, ar esate „Wall Street“ bankininkas,",
		"mk": "Без разлика дали сте банкар на Волстрит,",
		"no": "Enten du er en Wall Street -bankmann,",
		"sk": "Či už ste bankárom na Wall Street,",
		"sl": "Ne glede na to, ali ste bankir na Wall Streetu,",
		"sv": "Oavsett om du är en Wall Street -bankir,",
		"fi": "Olitpa Wall Streetin pankkiiri,",
		"af": "Of u nou 'n Wall Street -bankier is,",
		"sq": "Nëse jeni bankier i Wall Street,",
		"am": "እርስዎ የዎል ስትሪት ባለ ባንክ ይሁኑ ፣",
		"my": "သင်သည် Wall Street ဘဏ်လုပ်ငန်းရှင်တစ် ဦး ဖြစ်သည်ဖြစ်စေ၊",
		"gu": "ભલે તમે વોલ સ્ટ્રીટ બેંકર હો,",
		"he": "בין אם אתה בנקאי בוול סטריט,",
		"id": "Apakah Anda seorang bankir Wall Street,",
		"kn": "ನೀವು ವಾಲ್ ಸ್ಟ್ರೀಟ್ ಬ್ಯಾಂಕರ್ ಆಗಿರಲಿ,",
		"kk": "Сіз Уолл -стрит банкир болсаңыз да,",
		"km": "មិនថាអ្នកជាធនាគារិកនៅ Wall Street,",
		"ky": "Уолл -стрит банкири болобу,",
		"lo": "ບໍ່ວ່າເຈົ້າຈະເປັນທະນາຄານຂອງ Wall Street,",
		"ml": "നിങ്ങൾ ഒരു വാൾസ്ട്രീറ്റ് ബാങ്കർ ആണെങ്കിലും,",
		"mr": "तुम्ही वॉल स्ट्रीट बँकर असाल,",
		"mn": "Та Уолл Стрийтийн банкны ажилтан байсан ч бай,",
		"ne": "चाहे तपाइँ वाल स्ट्रीट बैंकर हो,",
		"pa": "ਭਾਵੇਂ ਤੁਸੀਂ ਵਾਲ ਸਟਰੀਟ ਦੇ ਬੈਂਕਰ ਹੋ,",
		"si": "ඔබ වෝල් වීදියේ බැංකු කරුවෙකු වුවත්,",
		"sw": "Ikiwa wewe ni benki ya Wall Street,",
		"ta": "நீங்கள் ஒரு வோல் ஸ்ட்ரீட் வங்கியாளராக இருந்தாலும்,",
		"te": "మీరు వాల్ స్ట్రీట్ బ్యాంకర్ అయినా,",
		"ur": "چاہے آپ وال اسٹریٹ بینکر ہوں ،",
		"zu": "Noma ngabe uyibhange laseWall Street,",
	},
	"orYouAreASmallChildOfIndianFarmer": {
		"en": "Or you are a small child from Indian farmer.",
		"es": "O eres un niño pequeño de un granjero indio.",
		"zh": "或者你是一个来自印度农民的小孩。",
		"hi": "या आप भारतीय किसान के एक छोटे बच्चे हैं।",
		"ar": "أم أنك طفل صغير من مزارع هندي.",
		"pt": "Ou você é uma criança pequena de um fazendeiro indiano.",
		"bn": "অথবা আপনি ভারতীয় কৃষকের ছোট সন্তান।",
		"ru": "Или вы маленький ребенок от индийского фермера.",
		"ja": "または、あなたはインドの農民の小さな子供です。",
		"de": "Oder Sie sind ein kleines Kind vom indischen Bauern.",
		"ms": "Atau anda adalah anak kecil dari petani India.",
		"vi": "Hoặc bạn là một đứa trẻ nhỏ từ nông dân Ấn Độ.",
		"fr": "Ou vous êtes un petit enfant d'un fermier indien.",
		"tr": "Ya da Hintli çiftçiden küçük bir çocuksunuz.",
		"it": "Oppure sei un bambino piccolo di un contadino indiano.",
		"th": "หรือคุณเป็นเด็กเล็กจากชาวนาอินเดีย",
		"fa": "یا شما یک کودک کوچک از کشاورز هندی هستید.",
		"pl": "Albo jesteś małym dzieckiem od indyjskiego rolnika.",
		"uk": "Або ви маленька дитина від індійського фермера.",
		"uz": "Yoki siz hind dehqonining kichkina farzandisiz.",
		"ro": "Sau ești un copil mic de la fermier indian.",
		"az": "Ya da hindli fermerdən balaca bir uşaqsan.",
		"hr": "Ili ste malo dijete iz indijskog poljoprivrednika.",
		"bg": "Или сте малко дете от индийски фермер.",
		"sr": "Или сте мало дете из индијског фармера.",
		"hy": "Կամ դուք փոքր երեխա եք հնդիկ ֆերմերից:",
		"eu": "Edo Indiako nekazariaren ume txikia zara.",
		"be": "Ці вы маленькае дзіця ад індыйскага фермера.",
		"ca": "O sou un nen petit de pagès indi.",
		"cs": "Nebo jste malé dítě od indického farmáře.",
		"da": "Eller du er et lille barn fra indisk landmand.",
		"nl": "Of je bent een klein kind van een Indiase boer.",
		"et": "Või olete väike laps India põllumehest.",
		"tl": "O ikaw ay isang maliit na bata mula sa magsasakang India.",
		"gl": "Ou es un neno pequeno de labrego indio.",
		"ka": "ან თქვენ პატარა ბავშვი ხართ ინდოელი ფერმერისგან.",
		"el": "Or είστε ένα μικρό παιδί από Ινδό αγρότη.",
		"hu": "Vagy egy kisgyerek az indiai farmertől.",
		"is": "Eða þú ert lítið barn frá indverskum bónda.",
		"lv": "Vai arī jūs esat mazs bērns no Indijas lauksaimnieka.",
		"lt": "Arba esate mažas vaikas iš Indijos ūkininko.",
		"mk": "Или сте мало дете од индиски земјоделец.",
		"no": "Eller du er et lite barn fra indisk bonde.",
		"sk": "Alebo ste malé dieťa z indického farmára.",
		"sl": "Ali pa ste majhen otrok indijskega kmeta.",
		"sv": "Eller så är du ett litet barn från indisk bonde.",
		"fi": "Tai olet intialaisen maanviljelijän pieni lapsi.",
		"af": "Of u is 'n klein kind van 'n Indiese boer.",
		"sq": "Ose ju jeni një fëmijë i vogël nga fermeri indian.",
		"am": "ወይም እርስዎ ከህንድ ገበሬ የመጡ ትንሽ ልጅ ነዎት።",
		"my": "ဒါမှမဟုတ်မင်းကအိန္ဒိယလယ်သမားတစ်ယောက်ကနေကလေးသေးသေးလေးတစ်ယောက်လား။",
		"gu": "અથવા તમે ભારતીય ખેડૂતના નાના બાળક છો.",
		"he": "או שאתה ילד קטן מאיכר הודי.",
		"id": "Atau Anda adalah anak kecil dari petani India.",
		"kn": "ಅಥವಾ ನೀವು ಭಾರತೀಯ ರೈತನ ಚಿಕ್ಕ ಮಗು.",
		"kk": "Немесе сіз үнді фермерінің кішкентай баласысыз.",
		"km": "ឬអ្នកគឺជាកូនតូចមកពីកសិករឥណ្ឌា។",
		"ky": "Же сен индиялык дыйкандын кичинекей баласысың.",
		"lo": "ຫຼືເຈົ້າເປັນເດັກນ້ອຍຈາກຊາວນາຊາວອິນເດຍ.",
		"ml": "അല്ലെങ്കിൽ നിങ്ങൾ ഇന്ത്യൻ കർഷകരിൽ നിന്നുള്ള ഒരു ചെറിയ കുട്ടിയാണ്.",
		"mr": "किंवा तुम्ही भारतीय शेतकऱ्याचे लहान मूल आहात.",
		"mn": "Эсвэл та Энэтхэгийн тариачны бяцхан хүүхэд юм.",
		"ne": "वा तपाइँ भारतीय किसान बाट एक सानो बच्चा हो।",
		"pa": "ਜਾਂ ਤੁਸੀਂ ਭਾਰਤੀ ਕਿਸਾਨ ਦੇ ਛੋਟੇ ਬੱਚੇ ਹੋ.",
		"si": "නැතහොත් ඔබ ඉන්දියානු ගොවියෙකුගෙන් කුඩා දරුවෙකි.",
		"sw": "Au wewe ni mtoto mdogo kutoka kwa mkulima wa India.",
		"ta": "அல்லது நீங்கள் இந்திய விவசாயியின் சிறு குழந்தை.",
		"te": "లేదా మీరు భారతీయ రైతు నుండి చిన్న పిల్లవాడిని.",
		"ur": "یا آپ ہندوستانی کسان سے چھوٹے بچے ہیں۔",
		"zu": "Noma uyingane encane evela kumlimi waseNdiya.",
	},
	"whetherYouAreFightingForFreedomNow": {
		"en": "Whether you are fighting for freedom now,",
		"es": "Ya sea que estés luchando por la libertad ahora,",
		"zh": "无论你现在是为自由而战，",
		"hi": "चाहे आप अभी आजादी के लिए लड़ रहे हों,",
		"ar": "سواء كنت تقاتل من أجل الحرية الآن ،",
		"pt": "Esteja você lutando pela liberdade agora,",
		"bn": "আপনি এখন স্বাধীনতার জন্য লড়াই করছেন কিনা,",
		"ru": "Боишься ли ты сейчас за свободу,",
		"ja": "あなたが今自由のために戦っているのかどうか、",
		"de": "Ob du jetzt für die Freiheit kämpfst,",
		"ms": "Sama ada anda memperjuangkan kebebasan sekarang,",
		"vi": "Cho dù bây giờ bạn đang đấu tranh cho tự do,",
		"fr": "Que vous combattiez pour la liberté maintenant,",
		"tr": "Şimdi özgürlük için savaşıyor olsanız da,",
		"it": "Sia che tu stia combattendo per la libertà ora,",
		"th": "ไม่ว่าตอนนี้คุณกำลังต่อสู้เพื่ออิสรภาพ",
		"fa": "خواه اکنون برای آزادی مبارزه می کنید ،",
		"pl": "Czy teraz walczysz o wolność,",
		"uk": "Чи ти зараз борешся за свободу,",
		"uz": "Siz hozir ozodlik uchun kurashayapsizmi,",
		"ro": "Fie că lupți pentru libertate acum,",
		"az": "İndi azadlıq uğrunda mübarizə aparırsan,",
		"hr": "Borite li se sada za slobodu,",
		"bg": "Независимо дали сега се борите за свобода,",
		"sr": "Било да се сада борите за слободу,",
		"hy": "Անկախ նրանից, թե դուք հիմա պայքարում եք ազատության համար,",
		"eu": "Askatasunaren alde borrokatzen ari zaren ala ez,",
		"be": "Ці змагаецеся вы цяпер за свабоду,",
		"ca": "Tant si estàs lluitant per la llibertat ara,",
		"cs": "Ať už teď bojujete za svobodu,",
		"da": "Uanset om du kæmper for frihed nu,",
		"nl": "Of je nu vecht voor vrijheid,",
		"et": "Ükskõik, kas võitlete praegu vabaduse eest,",
		"tl": "Kung nakikipaglaban ka para sa kalayaan ngayon,",
		"gl": "Se estás loitando pola liberdade agora,",
		"ka": "იბრძოლებ ახლა თავისუფლებისთვის,",
		"el": "Είτε παλεύετε για την ελευθερία τώρα,",
		"hu": "Akár most harcolsz a szabadságért,",
		"is": "Hvort sem þú ert að berjast fyrir frelsi núna,",
		"lv": "Neatkarīgi no tā, vai jūs tagad cīnāties par brīvību,",
		"lt": "Nesvarbu, ar dabar kovojate už laisvę,",
		"mk": "Без разлика дали се борите за слобода сега,",
		"no": "Enten du kjemper for frihet nå,",
		"sk": "Či už teraz bojujete za slobodu,",
		"sl": "Ali se zdaj borite za svobodo,",
		"sv": "Oavsett om du kämpar för frihet nu,",
		"fi": "Taisteletko nyt vapauden puolesta,",
		"af": "Of jy nou vir vryheid veg,",
		"sq": "Nëse luftoni për lirinë tani,",
		"am": "አሁን ለነፃነት ብትታገሉ ፣",
		"my": "မင်းအခုလွတ်လပ်ရေးအတွက်တိုက်ပွဲဝင်နေသလား၊",
		"gu": "ભલે તમે આઝાદી માટે લડી રહ્યા છો,",
		"he": "בין אם אתם נלחמים על החופש עכשיו,",
		"id": "Apakah Anda sedang berjuang untuk kebebasan sekarang,",
		"kn": "ನೀವು ಈಗ ಸ್ವಾತಂತ್ರ್ಯಕ್ಕಾಗಿ ಹೋರಾಡುತ್ತಿರಲಿ,",
		"kk": "Сіз қазір бостандық үшін күресіп жүрсіз бе,",
		"km": "មិនថាអ្នកកំពុងតស៊ូដើម្បីសេរីភាពឥឡូវនេះទេ",
		"ky": "Сиз азыр эркиндик үчүн күрөшүп жатсаңыз да,",
		"lo": "ບໍ່ວ່າເຈົ້າ ກຳ ລັງຕໍ່ສູ້ເພື່ອອິດສະລະພາບດຽວນີ້,",
		"ml": "നിങ്ങൾ ഇപ്പോൾ സ്വാതന്ത്ര്യത്തിനായി പോരാടുന്നുണ്ടോ,",
		"mr": "तुम्ही आता स्वातंत्र्यासाठी लढत आहात का,",
		"mn": "Та одоо эрх чөлөөний төлөө тэмцэж байгаа эсэх,",
		"ne": "चाहे तपाइँ अब स्वतन्त्रता को लागी लडिरहनु भएको छ,",
		"pa": "ਭਾਵੇਂ ਤੁਸੀਂ ਹੁਣ ਆਜ਼ਾਦੀ ਲਈ ਲੜ ਰਹੇ ਹੋ,",
		"si": "ඔබ දැන් නිදහස සඳහා සටන් කරනවාද,",
		"sw": "Ikiwa unapigania uhuru sasa,",
		"ta": "நீங்கள் இப்போது சுதந்திரத்திற்காக போராடுகிறீர்களா,",
		"te": "మీరు ఇప్పుడు స్వేచ్ఛ కోసం పోరాడుతున్నా,",
		"ur": "چاہے اب آپ آزادی کی جنگ لڑ رہے ہیں ،",
		"zu": "Noma ngabe ulwela inkululeko manje,",
	},
	"orYouThoughtItWasADivineRight": {
		"en": "Or you thought it was a divine right.",
		"es": "O pensaste que era un derecho divino.",
		"zh": "或者你认为这是一项神圣的权利。",
		"hi": "या आपने सोचा था कि यह एक दैवीय अधिकार था।",
		"ar": "أو كنت تعتقد أنه حق إلهي.",
		"pt": "Ou você pensou que era um direito divino.",
		"bn": "অথবা আপনি ভেবেছিলেন এটি একটি divineশ্বরিক অধিকার।",
		"ru": "Или вы думали, что это божественное право.",
		"ja": "またはあなたはそれが神の権利だと思った。",
		"de": "Oder Sie dachten, es sei ein göttliches Recht.",
		"ms": "Atau anda fikir ia adalah hak ilahi.",
		"vi": "Hoặc bạn nghĩ rằng đó là một quyền thiêng liêng.",
		"fr": "Ou vous pensiez que c'était un droit divin.",
		"tr": "Ya da bunun ilahi bir hak olduğunu düşündünüz.",
		"it": "O pensavi fosse un diritto divino.",
		"th": "หรือคุณคิดว่าเป็นสิทธิอันศักดิ์สิทธิ์",
		"fa": "یا فکر می کردید این یک حق الهی است.",
		"pl": "Albo myślałeś, że to boskie prawo.",
		"uk": "Або ви думали, що це божественне право.",
		"uz": "Yoki siz buni ilohiy haq deb o'yladingiz.",
		"ro": "Sau ai crezut că este un drept divin.",
		"az": "Yoxsa bunun ilahi bir haqq olduğunu düşünürdün.",
		"hr": "Ili ste mislili da je to božansko pravo.",
		"bg": "Или си мислите, че това е божествено право.",
		"sr": "Или сте мислили да је то божанско право.",
		"hy": "Կամ դուք կարծում էիք, որ դա աստվածային իրավունք է:",
		"eu": "Edo jainkozko eskubidea zela pentsatu zenuen.",
		"be": "Ці вы думалі, што гэта боскае права.",
		"ca": "O pensaves que era un dret diví.",
		"cs": "Nebo jste si mysleli, že je to božské právo.",
		"da": "Eller du troede, at det var en guddommelig ret.",
		"nl": "Of je dacht dat het een goddelijk recht was.",
		"et": "Või arvasite, et see on jumalik õigus.",
		"tl": "O akala mo ito ay isang banal na karapatan.",
		"gl": "Ou pensabas que era un dereito divino.",
		"ka": "ან თქვენ ფიქრობდით, რომ ეს იყო ღვთაებრივი უფლება.",
		"el": "Or νομίζατε ότι ήταν θεϊκό δικαίωμα.",
		"hu": "Vagy azt gondolta, hogy ez isteni jog.",
		"is": "Eða þú hélst að það væri guðlegur réttur.",
		"lv": "Vai arī jūs domājāt, ka tās ir dievišķas tiesības.",
		"lt": "Arba manėte, kad tai dieviška teisė.",
		"mk": "Или мислевте дека тоа е божествено право.",
		"no": "Eller du trodde det var en guddommelig rettighet.",
		"sk": "Alebo ste si mysleli, že je to božské právo.",
		"sl": "Ali pa ste mislili, da je to božanska pravica.",
		"sv": "Eller så trodde du att det var en gudomlig rättighet.",
		"fi": "Tai luulit, että se oli jumalallinen oikeus.",
		"af": "Of jy het gedink dit is 'n goddelike reg.",
		"sq": "Ose ju menduat se ishte një e drejtë hyjnore.",
		"am": "ወይም መለኮታዊ መብት ነው ብለው አስበው ነበር።",
		"my": "ဒါမှမဟုတ်ဒါဟာဘုရားသခင့်အခွင့်အရေးတစ်ခုလို့မင်းထင်ခဲ့သလား။",
		"gu": "અથવા તમે વિચાર્યું કે તે દૈવી અધિકાર છે.",
		"he": "או שחשבת שזו זכות אלוהית.",
		"id": "Atau Anda pikir itu adalah hak ilahi.",
		"kn": "ಅಥವಾ ಇದು ದೈವಿಕ ಹಕ್ಕು ಎಂದು ನೀವು ಭಾವಿಸಿದ್ದೀರಿ.",
		"kk": "Немесе сіз бұл құдайлық құқық деп ойладыңыз.",
		"km": "ឬអ្នកគិតថាវាជាសិទ្ធិដ៏ទេវភាព។",
		"ky": "Же сиз муну кудайдын укугу деп ойлодуңуз.",
		"lo": "ຫຼືເຈົ້າຄິດວ່າມັນເປັນສິດທິອັນສູງສົ່ງ.",
		"ml": "അല്ലെങ്കിൽ അത് ഒരു ദൈവിക അവകാശമാണെന്ന് നിങ്ങൾ കരുതി.",
		"mr": "किंवा तुम्हाला तो दैवी अधिकार वाटला.",
		"mn": "Эсвэл та үүнийг бурханлаг эрх гэж бодсон байх.",
		"ne": "वा तपाइँलाई लाग्छ कि यो एक ईश्वरीय अधिकार थियो।",
		"pa": "ਜਾਂ ਤੁਸੀਂ ਸੋਚਿਆ ਕਿ ਇਹ ਇੱਕ ਬ੍ਰਹਮ ਅਧਿਕਾਰ ਸੀ.",
		"si": "නැත්නම් එය දිව්‍යමය අයිතියක් යැයි ඔබ සිතුවා.",
		"sw": "Au ulidhani ni haki ya kimungu.",
		"ta": "அல்லது அது தெய்வீக உரிமை என்று நீங்கள் நினைத்தீர்கள்.",
		"te": "లేదా అది దైవిక హక్కు అని మీరు అనుకున్నారు.",
		"ur": "یا آپ نے اسے خدائی حق سمجھا۔",
		"zu": "Noma ubucabanga ukuthi kuyilungelo laphezulu.",
	},
	"itsABeaconOfLight": {
		"en": "It's a beacon of light,",
		"es": "Es un faro de luz",
		"zh": "是一盏明灯，",
		"hi": "यह प्रकाश की किरण है,",
		"ar": "إنها منارة الضوء ،",
		"pt": "É um farol de luz,",
		"bn": "এটা আলোর বাতিঘর,",
		"ru": "Это луч света,",
		"ja": "それは光の灯台です、",
		"de": "Es ist ein Leuchtfeuer des Lichts,",
		"ms": "Ini adalah cahaya lampu,",
		"vi": "Đó là một ngọn hải đăng ánh sáng,",
		"fr": "C'est un phare de lumière,",
		"tr": "Bu bir ışık feneri,",
		"it": "è un raggio di luce,",
		"th": "มันคือแสงประทีป",
		"fa": "این چراغ روشنایی است ،",
		"pl": "To latarnia światła,",
		"uk": "Це маяк світла,",
		"uz": "Bu nur chirog'i,",
		"ro": "Este un far de lumină,",
		"az": "İşıq çırağıdır,",
		"hr": "To je svjetionik,",
		"bg": "Това е фар за светлина,",
		"sr": "То је светионик светлости,",
		"hy": "Դա լույսի փարոս է,",
		"eu": "Argiaren faroa da,",
		"be": "Гэта маяк святла,",
		"ca": "És un far de llum,",
		"cs": "Je to maják světla,",
		"da": "Det er et fyrtårn af lys,",
		"nl": "Het is een baken van licht,",
		"et": "See on valguse majakas,",
		"tl": "Ito ay isang ilaw ng ilaw,",
		"gl": "É un faro de luz,",
		"ka": "ეს არის სინათლის შუქურა,",
		"el": "Είναι ένας φάρος φωτός,",
		"hu": "Ez egy fényjelző,",
		"is": "Það er ljósastaur,",
		"lv": "Tā ir gaismas bāka,",
		"lt": "Tai šviesos švyturys,",
		"mk": "Тоа е светилник на светлината,",
		"no": "Det er et fyrtårn av lys,",
		"sk": "Je to maják svetla,",
		"sl": "To je svetilnik,",
		"sv": "Det är en ledstjärna av ljus,",
		"fi": "Se on valonheitin,",
		"af": "Dit is 'n ligbaken,",
		"sq": "Shtë një fener drite,",
		"am": "እሱ የብርሃን መብራት ነው ፣",
		"my": "ဒါဟာမီးပြတိုက်",
		"gu": "તે પ્રકાશનો કિરણ છે,",
		"he": "זהו מגדלור של אור,",
		"id": "Ini adalah suar cahaya,",
		"kn": "ಇದು ಬೆಳಕಿನ ದಾರಿದೀಪ,",
		"kk": "Бұл жарық шамшырағы,",
		"km": "វាជាចង្កៀងបំភ្លឺ",
		"ky": "Бул жарык чырак,",
		"lo": "ມັນເປັນສັນຍານແຫ່ງຄວາມສະຫວ່າງ,",
		"ml": "അത് ഒരു പ്രകാശത്തിന്റെ വിളക്കാണ്,",
		"mr": "तो प्रकाशाचा प्रकाश आहे,",
		"mn": "Энэ бол гэрлэн дохио,",
		"ne": "यो उज्यालो को एक किरण हो,",
		"pa": "ਇਹ ਚਾਨਣ ਦਾ ਚਾਨਣ ਹੈ,",
		"si": "එය ආලෝක පහනකි,",
		"sw": "Ni taa ya nuru,",
		"ta": "இது ஒரு ஒளி விளக்கு,",
		"te": "ఇది ఒక కాంతి దీపం,",
		"ur": "یہ روشنی کی روشنی ہے ،",
		"zu": "Kuyisibani sokukhanya,",
	},
	"forUnderbankedPopulationAroundWorld": {
		"en": "For underbanked population around the world.",
		"es": "Para la población no bancarizada de todo el mundo.",
		"zh": "适用于世界各地银行存款不足的人群。",
		"hi": "दुनिया भर में कम बैंकिंग सुविधा वाली आबादी के लिए।",
		"ar": "للسكان الذين يعانون من نقص البنوك في جميع أنحاء العالم.",
		"pt": "Para população com recursos insuficientes em todo o mundo.",
		"bn": "বিশ্বব্যাপী আন্ডারব্যাঙ্ক করা জনসংখ্যার জন্য।",
		"ru": "Для малообеспеченного населения по всему миру.",
		"ja": "世界中の銀行不足の人口のために。",
		"de": "Für unterbankierte Bevölkerung auf der ganzen Welt.",
		"ms": "Untuk penduduk yang kurang bergaji di seluruh dunia.",
		"vi": "Đối với dân số ngân hàng thấp trên khắp thế giới.",
		"fr": "Pour la population sous-bancarisée du monde entier.",
		"tr": "Dünya çapında yetersiz banka nüfusu için.",
		"it": "Per la popolazione sottobanca in tutto il mondo.",
		"th": "สำหรับประชากรที่อยู่ภายใต้ธนาคารทั่วโลก",
		"fa": "برای جمعیت زیر بانک در سراسر جهان.",
		"pl": "Dla niedofinansowanej populacji na całym świecie.",
		"uk": "Для банківського населення у всьому світі.",
		"uz": "Dunyo bo'ylab kam ta'minlangan aholi uchun.",
		"ro": "Pentru populația subbancată din întreaga lume.",
		"az": "Dünyanın hər yerində bank olmayan insanlar üçün.",
		"hr": "Za siromašno stanovništvo širom svijeta.",
		"bg": "За населението с недостатъчен банков ресурс по целия свят.",
		"sr": "За недовољно банковно становништво широм света.",
		"hy": "Ամբողջ աշխարհի թերբնակեցված բնակչության համար:",
		"eu": "Mundu osoko banku gutxiko biztanleentzat.",
		"be": "Для насельніцтва з нізкім банкам па ўсім свеце.",
		"ca": "Per a la població poc bancària de tot el món.",
		"cs": "Pro podbankovou populaci po celém světě.",
		"da": "For underbanked befolkning rundt om i verden.",
		"nl": "Voor mensen met een lage bank over de hele wereld.",
		"et": "Alampankadega elanikkonnale kogu maailmas.",
		"tl": "Para sa populasyon ng underbanked sa buong mundo.",
		"gl": "Para poboación subbankada en todo o mundo.",
		"ka": "მთელს მსოფლიოში დაბალ ბანკის მოსახლეობისთვის.",
		"el": "Για υποτραπεζικούς πληθυσμούς σε όλο τον κόσμο.",
		"hu": "Az alulfinanszírozott lakosság számára szerte a világon.",
		"is": "Fyrir undirbanka íbúa um allan heim.",
		"lv": "Iedzīvotājiem ar zemu banku skaitu visā pasaulē.",
		"lt": "Mažai bankinių gyventojų visame pasaulyje.",
		"mk": "За население под банкарство ширум светот.",
		"no": "For underbanket befolkning rundt om i verden.",
		"sk": "Pre podbankové obyvateľstvo na celom svete.",
		"sl": "Za prebivalce po svetu, ki nimajo dovolj bank.",
		"sv": "För underbankad befolkning runt om i världen.",
		"fi": "Alipankkiväestölle ympäri maailmaa.",
		"af": "Vir onderbank -bevolking regoor die wêreld.",
		"sq": "Për popullsinë e nënbankuar në të gjithë botën.",
		"am": "በዓለም ዙሪያ ባንክ ለሌለው ሕዝብ።",
		"my": "ကမ္ဘာတစ်ဝှမ်းလုံးရှိလူ ဦး ရေနည်းသည်။",
		"gu": "વિશ્વભરમાં અન્ડરબેન્ક વસ્તી માટે.",
		"he": "לאוכלוסייה תת -גדושה ברחבי העולם.",
		"id": "Untuk populasi underbanked di seluruh dunia.",
		"kn": "ವಿಶ್ವದಾದ್ಯಂತ ಬ್ಯಾಂಕುಗಳಿಲ್ಲದ ಜನಸಂಖ್ಯೆಗಾಗಿ.",
		"kk": "Бүкіл әлем бойынша банктік емес тұрғындар үшін.",
		"km": "សម្រាប់ប្រជាជនដែលគ្មានធនាគារនៅជុំវិញពិភពលោក។",
		"ky": "Дүйнө жүзү боюнча банктардагы калк үчүн.",
		"lo": "ສໍາລັບປະຊາກອນ underbanked ທົ່ວໂລກ.",
		"ml": "ലോകമെമ്പാടുമുള്ള ബാങ്കിംഗ് ഇല്ലാത്ത ജനസംഖ്യയ്ക്ക്.",
		"mr": "जगभरातील अंडरबँकेड लोकसंख्येसाठी.",
		"mn": "Дэлхийн өнцөг булан бүрээс дутуу үнэлгээтэй хүн амын хувьд.",
		"ne": "संसार भर मा underbanked जनसंख्या को लागी।",
		"pa": "ਦੁਨੀਆ ਭਰ ਵਿੱਚ ਅੰਡਰਬੈਂਕਡ ਆਬਾਦੀ ਲਈ.",
		"si": "ලොව පුරා බැංකු නැති ජනගහනය සඳහා.",
		"sw": "Kwa idadi ya watu wasio na benki kote ulimwenguni.",
		"ta": "உலகெங்கிலும் உள்ள வங்கிகள் இல்லாத மக்களுக்கு.",
		"te": "ప్రపంచవ్యాప్తంగా అండర్ బ్యాంక్ జనాభా కోసం.",
		"ur": "دنیا بھر میں زیرزمین آبادی کے لیے۔",
		"zu": "Kubantu abangenamabhange emhlabeni jikelele.",
	},
	"didYouKnow": {
		"en": "Did you know?",
		"es": "¿Sabías?",
		"zh": "你知道吗？",
		"hi": "क्या तुम्हें पता था?",
		"ar": "هل كنت تعلم؟",
		"pt": "Você sabia?",
		"bn": "তুমি কি জানতে?",
		"ru": "Вы знали?",
		"ja": "知ってますか？",
		"de": "Wusstest du schon?",
		"ms": "Adakah kamu tahu?",
		"vi": "Bạn có biết không?",
		"fr": "Le saviez-vous?",
		"tr": "Biliyor musun?",
		"it": "Lo sapevate?",
		"th": "เธอรู้รึเปล่า?",
		"fa": "آیا میدانستید؟",
		"pl": "Czy wiedziałeś?",
		"uk": "Ти знав?",
		"uz": "Bilasizmi?",
		"ro": "Știați?",
		"az": "Bilirdinizmi?",
		"hr": "Dali si znao?",
		"bg": "Знаеше ли?",
		"sr": "Да ли си знао?",
		"hy": "Գիտեի՞ք:",
		"eu": "Badakizu?",
		"be": "Вы ведалі?",
		"ca": "Ho savies?",
		"cs": "Věděl jsi?",
		"da": "Vidste du?",
		"nl": "Wist u?",
		"et": "Kas sa teadsid?",
		"tl": "Alam mo ba?",
		"gl": "Sabías?",
		"ka": "Იცოდი?",
		"el": "Το ήξερες?",
		"hu": "Tudtad?",
		"is": "Vissir þú?",
		"lv": "Vai tu zināji?",
		"lt": "Ar tu žinai?",
		"mk": "Дали знаеше?",
		"no": "Visste du?",
		"sk": "Vedel si?",
		"sl": "Ali si vedel?",
		"sv": "Visste du?",
		"fi": "Tiesitkö?",
		"af": "Het jy geweet?",
		"sq": "E dinit?",
		"am": "ያውቁ ኖሯል?",
		"my": "မင်းသိလား?",
		"gu": "તમને ખબર છે?",
		"he": "האם ידעת?",
		"id": "Tahukah kamu?",
		"kn": "ನಿನಗೆ ಗೊತ್ತೆ?",
		"kk": "Сіз білдіңіз бе?",
		"km": "តើអ្នកដឹងទេ?",
		"ky": "Сиз билесизби?",
		"lo": "ເຈົ້າ​ຮູ້​ບໍ່?",
		"ml": "നിനക്കറിയുമോ?",
		"mr": "तुम्हाला माहिती आहे का?",
		"mn": "Та мэдсэн үү?",
		"ne": "तपाईंलाई थाँहा थियो?",
		"pa": "ਕੀ ਤੁਸੀ ਜਾਣਦੇ ਹੋ?",
		"si": "ඔයා දැනගෙන හිටියා ද?",
		"sw": "Ulijua?",
		"ta": "உனக்கு தெரியுமா?",
		"te": "నీకు తెలుసా?",
		"ur": "کیا آپ جانتے ہیں؟",
		"zu": "Uthi bewazi?",
	},
	"thereAre2BillionUnbankedPeople": {
		"en": "There are 2 billion unbanked people.",
		"es": "Hay 2 mil millones de personas no bancarizadas.",
		"zh": "有 20 亿没有银行账户的人。",
		"hi": "2 अरब लोग बिना बैंक वाले हैं।",
		"ar": "هناك 2 مليار شخص لا يتعاملون مع البنوك.",
		"pt": "Existem 2 bilhões de pessoas sem banco.",
		"bn": "2 বিলিয়ন ব্যাঙ্কহীন মানুষ আছে।",
		"ru": "Есть 2 миллиарда человек, не охваченных банковскими услугами.",
		"ja": "銀行口座を持たない人は20億人います。",
		"de": "Es gibt 2 Milliarden Menschen ohne Bankkonto.",
		"ms": "Terdapat 2 bilion orang tanpa bank.",
		"vi": "Có 2 tỷ người không có tiền gửi ngân hàng.",
		"fr": "Il y a 2 milliards de personnes non bancarisées.",
		"tr": "2 milyar bankasız insan var.",
		"it": "Ci sono 2 miliardi di persone senza banca.",
		"th": "มีคนที่ไม่มีบัญชีธนาคาร 2 พันล้านคน",
		"fa": "2 میلیارد نفر بدون بانک وجود دارد.",
		"pl": "Istnieją 2 miliardy ludzi bez bankowości.",
		"uk": "Є 2 мільярди людей без банків.",
		"uz": "2 milliardga yaqin odamlar bor.",
		"ro": "Există 2 miliarde de persoane nebancate.",
		"az": "Bankda olmayan 2 milyard insan var.",
		"hr": "Postoji 2 milijarde ljudi bez banaka.",
		"bg": "Има 2 милиарда хора без банки.",
		"sr": "Постоји 2 милијарде људи без банака.",
		"hy": "Կան 2 միլիարդ ոչ բանկային մարդիկ:",
		"eu": "Banku gabeko 2.000 mila milioi pertsona daude.",
		"be": "Ёсць 2 мільярды безбанкаўскіх людзей.",
		"ca": "Hi ha 2.000 milions de persones sense banc.",
		"cs": "Nebankovních lidí jsou 2 miliardy.",
		"da": "Der er 2 milliarder mennesker uden bank.",
		"nl": "Er zijn 2 miljard mensen zonder bankrekening.",
		"et": "Pangadeta on 2 miljardit inimest.",
		"tl": "Mayroong 2 bilyong mga hindi bangko.",
		"gl": "Hai 2.000 millóns de persoas sen bancos.",
		"ka": "არის 2 მილიარდი ბანკირებული ადამიანი.",
		"el": "Υπάρχουν 2 δισεκατομμύρια άτομα χωρίς τράπεζες.",
		"hu": "2 milliárd bank nélküli ember van.",
		"is": "Það eru 2 milljarðar bankalausir.",
		"lv": "Ir 2 miljardi cilvēku bez bankām.",
		"lt": "Yra 2 milijardai bankų neturinčių žmonių.",
		"mk": "Има 2 милијарди луѓе без банкарство.",
		"no": "Det er 2 milliarder mennesker uten bank.",
		"sk": "Bez banky sú 2 miliardy ľudí.",
		"sl": "Obstaja 2 milijardi ljudi brez bank.",
		"sv": "Det finns 2 miljarder obankade människor.",
		"fi": "Pankitonta henkilöä on 2 miljardia.",
		"af": "Daar is 2 miljard mense sonder bank.",
		"sq": "Ka 2 miliardë njerëz pa banka.",
		"am": "በባንክ ያልተያዙ 2 ቢሊዮን ሰዎች አሉ።",
		"my": "ဘဏ်မရှိသောလူ ၂ ဘီလီယံရှိသည်။",
		"gu": "2 અબજ બેન્ક વગરના લોકો છે.",
		"he": "ישנם 2 מיליארד אנשים ללא בנק.",
		"id": "Ada 2 miliar orang yang tidak memiliki rekening bank.",
		"kn": "2 ಬಿಲಿಯನ್ ಬ್ಯಾಂಕ್‌ಗಳಿಲ್ಲದ ಜನರಿದ್ದಾರೆ.",
		"kk": "Банкі жоқ 2 миллиард адам бар.",
		"km": "មានមនុស្ស ២ ពាន់លាននាក់ដែលមិនមានធនាគារ។",
		"ky": "Банкы жок 2 миллиард адам бар.",
		"lo": "ມີຄົນບໍ່ມີທະນາຄານ 2 ພັນລ້ານຄົນ.",
		"ml": "ബാങ്കില്ലാത്ത 2 ബില്യൺ ആളുകളുണ്ട്.",
		"mr": "तेथे 2 अब्ज बँक नसलेले लोक आहेत.",
		"mn": "Банкинд хамрагдаагүй 2 тэрбум хүн бий.",
		"ne": "त्यहाँ २ अर्ब बैंकिked्ग जनता छन्।",
		"pa": "ਇੱਥੇ 2 ਅਰਬ ਬੈਂਕ ਰਹਿਤ ਲੋਕ ਹਨ.",
		"si": "බැංකු නැති මිනිසුන් බිලියන 2 ක් සිටිති.",
		"sw": "Kuna watu bilioni 2 wasio na benki.",
		"ta": "வங்கியில்லாத 2 பில்லியன் மக்கள் உள்ளனர்.",
		"te": "2 బిలియన్ బ్యాంకులేని వ్యక్తులు ఉన్నారు.",
		"ur": "2 ارب غیر بینک لوگ ہیں۔",
		"zu": "Kunabantu abayizigidigidi ezi-2 abangenamabhange.",
	},
	"peopleFindItDifficultToMaintainTheirLife": {
		"en": "People find it difficult to maintain their life.",
		"es": "A las personas les resulta difícil mantener su vida.",
		"zh": "人们发现很难维持他们的生活。",
		"hi": "लोगों को अपने जीवन को बनाए रखना मुश्किल लगता है।",
		"ar": "يجد الناس صعوبة في الحفاظ على حياتهم.",
		"pt": "As pessoas têm dificuldade em manter suas vidas.",
		"bn": "মানুষ তাদের জীবন বজায় রাখা কঠিন মনে করে।",
		"ru": "Людям трудно поддерживать свою жизнь.",
		"ja": "人々は自分たちの生活を維持するのが難しいと感じています。",
		"de": "Den Menschen fällt es schwer, ihr Leben aufrechtzuerhalten.",
		"ms": "Orang merasa sukar untuk mengekalkan kehidupan mereka.",
		"vi": "Mọi người cảm thấy khó khăn để duy trì cuộc sống của họ.",
		"fr": "Les gens ont du mal à maintenir leur vie.",
		"tr": "İnsanlar hayatlarını sürdürmekte zorlanıyorlar.",
		"it": "Le persone trovano difficile mantenere la propria vita.",
		"th": "ผู้คนพบว่ามันยากที่จะรักษาชีวิตของพวกเขา",
		"fa": "مردم به سختی می توانند زندگی خود را حفظ کنند.",
		"pl": "Ludzie mają trudności z utrzymaniem życia.",
		"uk": "Людям важко підтримувати своє життя.",
		"uz": "Odamlarga o'z hayotini saqlab qolish qiyin.",
		"ro": "Oamenilor le este greu să-și mențină viața.",
		"az": "İnsanlar həyatlarını davam etdirməkdə çətinlik çəkirlər.",
		"hr": "Ljudi teško održavaju svoj život.",
		"bg": "Хората трудно поддържат живота си.",
		"sr": "Људи тешко одржавају свој живот.",
		"hy": "Մարդիկ դժվարանում են պահպանել իրենց կյանքը:",
		"eu": "Jendeari zaila zaio bizitza mantentzea.",
		"be": "Людзям цяжка падтрымліваць сваё жыццё.",
		"ca": "A la gent li costa mantenir la seva vida.",
		"cs": "Pro lidi je obtížné udržet si život.",
		"da": "Folk har svært ved at vedligeholde deres liv.",
		"nl": "Mensen vinden het moeilijk om in leven te blijven.",
		"et": "Inimestel on raske oma elu säilitada.",
		"tl": "Nahihirapan ang mga tao na mapanatili ang kanilang buhay.",
		"gl": "Á xente cústalle manter a súa vida.",
		"ka": "ადამიანებს უჭირთ სიცოცხლის შენარჩუნება.",
		"el": "Οι άνθρωποι δυσκολεύονται να διατηρήσουν τη ζωή τους.",
		"hu": "Az emberek nehezen tudják fenntartani életüket.",
		"is": "Fólk á erfitt með að viðhalda lífi sínu.",
		"lv": "Cilvēkiem ir grūti saglabāt savu dzīvi.",
		"lt": "Žmonėms sunku išlaikyti savo gyvenimą.",
		"mk": "На луѓето им е тешко да го одржат својот живот.",
		"no": "Folk synes det er vanskelig å opprettholde livet.",
		"sk": "Ľudia majú problém udržať si život.",
		"sl": "Ljudje težko vzdržujejo svoje življenje.",
		"sv": "Människor har svårt att behålla sitt liv.",
		"fi": "Ihmisten on vaikea ylläpitää elämäänsä.",
		"af": "Mense vind dit moeilik om hul lewe te handhaaf.",
		"sq": "Njerëzit e kanë të vështirë të mbajnë jetën e tyre.",
		"am": "ሰዎች ሕይወታቸውን ለመጠበቅ ይቸገራሉ።",
		"my": "လူတွေဟာသူတို့ရဲ့ဘ ၀ ကိုထိန်းသိမ်းဖို့ခက်ခဲတယ်။",
		"gu": "લોકોને પોતાનું જીવન જાળવવું મુશ્કેલ લાગે છે.",
		"he": "לאנשים קשה לשמור על חייהם.",
		"id": "Orang-orang merasa sulit untuk mempertahankan hidup mereka.",
		"kn": "ಜನರು ತಮ್ಮ ಜೀವನವನ್ನು ನಿರ್ವಹಿಸುವುದು ಕಷ್ಟಕರವಾಗಿದೆ.",
		"kk": "Адамдарға өз өмірін сақтап қалу қиынға соғады.",
		"km": "មនុស្សពិបាករក្សាជីវិតរបស់ពួកគេ។",
		"ky": "Адамдарга жашоосун сактап калуу кыйынга турат.",
		"lo": "ປະຊາຊົນເຫັນວ່າມັນຍາກທີ່ຈະຮັກສາຊີວິດຂອງເຂົາເຈົ້າ.",
		"ml": "ആളുകൾക്ക് അവരുടെ ജീവിതം നിലനിർത്താൻ ബുദ്ധിമുട്ടാണ്.",
		"mr": "लोकांना त्यांचे जीवन टिकवणे कठीण वाटते.",
		"mn": "Хүмүүс амьдралаа хадгалахад хэцүү байдаг.",
		"ne": "मानिसहरुलाई आफ्नो जिन्दगी जोगाउन गाह्रो लाग्छ।",
		"pa": "ਲੋਕਾਂ ਨੂੰ ਆਪਣੀ ਜ਼ਿੰਦਗੀ ਨੂੰ ਕਾਇਮ ਰੱਖਣਾ ਮੁਸ਼ਕਲ ਲੱਗਦਾ ਹੈ.",
		"si": "මිනිසුන්ට තම ජීවිතය පවත්වාගෙන යාමට අපහසු වේ.",
		"sw": "Watu wanapata shida kudumisha maisha yao.",
		"ta": "மக்கள் தங்கள் வாழ்க்கையை பராமரிப்பது கடினம்.",
		"te": "ప్రజలు తమ జీవితాన్ని కొనసాగించడం కష్టంగా ఉంది.",
		"ur": "لوگوں کو اپنی زندگی کو برقرار رکھنا مشکل لگتا ہے۔",
		"zu": "Abantu bakuthola kunzima ukugcina impilo yabo.",
	},
	"theyLackOfAccessToFinancialServices": {
		"en": "They lack of access to financial services,",
		"es": "Carecen de acceso a servicios financieros,",
		"zh": "他们无法获得金融服务，",
		"hi": "उनके पास वित्तीय सेवाओं तक पहुंच की कमी है,",
		"ar": "يفتقرون إلى الوصول إلى الخدمات المالية ،",
		"pt": "Eles não têm acesso a serviços financeiros,",
		"bn": "তাদের আর্থিক সেবার অ্যাক্সেসের অভাব,",
		"ru": "У них нет доступа к финансовым услугам,",
		"ja": "彼らは金融サービスへのアクセスを欠いています、",
		"de": "Sie haben keinen Zugang zu Finanzdienstleistungen,",
		"ms": "Mereka kekurangan akses ke perkhidmatan kewangan,",
		"vi": "Họ thiếu khả năng tiếp cận các dịch vụ tài chính,",
		"fr": "Ils n'ont pas accès aux services financiers,",
		"tr": "Finansal hizmetlere erişimden yoksundurlar,",
		"it": "Non hanno accesso ai servizi finanziari,",
		"th": "พวกเขาขาดการเข้าถึงบริการทางการเงิน",
		"fa": "عدم دسترسی به خدمات مالی ،",
		"pl": "Brak dostępu do usług finansowych,",
		"uk": "Вони не мають доступу до фінансових послуг,",
		"uz": "Ular moliyaviy xizmatlardan foydalana olmaydilar,",
		"ro": "Lipsesc accesul la serviciile financiare,",
		"az": "Maliyyə xidmətlərindən istifadə edə bilmirlər.",
		"hr": "Nedostaje im pristup financijskim uslugama,",
		"bg": "Те нямат достъп до финансови услуги,",
		"sr": "Недостаје им приступ финансијским услугама,",
		"hy": "Նրանք չունեն ֆինանսական ծառայությունների մատչելիություն,",
		"eu": "Finantza zerbitzuetarako sarbiderik ez dute,",
		"be": "Яны не маюць доступу да фінансавых паслуг,",
		"ca": "No tenen accés als serveis financers,",
		"cs": "Nemají přístup k finančním službám,",
		"da": "De mangler adgang til finansielle tjenester,",
		"nl": "Ze hebben geen toegang tot financiële diensten,",
		"et": "Neil puudub juurdepääs finantsteenustele,",
		"tl": "Kulang sila ng pag-access sa mga serbisyong pampinansyal,",
		"gl": "Non teñen acceso a servizos financeiros,",
		"ka": "მათ არ აქვთ წვდომა ფინანსურ მომსახურებაზე,",
		"el": "Δεν έχουν πρόσβαση σε χρηματοοικονομικές υπηρεσίες,",
		"hu": "Nem férnek hozzá a pénzügyi szolgáltatásokhoz,",
		"is": "Þeir skortir aðgang að fjármálaþjónustu,",
		"lv": "Viņiem trūkst piekļuves finanšu pakalpojumiem,",
		"lt": "Joms trūksta galimybių naudotis finansinėmis paslaugomis,",
		"mk": "Тие немаат пристап до финансиски услуги,",
		"no": "De mangler tilgang til finansielle tjenester,",
		"sk": "Nemajú prístup k finančným službám,",
		"sl": "Nimajo dostopa do finančnih storitev,",
		"sv": "De saknar tillgång till finansiella tjänster,",
		"fi": "Heillä ei ole pääsyä rahoituspalveluihin,",
		"af": "Hulle het nie toegang tot finansiële dienste nie,",
		"sq": "Ata nuk kanë qasje në shërbimet financiare,",
		"am": "እነሱ የገንዘብ አገልግሎቶች አቅርቦት እጥረት አለባቸው ፣",
		"my": "ငွေကြေး ၀ န်ဆောင်မှုများလက်လှမ်းမီမှုမရှိခြင်း၊",
		"gu": "તેમની પાસે નાણાકીય સેવાઓની પહોંચનો અભાવ છે,",
		"he": "חוסר גישה לשירותים פיננסיים,",
		"id": "Mereka kekurangan akses ke layanan keuangan,",
		"kn": "ಅವರಿಗೆ ಹಣಕಾಸಿನ ಸೇವೆಗಳಿಗೆ ಪ್ರವೇಶದ ಕೊರತೆಯಿದೆ,",
		"kk": "Олар қаржылық қызметтерге қол жеткізе алмайды,",
		"km": "ពួកគេខ្វះលទ្ធភាពទទួលបានសេវាកម្មហិរញ្ញវត្ថុ",
		"ky": "Алардын каржылык кызматтарга жеткиликтүүлүгү жок,",
		"lo": "ເຂົາເຈົ້າຂາດການເຂົ້າເຖິງການບໍລິການດ້ານການເງິນ,",
		"ml": "അവർക്ക് സാമ്പത്തിക സേവനങ്ങൾ ലഭ്യമല്ല,",
		"mr": "त्यांना आर्थिक सेवांमध्ये प्रवेश नसणे,",
		"mn": "Тэд санхүүгийн үйлчилгээ авах боломжгүй,",
		"ne": "उनीहरु वित्तीय सेवामा पहुँचको कमी,",
		"pa": "ਉਨ੍ਹਾਂ ਕੋਲ ਵਿੱਤੀ ਸੇਵਾਵਾਂ ਤੱਕ ਪਹੁੰਚ ਦੀ ਘਾਟ,",
		"si": "ඔවුන්ට මූල්‍ය සේවා සඳහා ප්‍රවේශය නොමැති වීම,",
		"sw": "Wanakosa upatikanaji wa huduma za kifedha,",
		"ta": "அவர்களுக்கு நிதிச் சேவைகள் கிடைக்கவில்லை",
		"te": "వారికి ఆర్థిక సేవలు అందుబాటులో లేవు,",
		"ur": "ان کے پاس مالی خدمات تک رسائی کا فقدان ہے ،",
		"zu": "Bantula ukufinyelela ezinsizakalweni zezezimali,",
	},
	"andAreMostVulnarableToFinancialCrisis": {
		"en": "And are most vulnerable to financial crisis.",
		"es": "Y son los más vulnerables a la crisis financiera.",
		"zh": "并且最容易受到金融危机的影响。",
		"hi": "और आर्थिक संकट की सबसे अधिक चपेट में हैं।",
		"ar": "وهم الأكثر عرضة للأزمة المالية.",
		"pt": "E são os mais vulneráveis a crises financeiras.",
		"bn": "এবং আর্থিক সংকটের জন্য সবচেয়ে ঝুঁকিপূর্ণ।",
		"ru": "И наиболее уязвимы перед финансовым кризисом.",
		"ja": "そして、金融危機に対して最も脆弱です。",
		"de": "Und sind am anfälligsten für Finanzkrisen.",
		"ms": "Dan paling terdedah kepada krisis kewangan.",
		"vi": "Và dễ bị khủng hoảng tài chính nhất.",
		"fr": "Et sont les plus vulnérables à la crise financière.",
		"tr": "Ve finansal krize karşı en savunmasız olanlar.",
		"it": "E sono più vulnerabili alla crisi finanziaria.",
		"th": "และมีความเสี่ยงต่อวิกฤตการณ์ทางการเงินมากที่สุด",
		"fa": "و بیشتر در معرض بحران مالی هستند.",
		"pl": "I są najbardziej podatne na kryzys finansowy.",
		"uk": "І найбільш вразливі перед фінансовою кризою.",
		"uz": "Va ular moliyaviy inqirozga ko'proq moyil.",
		"ro": "Și sunt cei mai vulnerabili la criza financiară.",
		"az": "Və ən çox maliyyə böhranına məruz qalırlar.",
		"hr": "I najugroženiji su financijskom krizom.",
		"bg": "И са най -уязвими към финансова криза.",
		"sr": "И најугроженији су од финансијске кризе.",
		"hy": "Եվ առավել խոցելի են ֆինանսական ճգնաժամի նկատմամբ:",
		"eu": "Eta ahulenak dira finantza krisiaren aurrean.",
		"be": "І найбольш уразлівыя перад фінансавым крызісам.",
		"ca": "I són els més vulnerables a la crisi financera.",
		"cs": "A jsou nejvíce ohroženi finanční krizí.",
		"da": "Og er mest sårbare over for finanskrise.",
		"nl": "En zijn het meest kwetsbaar voor een financiële crisis.",
		"et": "Ja nad on finantskriisi suhtes kõige haavatavamad.",
		"tl": "At pinaka-mahina laban sa krisis sa pananalapi.",
		"gl": "E son os máis vulnerables á crise financeira.",
		"ka": "და ისინი ყველაზე დაუცველები არიან ფინანსური კრიზისის მიმართ.",
		"el": "Και είναι πιο ευάλωτοι στην οικονομική κρίση.",
		"hu": "És a legveszélyeztetettebbek a pénzügyi válsággal szemben.",
		"is": "Og eru viðkvæmastir fyrir fjármálakreppu.",
		"lv": "Un ir visneaizsargātākie pret finanšu krīzi.",
		"lt": "Ir yra labiausiai pažeidžiami finansų krizės.",
		"mk": "И се најранливи на финансиска криза.",
		"no": "Og er mest utsatt for finanskrise.",
		"sk": "A sú najzraniteľnejšie voči finančnej kríze.",
		"sl": "In so najbolj ranljivi za finančno krizo.",
		"sv": "Och är mest utsatta för finanskris.",
		"fi": "Ja he ovat alttiimpia finanssikriisille.",
		"af": "En is die kwesbaarste vir finansiële krisis.",
		"sq": "Dhe janë më të prekshmit nga kriza financiare.",
		"am": "እና ለገንዘብ ቀውስ በጣም ተጋላጭ ናቸው።",
		"my": "ငွေကြေးအကျပ်အတည်းအတွက်အထိခိုက်လွယ်ဆုံးဖြစ်သည်။",
		"gu": "અને નાણાકીય કટોકટી માટે સૌથી સંવેદનશીલ હોય છે.",
		"he": "והם הפגיעים ביותר למשבר פיננסי.",
		"id": "Dan paling rentan terhadap krisis keuangan.",
		"kn": "ಮತ್ತು ಆರ್ಥಿಕ ಬಿಕ್ಕಟ್ಟಿಗೆ ಹೆಚ್ಚು ಗುರಿಯಾಗುತ್ತಾರೆ.",
		"kk": "Және олар қаржылық дағдарысқа ең осал.",
		"km": "ហើយងាយរងគ្រោះបំផុតចំពោះវិបត្តិហិរញ្ញវត្ថុ។",
		"ky": "Жана финансылык кризиске эң алсыз.",
		"lo": "ແລະມີຄວາມສ່ຽງທີ່ສຸດຕໍ່ກັບວິກິດການການເງິນ.",
		"ml": "സാമ്പത്തിക പ്രതിസന്ധിക്ക് ഏറ്റവും കൂടുതൽ ഇരയാകുന്നത്.",
		"mr": "आणि आर्थिक संकटासाठी सर्वात असुरक्षित आहेत.",
		"mn": "Мөн санхүүгийн хямралд хамгийн өртөмтгий байдаг.",
		"ne": "र आर्थिक संकट को लागी सबैभन्दा कमजोर छन्।",
		"pa": "ਅਤੇ ਵਿੱਤੀ ਸੰਕਟ ਲਈ ਸਭ ਤੋਂ ਕਮਜ਼ੋਰ ਹਨ.",
		"si": "තවද මූල්‍ය අර්බුදයට වඩාත් ගොදුරු විය හැකිය.",
		"sw": "Na ni hatari zaidi kwa shida ya kifedha.",
		"ta": "மேலும் நிதி நெருக்கடிக்கு மிகவும் பாதிக்கப்படக்கூடியவர்கள்.",
		"te": "మరియు ఆర్థిక సంక్షోభానికి ఎక్కువగా గురవుతారు.",
		"ur": "اور مالی بحران کا سب سے زیادہ شکار ہیں۔",
		"zu": "Futhi basengozini enkulu ezinkingeni zezezimali.",
	},
	"evenInMostDevelopedWorlds": {
		"en": "Even in most developed worlds,",
		"es": "Incluso en la mayoría de los mundos desarrollados,",
		"zh": "即使在大多数发达国家，",
		"hi": "अधिकांश विकसित देशों में भी,",
		"ar": "حتى في معظم العوالم المتقدمة ،",
		"pt": "Mesmo nos mundos mais desenvolvidos,",
		"bn": "এমনকি অধিকাংশ উন্নত বিশ্বেও,",
		"ru": "Даже в самых развитых мирах",
		"ja": "ほとんどの先進国でさえ、",
		"de": "Selbst in den meisten entwickelten Welten,",
		"ms": "Walaupun di kebanyakan dunia maju,",
		"vi": "Ngay cả trong hầu hết các thế giới phát triển,",
		"fr": "Même dans la plupart des mondes développés,",
		"tr": "En gelişmiş dünyalarda bile,",
		"it": "Anche nei mondi più sviluppati,",
		"th": "แม้แต่ในโลกที่พัฒนาแล้วส่วนใหญ่",
		"fa": "حتی در اکثر کشورهای توسعه یافته ،",
		"pl": "Nawet w najbardziej rozwiniętych światach",
		"uk": "Навіть у більшості розвинених світів,",
		"uz": "Hatto dunyoning ko'pgina rivojlangan mamlakatlarida",
		"ro": "Chiar și în cele mai dezvoltate lumi,",
		"az": "Ən inkişaf etmiş dünyalarda belə",
		"hr": "Čak i u većini razvijenih svjetova,",
		"bg": "Дори в повечето развити светове,",
		"sr": "Чак и у већини развијених светова,",
		"hy": "Նույնիսկ առավել զարգացած աշխարհներում,",
		"eu": "Mundu garatuenetan ere,",
		"be": "Нават у большасці развітых светаў,",
		"ca": "Fins i tot en els mons més desenvolupats,",
		"cs": "I ve většině vyspělých světů",
		"da": "Selv i de fleste udviklede verdener,",
		"nl": "Zelfs in de meeste ontwikkelde werelden,",
		"et": "Isegi enamikus arenenud maailmades,",
		"tl": "Kahit na sa pinaka maunlad na mundo,",
		"gl": "Mesmo na maioría dos mundos desenvolvidos,",
		"ka": "თუნდაც ყველაზე განვითარებულ სამყაროებში,",
		"el": "Ακόμα και στους πιο ανεπτυγμένους κόσμους,",
		"hu": "Még a legtöbb fejlett világban is",
		"is": "Jafnvel í flestum þróuðum heimum,",
		"lv": "Pat lielākajā daļā attīstīto valstu,",
		"lt": "Net daugumoje išsivysčiusių pasaulių,",
		"mk": "Дури и во најразвиените светови,",
		"no": "Selv i de fleste utviklede verdener,",
		"sk": "Aj vo väčšine vyspelých svetov",
		"sl": "Tudi v večini razvitih svetov,",
		"sv": "Även i de flesta utvecklade världar,",
		"fi": "Jopa useimmissa kehittyneissä maissa",
		"af": "Selfs in die meeste ontwikkelde wêrelde,",
		"sq": "Edhe në botët më të zhvilluara,",
		"am": "በብዙ ባደጉት ዓለማት ውስጥ እንኳን ፣",
		"my": "အတိုးတက်ဆုံးကမ္ဘာတွေမှာတောင်",
		"gu": "મોટાભાગના વિકસિત વિશ્વમાં પણ,",
		"he": "אפילו ברוב העולמות המפותחים,",
		"id": "Bahkan di sebagian besar negara maju,",
		"kn": "ಅತ್ಯಂತ ಅಭಿವೃದ್ಧಿ ಹೊಂದಿದ ಪ್ರಪಂಚಗಳಲ್ಲಿಯೂ ಸಹ,",
		"kk": "Тіпті дамыған елдердің көбінде",
		"km": "សូម្បីតែនៅក្នុងប្រទេសអភិវឌ្developedន៍ភាគច្រើនក៏ដោយ",
		"ky": "Көпчүлүк өнүккөн өлкөлөрдө да,",
		"lo": "ແມ້ແຕ່ຢູ່ໃນໂລກທີ່ພັດທະນາແລ້ວ,",
		"ml": "മിക്ക വികസിത രാജ്യങ്ങളിലും പോലും,",
		"mr": "अगदी विकसित जगात सुद्धा,",
		"mn": "Ихэнх хөгжингүй орнуудад ч гэсэн",
		"ne": "धेरै विकसित संसारहरुमा पनि,",
		"pa": "ਬਹੁਤੇ ਵਿਕਸਤ ਸੰਸਾਰਾਂ ਵਿੱਚ ਵੀ,",
		"si": "බොහෝ දියුණු රටවල පවා,",
		"sw": "Hata katika ulimwengu ulioendelea zaidi,",
		"ta": "மிகவும் வளர்ந்த நாடுகளில் கூட,",
		"te": "చాలా అభివృద్ధి చెందిన ప్రపంచాలలో కూడా,",
		"ur": "یہاں تک کہ زیادہ تر ترقی یافتہ دنیاوں میں ،",
		"zu": "Ngisho nasemazweni amaningi athuthukile,",
	},
	"thePowersTakeInnocentCitizensWealth": {
		"en": "The powers take innocent citizens' wealth,",
		"es": "Los poderes toman la riqueza de ciudadanos inocentes,",
		"zh": "权力夺走了无辜公民的财富，",
		"hi": "शक्तियाँ निर्दोष नागरिकों की दौलत लेती हैं,",
		"ar": "السلطات تأخذ ثروة المواطنين الأبرياء ،",
		"pt": "Os poderes levam a riqueza de cidadãos inocentes,",
		"bn": "ক্ষমতা নিরীহ নাগরিকদের সম্পদ কেড়ে নেয়,",
		"ru": "Власть забирает богатство невинных граждан,",
		"ja": "権力は無実の市民の富を奪います、",
		"de": "Die Mächte nehmen den Reichtum unschuldiger Bürger,",
		"ms": "Kuasa mengambil kekayaan rakyat yang tidak bersalah,",
		"vi": "Các quyền lực lấy của cải của công dân vô tội,",
		"fr": "Les pouvoirs prennent la richesse des citoyens innocents,",
		"tr": "Güçler masum vatandaşların servetini alıyor,",
		"it": "I poteri prendono la ricchezza di cittadini innocenti,",
		"th": "อำนาจยึดทรัพย์ของพลเมืองผู้บริสุทธิ์",
		"fa": "قدرتها ثروت شهروندان بیگناه را می گیرند ،",
		"pl": "Władza zabiera bogactwo niewinnych obywateli,",
		"uk": "Влада забирає багатства невинних громадян,",
		"uz": "Vakolatlar begunoh fuqarolarning boyligini oladi,",
		"ro": "Puterile iau bogăția cetățenilor nevinovați,",
		"az": "Güclər günahsız vətəndaşların sərvətini alır,",
		"hr": "Moći uzimaju bogatstvo nevinih građana,",
		"bg": "Силите вземат богатството на невинни граждани,",
		"sr": "Моћи узимају богатство невиних грађана,",
		"hy": "Իշխանությունները խլում են անմեղ քաղաքացիների հարստությունը,",
		"eu": "Botereek herritarren errugabeen aberastasuna hartzen dute,",
		"be": "Улады забіраюць багацце нявінных грамадзян,",
		"ca": "Els poders prenen riquesa de ciutadans innocents,",
		"cs": "Mocnosti berou bohatství nevinných občanů,",
		"da": "Magterne tager uskyldige borgeres rigdom,",
		"nl": "De machten nemen de rijkdom van onschuldige burgers,",
		"et": "Võimud võtavad süütute kodanike rikkuse,",
		"tl": "Kinukuha ng mga kapangyarihan ang yaman ng mga inosenteng mamamayan,",
		"gl": "Os poderes toman riqueza de cidadáns inocentes,",
		"ka": "ძალები იღებენ უდანაშაულო მოქალაქეების ქონებას,",
		"el": "Οι δυνάμεις παίρνουν τον πλούτο των αθώων πολιτών,",
		"hu": "A hatalmak elveszik az ártatlan polgárok vagyonát,",
		"is": "Völdin taka auð saklausra borgara,",
		"lv": "Varas atņem nevainīgu pilsoņu bagātību,",
		"lt": "Galios atima nekaltų piliečių turtus,",
		"mk": "Силите го земаат богатството на невините граѓани,",
		"no": "Maktene tar uskyldige innbyggeres rikdom,",
		"sk": "Mocnosti berú bohatstvo nevinných občanov,",
		"sl": "Pristojnosti vzamejo bogastvo nedolžnih državljanov,",
		"sv": "Makterna tar oskyldiga medborgares rikedom,",
		"fi": "Valta vie viattomien kansalaisten vaurauden,",
		"af": "Die magte neem die rykdom van onskuldige burgers in,",
		"sq": "Fuqitë marrin pasurinë e qytetarëve të pafajshëm,",
		"am": "ኃይሎቹ የንፁሃን ዜጎችን ሀብት ይወስዳሉ ፣",
		"my": "အင်အားကြီးနိုင်ငံတွေကအပြစ်မဲ့နိုင်ငံသားတွေရဲ့ဥစ္စာဓနကိုယူ၊",
		"gu": "સત્તા નિર્દોષ નાગરિકોની સંપત્તિ લે છે,",
		"he": "המעצמות לוקחות את עושרו של אזרחים חפים מפשע,",
		"id": "Kekuasaan mengambil kekayaan warga yang tidak bersalah,",
		"kn": "ಅಧಿಕಾರಗಳು ಮುಗ್ಧ ನಾಗರಿಕರ ಸಂಪತ್ತನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತವೆ,",
		"kk": "Билік кінәсіз азаматтардың байлығын тартып алады,",
		"km": "អំណាចយកទ្រព្យសម្បត្តិពលរដ្ឋស្លូតត្រង់",
		"ky": "Бийлик бейкүнөө жарандардын байлыгын тартып алат,",
		"lo": "ອຳ ນາດເອົາຊັບສິນຂອງພົນລະເມືອງທີ່ບໍລິສຸດ,",
		"ml": "അധികാരങ്ങൾ നിരപരാധികളായ പൗരന്മാരുടെ സമ്പത്ത് എടുക്കുന്നു,",
		"mr": "शक्ती निष्पाप नागरिकांची संपत्ती घेतात,",
		"mn": "Эрх мэдэл нь гэмгүй иргэдийн баялгийг авч,",
		"ne": "शक्तिहरूले निर्दोष नागरिकको सम्पत्ति लिन्छन्,",
		"pa": "ਸ਼ਕਤੀਆਂ ਨਿਰਦੋਸ਼ ਨਾਗਰਿਕਾਂ ਦੀ ਦੌਲਤ ਖੋਹ ਲੈਂਦੀਆਂ ਹਨ,",
		"si": "බලතල අහිංසක පුරවැසියන්ගේ ධනය උදුරා ගනී,",
		"sw": "Mamlaka huchukua utajiri wa raia wasio na hatia,",
		"ta": "அதிகாரங்கள் அப்பாவி குடிமக்களின் செல்வத்தை எடுத்துக்கொள்கின்றன,",
		"te": "అధికారాలు అమాయక పౌరుల సంపదను తీసుకుంటాయి,",
		"ur": "طاقتیں معصوم شہریوں کی دولت چھین لیتی ہیں ،",
		"zu": "Amandla athatha ingcebo yezakhamuzi ezingenacala,",
	},
	"andImposeRestrictions": {
		"en": "And impose restrictions,",
		"es": "E imponer restricciones,",
		"zh": "并施加限制，",
		"hi": "और प्रतिबंध लगाओ,",
		"ar": "وتفرض قيودًا ،",
		"pt": "E impor restrições,",
		"bn": "এবং বিধিনিষেধ আরোপ,",
		"ru": "И наложить ограничения,",
		"ja": "そして制限を課し、",
		"de": "Und Beschränkungen auferlegen,",
		"ms": "Dan kenakan sekatan,",
		"vi": "Và áp đặt các hạn chế,",
		"fr": "Et imposer des restrictions,",
		"tr": "Ve kısıtlamalar getir,",
		"it": "E imporre restrizioni,",
		"th": "และกำหนดข้อจำกัด",
		"fa": "و اعمال محدودیت ،",
		"pl": "I nakładaj ograniczenia,",
		"uk": "І ввести обмеження,",
		"uz": "Va cheklovlar qo'ying,",
		"ro": "Și impune restricții,",
		"az": "Və məhdudiyyətlər qoyun,",
		"hr": "I uvesti ograničenja,",
		"bg": "И налага ограничения,",
		"sr": "И увести ограничења,",
		"hy": "Եվ սահմանել սահմանափակումներ,",
		"eu": "Eta murrizketak ezarri,",
		"be": "І ўводзіць абмежаванні,",
		"ca": "I imposar restriccions,",
		"cs": "A zavést omezení,",
		"da": "Og indføre begrænsninger,",
		"nl": "En beperkingen opleggen,",
		"et": "Ja seada piiranguid,",
		"tl": "At magpataw ng mga paghihigpit,",
		"gl": "E impoñer restricións,",
		"ka": "და დააწესე შეზღუდვები,",
		"el": "Και να επιβάλει περιορισμούς,",
		"hu": "És korlátokat szabni,",
		"is": "Og setja takmarkanir,",
		"lv": "Un noteikt ierobežojumus,",
		"lt": "Ir nustatyti apribojimus,",
		"mk": "И наметнете ограничувања,",
		"no": "Og innføre begrensninger,",
		"sk": "A zaviesť obmedzenia,",
		"sl": "In uvesti omejitve,",
		"sv": "Och sätt restriktioner,",
		"fi": "Ja asettaa rajoituksia,",
		"af": "En stel beperkings op,",
		"sq": "Dhe vendosni kufizime,",
		"am": "እና ገደቦችን ያስቀምጡ ፣",
		"my": "ကန့်သတ်ချက်များပြဌာန်းခြင်း၊",
		"gu": "અને પ્રતિબંધો લાદવા,",
		"he": "ולהטיל הגבלות,",
		"id": "Dan memberlakukan batasan,",
		"kn": "ಮತ್ತು ನಿರ್ಬಂಧಗಳನ್ನು ವಿಧಿಸಿ,",
		"kk": "Және шектеулер енгізіңіз,",
		"km": "និងដាក់កម្រិត",
		"ky": "Жана чектөөлөрдү киргизип,",
		"lo": "ແລະວາງຂໍ້ ຈຳ ກັດ,",
		"ml": "കൂടാതെ നിയന്ത്രണങ്ങൾ ഏർപ്പെടുത്തുക,",
		"mr": "आणि निर्बंध लादणे,",
		"mn": "Мөн хязгаарлалт тавих,",
		"ne": "र प्रतिबन्ध लगाउनुहोस्,",
		"pa": "ਅਤੇ ਪਾਬੰਦੀਆਂ ਲਗਾਓ,",
		"si": "සහ සීමා පැනවීම,",
		"sw": "Na kuweka vikwazo,",
		"ta": "மற்றும் கட்டுப்பாடுகளை விதிக்கவும்,",
		"te": "మరియు ఆంక్షలు విధించండి,",
		"ur": "اور پابندیاں لگائیں ،",
		"zu": "Futhi ubeke imingcele,",
	},
	"onHowYouShouldSpendYourMoney": {
		"en": "On how you should spend your money,",
		"es": "Sobre cómo debe gastar su dinero,",
		"zh": "关于你应该如何花钱，",
		"hi": "आपको अपना पैसा कैसे खर्च करना चाहिए, इस पर",
		"ar": "حول كيفية إنفاق أموالك ،",
		"pt": "Sobre como você deve gastar seu dinheiro,",
		"bn": "কিভাবে আপনার টাকা খরচ করা উচিত,",
		"ru": "На то, как вы должны потратить свои деньги,",
		"ja": "あなたがあなたのお金をどのように使うべきかについて、",
		"de": "Wie Sie Ihr Geld ausgeben sollten,",
		"ms": "Mengenai bagaimana anda harus membelanjakan wang anda,",
		"vi": "Về cách bạn nên tiêu tiền của mình,",
		"fr": "Sur la façon dont vous devriez dépenser votre argent,",
		"tr": "Paranızı nasıl harcamanız gerektiği konusunda,",
		"it": "Su come dovresti spendere i tuoi soldi,",
		"th": "ว่าคุณควรใช้จ่ายเงินอย่างไร",
		"fa": "در مورد نحوه خرج کردن پول خود ،",
		"pl": "na to, jak wydać pieniądze,",
		"uk": "Про те, як ви повинні витрачати свої гроші,",
		"uz": "Pulni qanday sarflash kerakligi haqida,",
		"ro": "Cu privire la modul în care ar trebui să cheltuiți banii,",
		"az": "Pulunuzu necə xərcləməli olduğunuza,",
		"hr": "O tome kako trebate trošiti svoj novac,",
		"bg": "За това как трябва да харчите парите си,",
		"sr": "О томе како треба да трошите свој новац,",
		"hy": "Ինչպես պետք է ծախսեք ձեր գումարը,",
		"eu": "Zure dirua nola gastatu behar duzun jakiteko,",
		"be": "Пра тое, як вы павінны марнаваць свае грошы,",
		"ca": "Per gastar els vostres diners,",
		"cs": "O tom, jak byste měli utratit své peníze,",
		"da": "Om hvordan du skal bruge dine penge,",
		"nl": "Over hoe u uw geld moet besteden,",
		"et": "Kuidas peaksite oma raha kulutama,",
		"tl": "Sa kung paano mo dapat gugulin ang iyong pera,",
		"gl": "En como debes gastar o teu diñeiro,",
		"ka": "იმაზე, თუ როგორ უნდა დახარჯო შენი ფული,",
		"el": "Για το πώς πρέπει να ξοδέψετε τα χρήματά σας,",
		"hu": "Arra, hogyan kell költeni a pénzét,",
		"is": "Um hvernig þú ættir að eyða peningunum þínum,",
		"lv": "Par to, kā tērēt naudu,",
		"lt": "Apie tai, kaip turėtumėte išleisti savo pinigus,",
		"mk": "За тоа како треба да ги трошите парите,",
		"no": "På hvordan du skal bruke pengene dine,",
		"sk": "Ako by ste mali minúť svoje peniaze,",
		"sl": "O tem, kako bi morali porabiti svoj denar,",
		"sv": "På hur du ska spendera dina pengar,",
		"fi": "Miten sinun pitäisi käyttää rahasi,",
		"af": "Oor hoe u u geld moet bestee,",
		"sq": "Se si duhet të shpenzoni paratë tuaja,",
		"am": "ገንዘብዎን እንዴት ማውጣት እንዳለብዎት ፣",
		"my": "မင်းငွေကိုဘယ်လိုသုံးသင့်လဲ၊",
		"gu": "તમારે તમારા પૈસા કેવી રીતે ખર્ચવા જોઈએ,",
		"he": "על איך אתה צריך להוציא את הכסף שלך,",
		"id": "Tentang bagaimana Anda harus membelanjakan uang Anda,",
		"kn": "ನಿಮ್ಮ ಹಣವನ್ನು ನೀವು ಹೇಗೆ ಖರ್ಚು ಮಾಡಬೇಕು,",
		"kk": "Ақшаны қалай жұмсау керектігін,",
		"km": "តើអ្នកគួរចាយលុយយ៉ាងដូចម្តេច?",
		"ky": "Акчаңызды кантип жумшашыңыз керек,",
		"lo": "ເຈົ້າຄວນໃຊ້ເງິນຂອງເຈົ້າແນວໃດ,",
		"ml": "നിങ്ങളുടെ പണം എങ്ങനെ ചെലവഴിക്കണം എന്നതിനെക്കുറിച്ച്,",
		"mr": "तुम्ही तुमचे पैसे कसे खर्च करावेत,",
		"mn": "Мөнгөө хэрхэн зарцуулах талаар",
		"ne": "तपाइँ कसरी तपाइँको पैसा खर्च गर्नु पर्छ,",
		"pa": "ਤੁਹਾਨੂੰ ਆਪਣਾ ਪੈਸਾ ਕਿਵੇਂ ਖਰਚ ਕਰਨਾ ਚਾਹੀਦਾ ਹੈ,",
		"si": "ඔබ ඔබේ මුදල් වියදම් කළ යුතු ආකාරය ගැන,",
		"sw": "Juu ya jinsi unapaswa kutumia pesa zako,",
		"ta": "உங்கள் பணத்தை எப்படி செலவழிக்க வேண்டும்,",
		"te": "మీరు మీ డబ్బును ఎలా ఖర్చు చేయాలి,",
		"ur": "آپ کو اپنا پیسہ کیسے خرچ کرنا چاہیے ،",
		"zu": "Ungayisebenzisa kanjani imali yakho,",
	},
	"andHowYourWealthShouldBeMeasured": {
		"en": "And how your wealth should be measured.",
		"es": "Y cómo debe medirse su riqueza.",
		"zh": "以及如何衡量您的财富。",
		"hi": "और आपके धन को कैसे मापा जाना चाहिए।",
		"ar": "وكيف ينبغي قياس ثروتك.",
		"pt": "E como sua riqueza deve ser medida.",
		"bn": "এবং কিভাবে আপনার সম্পদ পরিমাপ করা উচিত।",
		"ru": "И как следует измерять ваше богатство.",
		"ja": "そして、あなたの富をどのように測定すべきか。",
		"de": "Und wie Ihr Vermögen gemessen werden sollte.",
		"ms": "Dan bagaimana kekayaan anda harus diukur.",
		"vi": "Và sự giàu có của bạn nên được đo lường như thế nào.",
		"fr": "Et comment votre richesse doit être mesurée.",
		"tr": "Ve servetinizin nasıl ölçülmesi gerektiğini.",
		"it": "E come dovrebbe essere misurata la tua ricchezza.",
		"th": "และควรวัดความมั่งคั่งของคุณอย่างไร",
		"fa": "و اینکه چگونه ثروت شما باید اندازه گیری شود.",
		"pl": "I jak należy mierzyć twoje bogactwo.",
		"uk": "І як слід оцінювати ваше багатство.",
		"uz": "Va sizning boyligingiz qanday o'lchanishi kerak.",
		"ro": "Și cum trebuie măsurată averea ta.",
		"az": "Və sərvətiniz necə ölçülməlidir.",
		"hr": "I kako treba mjeriti vaše bogatstvo.",
		"bg": "И как трябва да се измерва богатството ви.",
		"sr": "И како треба мерити ваше богатство.",
		"hy": "Եվ ինչպես պետք է չափվի ձեր հարստությունը:",
		"eu": "Eta nola neurtu behar da zure aberastasuna.",
		"be": "І як трэба вымяраць сваё багацце.",
		"ca": "I com s’ha de mesurar la vostra riquesa.",
		"cs": "A jak by se mělo měřit vaše bohatství.",
		"da": "Og hvordan din rigdom skal måles.",
		"nl": "En hoe uw vermogen moet worden gemeten.",
		"et": "Ja kuidas teie rikkust mõõta.",
		"tl": "At kung paano dapat masukat ang iyong kayamanan.",
		"gl": "E como se debería medir a túa riqueza.",
		"ka": "და როგორ უნდა შეფასდეს თქვენი სიმდიდრე.",
		"el": "Και πώς πρέπει να μετρηθεί ο πλούτος σας.",
		"hu": "És hogyan kell mérni a vagyonát.",
		"is": "Og hvernig ætti að mæla auð þinn.",
		"lv": "Un kā jānovērtē jūsu bagātība.",
		"lt": "Ir kaip turėtų būti matuojamas tavo turtas.",
		"mk": "И како треба да се измери вашето богатство.",
		"no": "Og hvordan din formue skal måles.",
		"sk": "A ako by sa malo merať vaše bohatstvo.",
		"sl": "In kako je treba meriti vaše bogastvo.",
		"sv": "Och hur din förmögenhet ska mätas.",
		"fi": "Ja miten omaisuutesi pitäisi mitata.",
		"af": "En hoe u rykdom gemeet moet word.",
		"sq": "Dhe si duhet matur pasuria juaj.",
		"am": "እና ሀብትዎ እንዴት እንደሚለካ።",
		"my": "ပြီးတော့မင်းရဲ့ချမ်းသာမှုကိုဘယ်လိုတိုင်းတာသင့်လဲ။",
		"gu": "અને તમારી સંપત્તિ કેવી રીતે માપવી જોઈએ.",
		"he": "ואיך צריך למדוד את העושר שלך.",
		"id": "Dan bagaimana kekayaan Anda harus diukur.",
		"kn": "ಮತ್ತು ನಿಮ್ಮ ಸಂಪತ್ತನ್ನು ಹೇಗೆ ಅಳೆಯಬೇಕು.",
		"kk": "Ал сіздің байлықты қалай өлшеу керек.",
		"km": "ហើយរបៀបដែលទ្រព្យសម្បត្តិរបស់អ្នកគួរតែត្រូវបានវាស់។",
		"ky": "Жана сиздин байлыгыңыз кандай ченелиши керек.",
		"lo": "ແລະຄວາມຮັ່ງມີຂອງເຈົ້າຄວນຖືກວັດແທກແນວໃດ.",
		"ml": "നിങ്ങളുടെ സമ്പത്ത് എങ്ങനെ അളക്കണം.",
		"mr": "आणि आपली संपत्ती कशी मोजली पाहिजे.",
		"mn": "Мөн таны баялгийг хэрхэн хэмжих ёстой.",
		"ne": "र कसरी तपाइँको धन मापन गर्नुपर्छ।",
		"pa": "ਅਤੇ ਤੁਹਾਡੀ ਦੌਲਤ ਨੂੰ ਕਿਵੇਂ ਮਾਪਿਆ ਜਾਣਾ ਚਾਹੀਦਾ ਹੈ.",
		"si": "තවද ඔබේ ධනය මැනිය යුතු ආකාරය.",
		"sw": "Na jinsi utajiri wako unapaswa kupimwa.",
		"ta": "உங்கள் செல்வத்தை எப்படி அளவிட வேண்டும்.",
		"te": "మరియు మీ సంపదను ఎలా కొలవాలి.",
		"ur": "اور آپ کی دولت کی پیمائش کیسے کی جائے۔",
		"zu": "Nokuthi ingcebo yakho kufanele ilinganiswe kanjani.",
	},
	"itDoesntHaveToBeThatWay": {
		"en": "It doesn't have to be that way.",
		"es": "No tiene por qué ser así.",
		"zh": "不必如此。",
		"hi": "इसे उस तरह से नहीं किया जाना है।",
		"ar": "لا يجب أن يكون الأمر على هذا النحو.",
		"pt": "Não tem que ser assim.",
		"bn": "এটা যে ভাবে হতে হবে না।",
		"ru": "Так не должно быть.",
		"ja": "そのようにする必要はありません。",
		"de": "Es muss nicht so sein.",
		"ms": "Tidak semestinya begitu.",
		"vi": "Nó không cần phải như vậy.",
		"fr": "Il ne doit pas en être ainsi.",
		"tr": "Böyle olmak zorunda değil.",
		"it": "Non deve essere così.",
		"th": "มันต้องไม่ใช่แบบนั้น",
		"fa": "لازم نیست اینطور باشد.",
		"pl": "Nie musi tak być.",
		"uk": "Це не повинно бути саме так.",
		"uz": "Bu shunday bo'lishi shart emas.",
		"ro": "Nu trebuie să fie așa.",
		"az": "Belə olmaq lazım deyil.",
		"hr": "Ne mora biti tako.",
		"bg": "Не е задължително да е така.",
		"sr": "Не мора бити тако.",
		"hy": "Պարտադիր չէ, որ այդպես լինի:",
		"eu": "Ez du zertan horrela izan.",
		"be": "Гэта не павінна быць так.",
		"ca": "No ha de ser així.",
		"cs": "Nemusí to tak být.",
		"da": "Det behøver ikke at være sådan.",
		"nl": "Het hoeft niet zo te zijn.",
		"et": "See ei pea nii olema.",
		"tl": "Hindi dapat ganun.",
		"gl": "Non ten por que ser así.",
		"ka": "ეს არ უნდა იყოს ასე.",
		"el": "Δεν χρειάζεται να είναι έτσι.",
		"hu": "Ennek nem kell így lennie.",
		"is": "Það þarf ekki að vera þannig.",
		"lv": "Tam nav jābūt tādam.",
		"lt": "Tai neturi būti taip.",
		"mk": "Не мора да е така.",
		"no": "Det trenger ikke å være sånn.",
		"sk": "Nemusí to tak byť.",
		"sl": "Ni nujno, da je tako.",
		"sv": "Det behöver inte vara så.",
		"fi": "Sen ei tarvitse olla näin.",
		"af": "Dit hoef nie so te wees nie.",
		"sq": "Nuk ka pse të jetë kështu.",
		"am": "እንደዚያ መሆን የለበትም።",
		"my": "ဒါဟာအဲဒီလိုဖြစ်ဖို့မလိုပါဘူး။",
		"gu": "તે તે રીતે હોવું જરૂરી નથી.",
		"he": "זה לא חייב להיות ככה.",
		"id": "Tidak harus seperti itu.",
		"kn": "ಅದು ಹಾಗೆ ಇರಬೇಕಾಗಿಲ್ಲ.",
		"kk": "Бұлай болуы міндетті емес.",
		"km": "វាមិនចាំបាច់ជាវិធីនោះទេ។",
		"ky": "Мындай болушу керек эмес.",
		"lo": "ມັນບໍ່ ຈຳ ເປັນຕ້ອງເປັນແບບນັ້ນ.",
		"ml": "അത് അങ്ങനെ ആയിരിക്കണമെന്നില്ല.",
		"mr": "ते तसे असणे आवश्यक नाही.",
		"mn": "Ийм байх албагүй.",
		"ne": "यो त्यस्तो तरीका हुनु पर्दैन।",
		"pa": "ਇਸ ਤਰ੍ਹਾਂ ਹੋਣਾ ਜ਼ਰੂਰੀ ਨਹੀਂ ਹੈ.",
		"si": "එය එසේ විය යුතු නැත.",
		"sw": "Sio lazima iwe hivyo.",
		"ta": "அது அப்படி இருக்க வேண்டியதில்லை.",
		"te": "అది అలా ఉండవలసిన అవసరం లేదు.",
		"ur": "اس طرح ہونا ضروری نہیں ہے۔",
		"zu": "Akudingeki kube njalo.",
	},
	"withCryptocurrency": {
		"en": "With cryptocurrency,",
		"es": "Con criptomoneda,",
		"zh": "有了加密货币，",
		"hi": "क्रिप्टोक्यूरेंसी के साथ,",
		"ar": "مع cryptocurrency ،",
		"pt": "Com criptomoeda,",
		"bn": "ক্রিপ্টোকারেন্সির সাথে,",
		"ru": "С криптовалютой,",
		"ja": "暗号通貨で、",
		"de": "Mit Kryptowährung,",
		"ms": "Dengan cryptocurrency,",
		"vi": "Với tiền điện tử,",
		"fr": "Avec la crypto-monnaie,",
		"tr": "Kripto para birimi ile,",
		"it": "Con le criptovalute,",
		"th": "ด้วยสกุลเงินดิจิทัล",
		"fa": "با ارز رمزنگاری شده ،",
		"pl": "Z kryptowalutą,",
		"uk": "З криптовалютою,",
		"uz": "Kripto valyutasi bilan,",
		"ro": "Cu criptomonede,",
		"az": "Kriptovalyuta ilə,",
		"hr": "Uz kriptovalutu,",
		"bg": "С криптовалута,",
		"sr": "Са криптовалутом,",
		"hy": "Ծպտյալ արժույթով,",
		"eu": "Kriptokonferentziarekin,",
		"be": "З крыптавалютай,",
		"ca": "Amb criptomoneda,",
		"cs": "S kryptoměnou,",
		"da": "Med kryptokurrency,",
		"nl": "Met cryptovaluta,",
		"et": "Krüptovaluutaga,",
		"tl": "Sa cryptocurrency,",
		"gl": "Coa moeda criptográfica,",
		"ka": "კრიპტოვალუტით,",
		"el": "Με κρυπτονόμισμα,",
		"hu": "A kriptovalutával,",
		"is": "Með cryptocurrency,",
		"lv": "Ar kriptovalūtu,",
		"lt": "Su kriptovaliuta,",
		"mk": "Со крипто валута,",
		"no": "Med kryptovaluta,",
		"sk": "S kryptomenou,",
		"sl": "S kriptovaluto,",
		"sv": "Med kryptovaluta,",
		"fi": "Kryptovaluutan kanssa,",
		"af": "Met kriptokurrency,",
		"sq": "Me kriptomonedhë,",
		"am": "ከ cryptocurrency ጋር ፣",
		"my": "cryptocurrency နှင့်၊",
		"gu": "ક્રિપ્ટોકરન્સી સાથે,",
		"he": "בעזרת cryptocurrency,",
		"id": "Dengan mata uang kripto,",
		"kn": "ಕ್ರಿಪ್ಟೋ ಕರೆನ್ಸಿಯೊಂದಿಗೆ,",
		"kk": "Криптовалюта арқылы,",
		"km": "ជាមួយ cryptocurrency,",
		"ky": "Cryptocurrency менен,",
		"lo": "ດ້ວຍ cryptocurrency,",
		"ml": "ക്രിപ്‌റ്റോകറൻസി ഉപയോഗിച്ച്,",
		"mr": "क्रिप्टोकरन्सीसह,",
		"mn": "Криптовалютын тусламжтайгаар",
		"ne": "Cryptocurrency संग,",
		"pa": "ਕ੍ਰਿਪਟੋਕੁਰੰਸੀ ਦੇ ਨਾਲ,",
		"si": "ගුප්ත ව්යවහාර මුදල් සමඟ,",
		"sw": "Na pesa ya sarafu,",
		"ta": "கிரிப்டோகரன்சியுடன்,",
		"te": "క్రిప్టోకరెన్సీతో,",
		"ur": "cryptocurrency کے ساتھ ،",
		"zu": "Nge-cryptocurrency,",
	},
	"ourUnalienableRightsAreSecured": {
		"en": "Our unalienable rights are secured.",
		"es": "Nuestros derechos inalienables están asegurados.",
		"zh": "我们不可剥夺的权利得到保障。",
		"hi": "हमारे अक्षम्य अधिकार सुरक्षित हैं।",
		"ar": "حقوقنا غير القابلة للتصرف مضمونة.",
		"pt": "Nossos direitos inalienáveis estão garantidos.",
		"bn": "আমাদের অযোগ্য অধিকার সুরক্ষিত।",
		"ru": "Наши неотъемлемые права защищены.",
		"ja": "私たちの不可侵の権利は保護されています。",
		"de": "Unsere unveräußerlichen Rechte sind gesichert.",
		"ms": "Hak kami yang tidak dapat dilindungi dijamin.",
		"vi": "Quyền bất khả xâm phạm của chúng tôi được bảo đảm.",
		"fr": "Nos droits inaliénables sont garantis.",
		"tr": "Devredilemez haklarımız güvence altına alınmıştır.",
		"it": "I nostri diritti inalienabili sono garantiti.",
		"th": "สิทธิที่ไม่สามารถโอนได้ของเรามีความปลอดภัย",
		"fa": "حقوق غیرقابل انکار ما تضمین شده است.",
		"pl": "Nasze niezbywalne prawa są zabezpieczone.",
		"uk": "Наші невід'ємні права забезпечені.",
		"uz": "Bizning ajralmas huquqlarimiz ta'minlangan.",
		"ro": "Drepturile noastre inalienabile sunt garantate.",
		"az": "Ayrılmaz hüquqlarımız təmin edilir.",
		"hr": "Naša neotuđiva prava su osigurana.",
		"bg": "Нашите неотменими права са осигурени.",
		"sr": "Наша неотуђива права су осигурана.",
		"hy": "Մեր անօտարելի իրավունքներն ապահովված են:",
		"eu": "Gure eskubide ukaezinak bermatuta daude.",
		"be": "Нашы неад'емныя правы забяспечаны.",
		"ca": "Els nostres drets inalienables estan garantits.",
		"cs": "Naše nezcizitelná práva jsou zajištěna.",
		"da": "Vores umistelige rettigheder er sikret.",
		"nl": "Onze onvervreemdbare rechten zijn verzekerd.",
		"et": "Meie võõrandamatud õigused on tagatud.",
		"tl": "Ang aming hindi nababatid na mga karapatan ay nasisiguro.",
		"gl": "Os nosos dereitos inalienables están garantidos.",
		"ka": "ჩვენი უცვლელი უფლებები დაცულია.",
		"el": "Τα αναφαίρετα δικαιώματά μας είναι κατοχυρωμένα.",
		"hu": "Elidegeníthetetlen jogaink biztosítva vannak.",
		"is": "Ófrávíkjanleg réttindi okkar eru tryggð.",
		"lv": "Mūsu neatņemamās tiesības ir nodrošinātas.",
		"lt": "Mūsų neatimamos teisės yra apsaugotos.",
		"mk": "Нашите неприкосновени права се обезбедени.",
		"no": "Våre umistelige rettigheter er sikret.",
		"sk": "Naše neodňateľné práva sú zaistené.",
		"sl": "Naše neodtujljive pravice so zavarovane.",
		"sv": "Våra omistliga rättigheter är säkrade.",
		"fi": "Luovuttamattomat oikeuksemme on turvattu.",
		"af": "Ons onvervreembare regte word verseker.",
		"sq": "Të drejtat tona të patjetërsueshme janë të siguruara.",
		"am": "የማይለወጡ መብቶቻችን ተጠብቀዋል።",
		"my": "ကျွန်ုပ်တို့၏မထိန်းချုပ်နိုင်သောအခွင့်အရေးများကိုလုံခြုံအောင်ပြုလုပ်ထားပါသည်။",
		"gu": "અમારા અજાણ્યા અધિકારો સુરક્ષિત છે.",
		"he": "זכויותינו הבלתי ניתנות לערעור מובטחות.",
		"id": "Hak kami yang tidak dapat dicabut dijamin.",
		"kn": "ನಮ್ಮ ಅಗಲಿಸಲಾಗದ ಹಕ್ಕುಗಳು ಸುರಕ್ಷಿತವಾಗಿವೆ.",
		"kk": "Біздің ажырамас құқықтарымыз қамтамасыз етілген.",
		"km": "សិទ្ធិដែលមិនអាចកាត់ថ្លៃបានរបស់យើងត្រូវបានធានា។",
		"ky": "Биздин ажыратылгыс укуктарыбыз корголгон.",
		"lo": "ສິດທິທີ່ບໍ່ສາມາດຕັດສິນໄດ້ຂອງພວກເຮົາແມ່ນໄດ້ຮັບການຮັບປະກັນ.",
		"ml": "ഞങ്ങളുടെ അസാധ്യമായ അവകാശങ്ങൾ സുരക്ഷിതമാണ്.",
		"mr": "आमचे अविभाज्य अधिकार सुरक्षित आहेत.",
		"mn": "Бидний салшгүй эрхийг баталгаажуулсан.",
		"ne": "हाम्रा अमान्य अधिकारहरु सुरक्षित छन्।",
		"pa": "ਸਾਡੇ ਅਟੁੱਟ ਅਧਿਕਾਰ ਸੁਰੱਖਿਅਤ ਹਨ.",
		"si": "අපේ වෙන් කළ නොහැකි අයිතිවාසිකම් සුරක්‍ෂිතයි.",
		"sw": "Haki zetu zinazoweza kupatikana zinalindwa.",
		"ta": "எங்களது பிரிக்க முடியாத உரிமைகள் பாதுகாக்கப்படுகின்றன.",
		"te": "మా తిరుగులేని హక్కులు భద్రపరచబడ్డాయి.",
		"ur": "ہمارے ناجائز حقوق محفوظ ہیں۔",
		"zu": "Amalungelo ethu atholakala avikelekile.",
	},
	"life": {
		"en": "Life,",
		"es": "La vida,",
		"zh": "生活，",
		"hi": "जिंदगी,",
		"ar": "حياة،",
		"pt": "Vida,",
		"bn": "জীবন,",
		"ru": "Жизнь,",
		"ja": "人生、",
		"de": "Leben,",
		"ms": "Kehidupan,",
		"vi": "Mạng sống,",
		"fr": "Vie,",
		"tr": "Hayat,",
		"it": "Vita,",
		"th": "ชีวิต,",
		"fa": "زندگی ،",
		"pl": "Życie,",
		"uk": "Життя,",
		"uz": "Hayot,",
		"ro": "Viaţă,",
		"az": "Həyat,",
		"hr": "Život,",
		"bg": "Живот,",
		"sr": "Живот,",
		"hy": "Կյանք,",
		"eu": "Bizitza,",
		"be": "Жыццё,",
		"ca": "La vida,",
		"cs": "Život,",
		"da": "Liv,",
		"nl": "Leven,",
		"et": "Elu,",
		"tl": "Buhay,",
		"gl": "Vida,",
		"ka": "სიცოცხლე,",
		"el": "ΖΩΗ,",
		"hu": "Élet,",
		"is": "Lífið,",
		"lv": "Dzīve,",
		"lt": "Gyvenimas,",
		"mk": "Живот,",
		"no": "Liv,",
		"sk": "Život,",
		"sl": "Življenje,",
		"sv": "Liv,",
		"fi": "Elämä,",
		"af": "Lewe,",
		"sq": "Jeta,",
		"am": "ሕይወት ፣",
		"my": "ဘဝ၊",
		"gu": "જીવન,",
		"he": "חַיִים,",
		"id": "Kehidupan,",
		"kn": "ಜೀವನ,",
		"kk": "Өмір,",
		"km": "ជីវិត,",
		"ky": "Жашоо,",
		"lo": "ຊີວິດ,",
		"ml": "ജീവിതം,",
		"mr": "आयुष्य,",
		"mn": "Амьдрал,",
		"ne": "जिन्दगी,",
		"pa": "ਜ਼ਿੰਦਗੀ,",
		"si": "ජීවිතය,",
		"sw": "Maisha,",
		"ta": "வாழ்க்கை,",
		"te": "జీవితం,",
		"ur": "زندگی ،",
		"zu": "Impilo,",
	},
	"liberty": {
		"en": "Liberty,",
		"es": "Libertad,",
		"zh": "自由，",
		"hi": "स्वतंत्रता,",
		"ar": "حرية،",
		"pt": "Liberdade,",
		"bn": "স্বাধীনতা,",
		"ru": "Свобода,",
		"ja": "自由、",
		"de": "Freiheit,",
		"ms": "Kebebasan,",
		"vi": "Liberty,",
		"fr": "Liberté,",
		"tr": "özgürlük,",
		"it": "Libertà,",
		"th": "เสรีภาพ,",
		"fa": "آزادی،",
		"pl": "Wolność,",
		"uk": "Свобода,",
		"uz": "Ozodlik,",
		"ro": "Libertate,",
		"az": "Azadlıq,",
		"hr": "Sloboda,",
		"bg": "Свобода,",
		"sr": "Слобода,",
		"hy": "Ազատություն,",
		"eu": "Askatasuna,",
		"be": "Свабода,",
		"ca": "Llibertat,",
		"cs": "Svoboda,",
		"da": "Frihed,",
		"nl": "Vrijheid,",
		"et": "Liberty,",
		"tl": "Kalayaan,",
		"gl": "Liberty,",
		"ka": "თავისუფლება,",
		"el": "Ελευθερία,",
		"hu": "Szabadság,",
		"is": "Frelsi,",
		"lv": "Brīvība,",
		"lt": "Laisvė,",
		"mk": "Слобода,",
		"no": "Frihet,",
		"sk": "Sloboda,",
		"sl": "Svoboda,",
		"sv": "Frihet,",
		"fi": "Liberty,",
		"af": "Vryheid,",
		"sq": "Liria,",
		"am": "ነፃነት ፣",
		"my": "လစ်ဘာတီ၊",
		"gu": "સ્વતંત્રતા,",
		"he": "חוֹפֶשׁ,",
		"id": "Kebebasan,",
		"kn": "ಸ್ವಾತಂತ್ರ್ಯ,",
		"kk": "Бостандық,",
		"km": "សេរីភាព,",
		"ky": "Эркиндик,",
		"lo": "ເສລີພາບ,",
		"ml": "സ്വാതന്ത്ര്യം,",
		"mr": "स्वातंत्र्य,",
		"mn": "Эрх чөлөө,",
		"ne": "स्वतन्त्रता,",
		"pa": "ਆਜ਼ਾਦੀ,",
		"si": "නිදහස,",
		"sw": "Uhuru,",
		"ta": "சுதந்திரம்,",
		"te": "స్వేచ్ఛ,",
		"ur": "آزادی ،",
		"zu": "Inkululeko,",
	},
	"andThePursuitOfHappiness": {
		"en": "And the pursuit of happiness.",
		"es": "Y la búsqueda de la felicidad.",
		"zh": "以及对幸福的追求。",
		"hi": "और खुशी की खोज।",
		"ar": "والسعي وراء السعادة.",
		"pt": "E a busca pela felicidade.",
		"bn": "আর সুখের সাধনা।",
		"ru": "И погоня за счастьем.",
		"ja": "そして幸福の追求。",
		"de": "Und das Streben nach Glück.",
		"ms": "Dan mengejar kebahagiaan.",
		"vi": "Và theo đuổi hạnh phúc.",
		"fr": "Et la poursuite du bonheur.",
		"tr": "Ve mutluluk arayışı.",
		"it": "E la ricerca della felicità.",
		"th": "และการแสวงหาความสุข",
		"fa": "و جستجوی خوشبختی",
		"pl": "I pogoń za szczęściem.",
		"uk": "І прагнення до щастя.",
		"uz": "Va baxtga intilish.",
		"ro": "Și căutarea fericirii.",
		"az": "Və xoşbəxtlik axtarışı.",
		"hr": "I potraga za srećom.",
		"bg": "И стремежът към щастие.",
		"sr": "И потрага за срећом.",
		"hy": "Եվ երջանկության ձգտումը:",
		"eu": "Eta zoriontasuna bilatzea.",
		"be": "І імкненне да шчасця.",
		"ca": "I la recerca de la felicitat.",
		"cs": "A honba za štěstím.",
		"da": "Og jagten på lykke.",
		"nl": "En het streven naar geluk.",
		"et": "Ja õnne poole püüdlemine.",
		"tl": "At ang paghabol sa kaligayahan.",
		"gl": "E a procura da felicidade.",
		"ka": "და ბედნიერებისკენ სწრაფვა.",
		"el": "Και το κυνήγι της ευτυχίας.",
		"hu": "És a boldogságra való törekvés.",
		"is": "Og leitin að hamingjunni.",
		"lv": "Un tiekšanās pēc laimes.",
		"lt": "Ir laimės siekimas.",
		"mk": "И потрага по среќа.",
		"no": "Og jakten på lykke.",
		"sk": "A honba za šťastím.",
		"sl": "In iskanje sreče.",
		"sv": "Och jakten på lycka.",
		"fi": "Ja onnen tavoittelu.",
		"af": "En die strewe na geluk.",
		"sq": "Dhe kërkimi i lumturisë.",
		"am": "እና ደስታን ማሳደድ።",
		"my": "ပျော်ရွှင်မှုကိုလိုက်ရှာသည်။",
		"gu": "અને સુખની શોધ.",
		"he": "והחתירה לאושר.",
		"id": "Dan mengejar kebahagiaan.",
		"kn": "ಮತ್ತು ಸಂತೋಷದ ಅನ್ವೇಷಣೆ.",
		"kk": "Және бақытқа ұмтылу.",
		"km": "និងការស្វែងរកសុភមង្គល។",
		"ky": "Жана бакытка умтулуу.",
		"lo": "ແລະການສະແຫວງຫາຄວາມສຸກ.",
		"ml": "ഒപ്പം സന്തോഷത്തിന്റെ പിന്തുടരലും.",
		"mr": "आणि आनंदाचा शोध.",
		"mn": "Мөн аз жаргалын эрэл хайгуул.",
		"ne": "र खुशी को खोजी।",
		"pa": "ਅਤੇ ਖੁਸ਼ੀ ਦੀ ਪ੍ਰਾਪਤੀ.",
		"si": "සහ සතුට සඳහා ලුහුබැඳීම.",
		"sw": "Na kutafuta furaha.",
		"ta": "மற்றும் மகிழ்ச்சியைத் தேடுவது.",
		"te": "మరియు ఆనందం కోసం వెతుకులాట.",
		"ur": "اور خوشی کا حصول۔",
		"zu": "Nokufuna injabulo.",
	},
	"itsNowTrulyForEveryone": {
		"en": "It's now truly for everyone.",
		"es": "Ahora es verdaderamente para todos.",
		"zh": "它现在真正适合所有人。",
		"hi": "यह अब वास्तव में सभी के लिए है।",
		"ar": "إنه الآن حقًا للجميع.",
		"pt": "Agora é realmente para todos.",
		"bn": "এটি এখন সত্যিকার অর্থে সবার জন্য।",
		"ru": "Теперь это действительно для всех.",
		"ja": "今では本当にすべての人のためです。",
		"de": "Es ist jetzt wirklich für alle.",
		"ms": "Kini benar-benar sesuai untuk semua orang.",
		"vi": "Bây giờ nó thực sự dành cho tất cả mọi người.",
		"fr": "C'est maintenant vraiment pour tout le monde.",
		"tr": "Artık gerçekten herkes için.",
		"it": "Ora è davvero per tutti.",
		"th": "ตอนนี้เป็นจริงสำหรับทุกคน",
		"fa": "اکنون واقعاً برای همه است.",
		"pl": "Teraz jest naprawdę dla wszystkich.",
		"uk": "Тепер це справді для всіх.",
		"uz": "Bu haqiqatan ham hamma uchun.",
		"ro": "Acum este cu adevărat pentru toată lumea.",
		"az": "İndi həqiqətən hər kəs üçündür.",
		"hr": "Sada je uistinu za svakoga.",
		"bg": "Сега е наистина за всички.",
		"sr": "Сада је заиста за свакога.",
		"hy": "Այժմ դա իսկապես բոլորի համար է:",
		"eu": "Benetan da guztiontzat.",
		"be": "Цяпер гэта сапраўды для ўсіх.",
		"ca": "Ara és realment per a tothom.",
		"cs": "Nyní je to opravdu pro každého.",
		"da": "Det er nu virkelig for alle.",
		"nl": "Het is nu echt voor iedereen.",
		"et": "See on nüüd tõesti kõigile.",
		"tl": "Totoo ito ngayon para sa lahat.",
		"gl": "Agora é verdadeiramente para todos.",
		"ka": "ახლა ნამდვილად ყველასთვისაა.",
		"el": "Τώρα είναι πραγματικά για όλους.",
		"hu": "Ez most tényleg mindenkinek szól.",
		"is": "Það er nú sannarlega fyrir alla.",
		"lv": "Tagad tas ir patiešām ikvienam.",
		"lt": "Dabar tai tikrai visiems.",
		"mk": "Сега е навистина за секого.",
		"no": "Det er nå virkelig for alle.",
		"sk": "Teraz je to naozaj pre každého.",
		"sl": "Zdaj je resnično za vsakogar.",
		"sv": "Det är nu verkligen för alla.",
		"fi": "Se on nyt todella kaikille.",
		"af": "Dit is nou werklik vir almal.",
		"sq": "Tani është vërtet për të gjithë.",
		"am": "አሁን በእውነት ለሁሉም ነው።",
		"my": "အခုကလူတိုင်းအတွက်တကယ်ဖြစ်လာပြီ။",
		"gu": "તે હવે ખરેખર દરેક માટે છે.",
		"he": "עכשיו זה באמת מתאים לכולם.",
		"id": "Sekarang benar-benar untuk semua orang.",
		"kn": "ಇದು ಈಗ ನಿಜವಾಗಿಯೂ ಎಲ್ಲರಿಗೂ ಆಗಿದೆ.",
		"kk": "Бұл шынымен барлығына арналған.",
		"km": "ឥឡូវនេះវាពិតជាសម្រាប់មនុស្សគ្រប់គ្នា។",
		"ky": "Бул азыр чындап эле баарына тиешелүү.",
		"lo": "ດຽວນີ້ມັນເປັນຄວາມຈິງ ສຳ ລັບທຸກຄົນ.",
		"ml": "ഇത് ഇപ്പോൾ എല്ലാവർക്കുമുള്ളതാണ്.",
		"mr": "हे आता प्रत्येकासाठी खरोखर आहे.",
		"mn": "Энэ нь үнэхээр бүх хүнд зориулагдсан болно.",
		"ne": "यो अब साँच्चै सबैको लागी हो।",
		"pa": "ਇਹ ਹੁਣ ਸਾਰਿਆਂ ਲਈ ਸੱਚਮੁੱਚ ਹੈ.",
		"si": "දැන් එය සෑම කෙනෙකුටම සත්‍යයකි.",
		"sw": "Sasa ni kweli kwa kila mtu.",
		"ta": "இது இப்போது அனைவருக்கும் உண்மையாக உள்ளது.",
		"te": "ఇది ఇప్పుడు అందరికీ నిజంగా ఉంది.",
		"ur": "یہ اب واقعی سب کے لیے ہے۔",
		"zu": "Manje seyenzelwe wonke umuntu ngokweqiniso.",
	},
	"duendeCanHelp": {
		"en": "Duende can help.",
		"es": "Duende puede ayudar.",
		"zh": "杜安德可以提供帮助。",
		"hi": "डुएन्डे मदद कर सकता है।",
		"ar": "يمكن أن يساعد دويندي.",
		"pt": "Duende pode ajudar.",
		"bn": "ডিউন্ডে সাহায্য করতে পারে।",
		"ru": "Дуэнде может помочь.",
		"ja": "ドゥエンデがお手伝いします。",
		"de": "Duende kann helfen.",
		"ms": "Duende boleh membantu.",
		"vi": "Duende có thể giúp.",
		"fr": "Duende peut vous aider.",
		"tr": "Duende yardımcı olabilir.",
		"it": "Duende può aiutare.",
		"th": "ดันเต้ช่วยได้",
		"fa": "دئوند می تواند کمک کند.",
		"pl": "Duende może pomóc.",
		"uk": "Дуенде може допомогти.",
		"uz": "Duende yordam berishi mumkin.",
		"ro": "Duende vă poate ajuta.",
		"az": "Duende kömək edə bilər.",
		"hr": "Duende može pomoći.",
		"bg": "Duende може да помогне.",
		"sr": "Дуенде може помоћи.",
		"hy": "Դեունդեն կարող է օգնել:",
		"eu": "Duendek lagun dezake.",
		"be": "Duende можа дапамагчы.",
		"ca": "Duende us pot ajudar.",
		"cs": "Duende může pomoci.",
		"da": "Duende kan hjælpe.",
		"nl": "Duende kan helpen.",
		"et": "Duende saab aidata.",
		"tl": "Si Duende ay maaaring makatulong.",
		"gl": "Duende pode axudar.",
		"ka": "დუენდეს შეუძლია დახმარება.",
		"el": "Το Duende μπορεί να βοηθήσει.",
		"hu": "Duende segíthet.",
		"is": "Duende getur hjálpað.",
		"lv": "Duende var palīdzēt.",
		"lt": "Duende gali padėti.",
		"mk": "Дуенде може да помогне.",
		"no": "Duende kan hjelpe.",
		"sk": "Duende môže pomôcť.",
		"sl": "Duende lahko pomaga.",
		"sv": "Duende kan hjälpa.",
		"fi": "Duende voi auttaa.",
		"af": "Duende kan help.",
		"sq": "Duende mund të ndihmojë.",
		"am": "Duende ሊረዳ ይችላል።",
		"my": "Duende ကကူညီနိုင်ပါတယ်။",
		"gu": "Duende મદદ કરી શકે છે.",
		"he": "Duende יכול לעזור.",
		"id": "Duende dapat membantu.",
		"kn": "ಬ್ಯುಂಡೆ ಸಹಾಯ ಮಾಡಬಹುದು.",
		"kk": "Duende көмектесе алады.",
		"km": "ឌែនដេអាចជួយបាន។",
		"ky": "Duende жардам бере алат.",
		"lo": "Duende ສາມາດຊ່ວຍໄດ້.",
		"ml": "ഡ്യൂണ്ടെ സഹായിക്കും.",
		"mr": "देउंडे मदत करू शकतात.",
		"mn": "Duende тусалж чадна.",
		"ne": "Duende मद्दत गर्न सक्नुहुन्छ।",
		"pa": "ਡਿndeਂਡੇ ਮਦਦ ਕਰ ਸਕਦਾ ਹੈ.",
		"si": "ඩියුන්ඩේට උදව් කළ හැකිය.",
		"sw": "Duende anaweza kusaidia.",
		"ta": "டூண்டே உதவலாம்.",
		"te": "డ్యూండే సహాయం చేయవచ్చు.",
		"ur": "ڈونڈے مدد کر سکتا ہے۔",
		"zu": "UDuende angasiza.",
	},
	"letsGoTogether": {
		"en": "Let's go together.",
		"es": "Vamos juntos.",
		"zh": "我们一起去吧。",
		"hi": "चलो साथ चलते हैं।",
		"ar": "لنذهب معا.",
		"pt": "Vamos juntos.",
		"bn": "চল একসাথে যাই.",
		"ru": "Пойдем вместе.",
		"ja": "一緒に行こう。",
		"de": "Lassen Sie uns gemeinsam gehen.",
		"ms": "Mari pergi bersama.",
		"vi": "Hãy đi cùng nhau.",
		"fr": "Allons-y ensemble.",
		"tr": "Hadi birlikte gidelim.",
		"it": "Andiamo insieme.",
		"th": "ไปด้วยกัน.",
		"fa": "بیا با هم بریم.",
		"pl": "Chodźmy razem.",
		"uk": "Давай підемо разом.",
		"uz": "Keling birga boramiz.",
		"ro": "Să mergem împreună.",
		"az": "Gəl birlikdə gedək.",
		"hr": "Idemo zajedno.",
		"bg": "Нека отидем заедно.",
		"sr": "Идемо заједно.",
		"hy": "Գնանք միասին.",
		"eu": "Goazen elkarrekin.",
		"be": "Пойдзем разам.",
		"ca": "Anem-hi junts.",
		"cs": "Pojďme společně.",
		"da": "Lad os gå sammen.",
		"nl": "Laten we samen gaan.",
		"et": "Lähme koos.",
		"tl": "Sabay tayo.",
		"gl": "Imos xuntos.",
		"ka": "წავიდეთ ერთად.",
		"el": "Ας πάμε μαζί.",
		"hu": "Menjünk együtt.",
		"is": "Förum saman.",
		"lv": "Ejam kopā.",
		"lt": "Eime kartu.",
		"mk": "Ајде да одиме заедно.",
		"no": "La oss gå sammen.",
		"sk": "Poďme spolu.",
		"sl": "Pojdimo skupaj.",
		"sv": "Låt oss gå ihop.",
		"fi": "Mennään yhdessä.",
		"af": "Kom ons gaan saam.",
		"sq": "Shkojme bashke.",
		"am": "አብረን እንሂድ.",
		"my": "အတူတူသွားကြစို့။",
		"gu": "ચાલો સાથે જઈએ.",
		"he": "בוא נלך יחד.",
		"id": "Ayo pergi bersama.",
		"kn": "ಒಟ್ಟಿಗೆ ಹೋಗೋಣ.",
		"kk": "Бірге барайық.",
		"km": "តោះទៅជាមួយគ្នា។",
		"ky": "Кел чогуу баралы.",
		"lo": "ໄປ ນຳ ກັນ.",
		"ml": "നമുക്കൊരുമിച്ചു പോവാം.",
		"mr": "चला एकत्र जाऊया.",
		"mn": "Хамтдаа явцгаая.",
		"ne": "सँगै जाऔं।",
		"pa": "ਆਓ ਮਿਲ ਕੇ ਚੱਲੀਏ.",
		"si": "අපි එකට යමු.",
		"sw": "Twende pamoja.",
		"ta": "ஒன்றாக செல்லலாம்.",
		"te": "కలిసి వెళ్దాం.",
		"ur": "چلو ساتھ چلتے ہیں.",
		"zu": "Asihambe ndawonye.",
	},
	"scanQRCodeWithYourDuendeAppToLogin": {
		"en": "Scan the QR code with your Duende app to log in.",
		"es": "Escanee el código QR con su aplicación Duende para iniciar sesión.",
		"zh": "使用 Duende 应用程序扫描二维码以登录。",
		"hi": "लॉग इन करने के लिए अपने डुएन्डे ऐप से क्यूआर कोड को स्कैन करें।",
		"ar": "امسح رمز الاستجابة السريعة باستخدام تطبيق Duende لتسجيل الدخول.",
		"pt": "Digitalize o código QR com o aplicativo Duende para fazer login.",
		"bn": "লগ ইন করার জন্য আপনার ডিউন্ড অ্যাপ দিয়ে কিউআর কোড স্ক্যান করুন।",
		"ru": "Отсканируйте QR-код с помощью приложения Duende, чтобы войти в систему.",
		"ja": "DuendeアプリでQRコードをスキャンしてログインします。",
		"de": "Scannen Sie den QR-Code mit Ihrer Duende-App, um sich anzumelden.",
		"ms": "Imbas kod QR dengan aplikasi Duende anda untuk log masuk.",
		"vi": "Quét mã QR bằng ứng dụng Duende của bạn để đăng nhập.",
		"fr": "Scannez le code QR avec votre application Duende pour vous connecter.",
		"tr": "Giriş yapmak için QR kodunu Duende uygulamanızla tarayın.",
		"it": "Scansiona il codice QR con la tua app Duende per accedere.",
		"th": "สแกนรหัส QR ด้วยแอป Duende เพื่อเข้าสู่ระบบ",
		"fa": "برای ورود به سیستم ، کد QR را با برنامه Duende خود اسکن کنید.",
		"pl": "Zeskanuj kod QR za pomocą aplikacji Duende, aby się zalogować.",
		"uk": "Відскануйте QR -код за допомогою програми Duende для входу.",
		"uz": "Kirish uchun Duende ilovangiz bilan QR kodini skanerlang.",
		"ro": "Scanați codul QR cu aplicația dvs. Duende pentru a vă conecta.",
		"az": "Daxil olmaq üçün Duende tətbiqinizlə QR kodunu tarayın.",
		"hr": "Skenirajte QR kôd pomoću aplikacije Duende za prijavu.",
		"bg": "Сканирайте QR кода с приложението Duende, за да влезете.",
		"sr": "Скенирајте КР код помоћу апликације Дуенде да бисте се пријавили.",
		"hy": "Մուտք գործելու համար սկանավորեք QR կոդը ձեր Duende հավելվածով:",
		"eu": "Eskaneatu QR kodea Duende aplikazioarekin saioa hasteko.",
		"be": "Адскануйце QR -код з дапамогай праграмы Duende для ўваходу.",
		"ca": "Escaneja el codi QR amb la teva aplicació Duende per iniciar la sessió.",
		"cs": "Chcete -li se přihlásit, naskenujte QR kód pomocí aplikace Duende.",
		"da": "Scan QR -koden med din Duende -app for at logge ind.",
		"nl": "Scan de QR-code met je Duende-app om in te loggen.",
		"et": "Sisselogimiseks skaneerige oma Duende rakendusega QR -kood.",
		"tl": "I-scan ang QR code gamit ang iyong Duende app upang mag-log in.",
		"gl": "Escanea o código QR coa túa aplicación Duende para iniciar sesión.",
		"ka": "სისტემაში შესასვლელად დაასკანირეთ QR კოდი თქვენი Duende აპლიკაციით.",
		"el": "Για να συνδεθείτε, σαρώστε τον κωδικό QR με την εφαρμογή Duende.",
		"hu": "A bejelentkezéshez olvassa be a QR -kódot Duende alkalmazásával.",
		"is": "Skannaðu QR kóða með Duende forritinu þínu til að skrá þig inn.",
		"lv": "Lai pieteiktos, skenējiet QR kodu, izmantojot savu Duende lietotni.",
		"lt": "Norėdami prisijungti, nuskaitykite QR kodą naudodami „Duende“ programą.",
		"mk": "Скенирајте го QR -кодот со вашата апликација Duende за да се најавите.",
		"no": "Skann QR -koden med Duende -appen for å logge på.",
		"sk": "Naskenujte QR kód pomocou aplikácie Duende a prihláste sa.",
		"sl": "Za prijavo skenirajte kodo QR z aplikacijo Duende.",
		"sv": "Skanna QR -koden med din Duende -app för att logga in.",
		"fi": "Kirjaudu sisään skannaamalla QR -koodi Duende -sovelluksellasi.",
		"af": "Skandeer die QR -kode met u Duende -app om aan te meld.",
		"sq": "Skanoni kodin QR me aplikacionin tuaj Duende për t'u regjistruar.",
		"am": "ለመግባት በ Duende መተግበሪያዎ የ QR ኮዱን ይቃኙ።",
		"my": "သင်၏ Duende အက်ပလီကေးရှင်းဖြင့် QR ကုဒ်ကိုစကင်ဖတ်ပါ။",
		"gu": "લ Dueગ ઇન કરવા માટે તમારી ડ્યુન્ડે એપ્લિકેશન સાથે QR કોડ સ્કેન કરો.",
		"he": "סרוק את קוד ה- QR באמצעות אפליקציית Duende שלך כדי להיכנס.",
		"id": "Pindai kode QR dengan aplikasi Duende Anda untuk masuk.",
		"kn": "ಲಾಗ್ ಇನ್ ಮಾಡಲು ನಿಮ್ಮ ಡ್ಯೂಂಡೆ ಆಪ್‌ನೊಂದಿಗೆ ಕ್ಯೂಆರ್ ಕೋಡ್ ಅನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ.",
		"kk": "Кіру үшін Duende қосымшасымен QR кодын сканерлеңіз.",
		"km": "ស្កេនកូដ QR ជាមួយកម្មវិធី Duende របស់អ្នកដើម្បីចូល។",
		"ky": "Кирүү үчүн Duende колдонмоңуз менен QR кодун сканерлеңиз.",
		"lo": "ສະແກນລະຫັດ QR ດ້ວຍແອັບ Duende ຂອງເຈົ້າເພື່ອເຂົ້າສູ່ລະບົບ.",
		"ml": "ലോഗിൻ ചെയ്യുന്നതിന് നിങ്ങളുടെ ഡ്യൂൻഡെ ആപ്പ് ഉപയോഗിച്ച് QR കോഡ് സ്കാൻ ചെയ്യുക.",
		"mr": "लॉग इन करण्यासाठी क्यूआर कोड तुमच्या ड्युंडे अॅपसह स्कॅन करा.",
		"mn": "QR кодыг Duende програмаараа сканнердаж нэвтэрнэ үү.",
		"ne": "तपाइँको Duende अनुप्रयोग संग लग इन गर्न को लागी QR कोड स्क्यान गर्नुहोस्।",
		"pa": "ਲੌਗ ਇਨ ਕਰਨ ਲਈ ਆਪਣੀ ਡਿਉਨਡੇ ਐਪ ਨਾਲ ਕਿ Q ਆਰ ਕੋਡ ਸਕੈਨ ਕਰੋ.",
		"si": "ප්‍රවේශ වීම සඳහා ඔබේ ඩියුන්ඩේ යෙදුම සමඟ QR කේතය පරිලෝකනය කරන්න.",
		"sw": "Changanua nambari ya QR na programu yako ya Duende ili uingie.",
		"ta": "உள்நுழைய உங்கள் டியூண்டே பயன்பாட்டைக் கொண்டு QR குறியீட்டை ஸ்கேன் செய்யவும்.",
		"te": "లాగిన్ అవ్వడానికి మీ డ్యూండే యాప్‌తో QR కోడ్‌ని స్కాన్ చేయండి.",
		"ur": "لاگ ان کرنے کے لیے اپنی ڈیوینڈ ایپ کے ساتھ کیو آر کوڈ اسکین کریں۔",
		"zu": "Skena ikhodi ye-QR ngohlelo lwakho lokusebenza lwe-Duende ukuze ungene ngemvume.",
	},
		"wrongVerificationCode": {
		"en": "Wrong QR code",
		"es": "Código QR incorrecto",
		"zh": "错误的二维码",
		"hi": "गलत क्यूआर कोड",
		"ar": "رمز الاستجابة السريعة خاطئ",
		"pt": "Código QR errado",
		"bn": "ভুল QR কোড",
		"ru": "Неправильный QR-код",
		"ja": "間違ったQRコード",
		"de": "Falscher QR-Code",
		"ms": "Kod QR yang salah",
		"vi": "Mã QR sai",
		"fr": "Code QR incorrect",
		"tr": "Yanlış QR kodu",
		"it": "Codice QR sbagliato",
		"th": "รหัส QR ผิด",
		"fa": "کد QR اشتباه است",
		"pl": "Nieprawidłowy kod QR",
		"uk": "Неправильний QR -код",
		"uz": "QR kod noto'g'ri",
		"ro": "Cod QR greșit",
		"az": "Yanlış QR kodu",
		"hr": "Pogrešan QR kôd",
		"bg": "Грешен QR код",
		"sr": "Погрешан КР код",
		"hy": "Սխալ QR կոդ",
		"eu": "QR kode okerra",
		"be": "Няправільны QR -код",
		"ca": "Codi QR incorrecte",
		"cs": "Špatný QR kód",
		"da": "Forkert QR -kode",
		"nl": "Verkeerde QR-code",
		"et": "Vale QR -kood",
		"tl": "Maling QR code",
		"gl": "Código QR incorrecto",
		"ka": "არასწორი QR კოდი",
		"el": "Λάθος κωδικός QR",
		"hu": "Rossz QR -kód",
		"is": "Rangur QR kóði",
		"lv": "Nepareizs QR kods",
		"lt": "Neteisingas QR kodas",
		"mk": "Погрешен QR -код",
		"no": "Feil QR -kode",
		"sk": "Nesprávny QR kód",
		"sl": "Napačna QR koda",
		"sv": "Fel QR -kod",
		"fi": "Väärä QR -koodi",
		"af": "Verkeerde QR -kode",
		"sq": "Kodi i gabuar QR",
		"am": "የተሳሳተ የ QR ኮድ",
		"my": "QR ကုဒ်မှားနေသည်",
		"gu": "ખોટો QR કોડ",
		"he": "קוד QR שגוי",
		"id": "Kode QR salah",
		"kn": "ತಪ್ಪು QR ಕೋಡ್",
		"kk": "QR коды қате",
		"km": "លេខកូដ QR ខុស",
		"ky": "Туура эмес QR коду",
		"lo": "ລະຫັດ QR ຜິດ",
		"ml": "തെറ്റായ ക്യുആർ കോഡ്",
		"mr": "चुकीचा QR कोड",
		"mn": "Буруу QR код",
		"ne": "गलत QR कोड",
		"pa": "ਗਲਤ QR ਕੋਡ",
		"si": "වැරදි QR කේතය",
		"sw": "Nambari ya QR isiyo sahihi",
		"ta": "தவறான QR குறியீடு",
		"te": "QR కోడ్ తప్పు",
		"ur": "غلط QR کوڈ۔",
		"zu": "Ikhodi ye-QR engalungile",
	},
	"yourAccountHasBeenClosed": {
		"en": "Your account has been closed.",
		"es": "Tu cuenta ha sido cerrada.",
		"zh": "您的帐户已被关闭。",
		"hi": "आपका खाता बंद कर दिया गया है।",
		"ar": "تم إغلاق الحساب الخاص بك.",
		"pt": "Sua conta foi fechada.",
		"bn": "আপনার অ্যাকাউন্ট বন্ধ করা হয়েছে।",
		"ru": "Ваша учетная запись была закрыта.",
		"ja": "アカウントが閉鎖されました。",
		"de": "Ihr Konto wurde geschlossen.",
		"ms": "Akaun anda telah ditutup.",
		"vi": "Tài khoản của bạn đã bị đóng.",
		"fr": "Votre compte a été fermé.",
		"tr": "Hesabınız kapatıldı.",
		"it": "Il tuo account è stato chiuso.",
		"th": "บัญชีของคุณถูกปิด",
		"fa": "حساب شما بسته شد",
		"pl": "Twoje konto zostało zamknięte.",
		"uk": "Ваш обліковий запис закрито.",
		"uz": "Hisobingiz yopildi.",
		"ro": "Contul dvs. a fost închis.",
		"az": "Hesabınız bağlandı.",
		"hr": "Vaš je račun zatvoren.",
		"bg": "Профилът ви е затворен.",
		"sr": "Ваш налог је затворен.",
		"hy": "Ձեր հաշիվը փակվել է:",
		"eu": "Zure kontua itxi egin da.",
		"be": "Ваш рахунак зачынены.",
		"ca": "El vostre compte s'ha tancat.",
		"cs": "Váš účet byl uzavřen.",
		"da": "Din konto er blevet lukket.",
		"nl": "Uw account is gesloten.",
		"et": "Teie konto on suletud.",
		"tl": "Ang iyong account ay sarado.",
		"gl": "A túa conta pechouse.",
		"ka": "თქვენი ანგარიში დაიხურა.",
		"el": "Ο λογαριασμός σας έκλεισε.",
		"hu": "Fiókját lezártuk.",
		"is": "Reikningnum þínum hefur verið lokað.",
		"lv": "Jūsu konts ir slēgts.",
		"lt": "Jūsų sąskaita buvo uždaryta.",
		"mk": "Вашата сметка е затворена.",
		"no": "Kontoen din er stengt.",
		"sk": "Váš účet bol zatvorený.",
		"sl": "Vaš račun je bil zaprt.",
		"sv": "Ditt konto har stängts.",
		"fi": "Tilisi on suljettu.",
		"af": "Jou rekening is gesluit.",
		"sq": "Llogaria juaj është mbyllur.",
		"am": "የእርስዎ መለያ ተዘግቷል።",
		"my": "သင်၏အကောင့်ကိုပိတ်လိုက်သည်။",
		"gu": "તમારું ખાતું બંધ કરી દેવામાં આવ્યું છે.",
		"he": "החשבון שלך נסגר.",
		"id": "Akun Anda telah ditutup.",
		"kn": "ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಮುಚ್ಚಲಾಗಿದೆ.",
		"kk": "Есептік жазбаңыз жабылды.",
		"km": "គណនីរបស់អ្នកត្រូវបានបិទ។",
		"ky": "Сиздин каттоо эсебиңиз жабылды.",
		"lo": "ບັນຊີຂອງທ່ານໄດ້ຖືກປິດແລ້ວ.",
		"ml": "നിങ്ങളുടെ അക്കൗണ്ട് അടച്ചു.",
		"mr": "तुमचे खाते बंद करण्यात आले आहे.",
		"mn": "Таны данс хаагдсан байна.",
		"ne": "तपाइँको खाता बन्द गरिएको छ।",
		"pa": "ਤੁਹਾਡਾ ਖਾਤਾ ਬੰਦ ਕਰ ਦਿੱਤਾ ਗਿਆ ਹੈ.",
		"si": "ඔබේ ගිණුම වසා දමා ඇත.",
		"sw": "Akaunti yako imefungwa.",
		"ta": "உங்கள் கணக்கு மூடப்பட்டது.",
		"te": "మీ ఖాతా మూసివేయబడింది.",
		"ur": "آپ کا اکاؤنٹ بند کر دیا گیا ہے۔",
		"zu": "I-akhawunti yakho ivaliwe.",
	},
	"yourAccountHasBeenBanned": {
		"en": "Your account has been banned.",
		"es": "Su cuenta ha sido prohibida.",
		"zh": "您的帐户已被禁止。",
		"hi": "आपका खाता प्रतिबंधित कर दिया गया है।",
		"ar": "تم حظر الحساب الخاص بك.",
		"pt": "Sua conta foi banida.",
		"bn": "আপনার অ্যাকাউন্ট নিষিদ্ধ করা হয়েছে।",
		"ru": "Ваш аккаунт заблокирован.",
		"ja": "あなたのアカウントは禁止されました。",
		"de": "Dein Account wurde gesperrt.",
		"ms": "Akaun anda telah dilarang.",
		"vi": "Tài khoản của bạn đã bị cấm.",
		"fr": "Votre compte a été banni.",
		"tr": "Hesabınız yasaklandı.",
		"it": "Il tuo account è stato bannato.",
		"th": "บัญชีของคุณถูกแบน",
		"fa": "حساب شما ممنوع شده است.",
		"pl": "Twoje konto zostało zablokowane.",
		"uk": "Ваш обліковий запис заборонено.",
		"uz": "Sizning hisobingiz taqiqlangan.",
		"ro": "Contul dvs. a fost interzis.",
		"az": "Hesabınız qadağan edildi.",
		"hr": "Vaš je račun zabranjen.",
		"bg": "Профилът ви е забранен.",
		"sr": "Ваш налог је забрањен.",
		"hy": "Ձեր հաշիվն արգելված է:",
		"eu": "Zure kontua debekatuta dago.",
		"be": "Ваш уліковы запіс забаронены.",
		"ca": "El vostre compte ha estat prohibit.",
		"cs": "Váš účet byl zakázán.",
		"da": "Din konto er blevet udelukket.",
		"nl": "Je account is verbannen.",
		"et": "Teie konto on blokeeritud.",
		"tl": "Bawal ang iyong account.",
		"gl": "A túa conta foi prohibida.",
		"ka": "თქვენი ანგარიში აიკრძალა.",
		"el": "Ο λογαριασμός σας έχει αποκλειστεί.",
		"hu": "Fiókját kitiltották.",
		"is": "Reikningurinn þinn hefur verið bannaður.",
		"lv": "Jūsu konts ir aizliegts.",
		"lt": "Jūsų paskyra uždrausta.",
		"mk": "Вашата сметка е забранета.",
		"no": "Kontoen din er utestengt.",
		"sk": "Váš účet bol zakázaný.",
		"sl": "Vaš račun je bil prepovedan.",
		"sv": "Ditt konto har blockerats.",
		"fi": "Tilisi on kielletty.",
		"af": "Jou rekening is verban.",
		"sq": "Llogaria juaj është e ndaluar.",
		"am": "የእርስዎ መለያ ታግዷል።",
		"my": "သင်၏အကောင့်ကိုပိတ်ပင်ထားသည်။",
		"gu": "તમારા એકાઉન્ટ પર પ્રતિબંધ મૂકવામાં આવ્યો છે.",
		"he": "החשבון שלך נאסר.",
		"id": "Akun Anda telah diblokir.",
		"kn": "ನಿಮ್ಮ ಖಾತೆಯನ್ನು ನಿಷೇಧಿಸಲಾಗಿದೆ.",
		"kk": "Есептік жазбаңызға тыйым салынды.",
		"km": "គណនីរបស់អ្នកត្រូវបានហាមឃាត់។",
		"ky": "Сиздин каттоо эсебиңизге тыюу салынган.",
		"lo": "ບັນຊີຂອງທ່ານຖືກຫ້າມ.",
		"ml": "നിങ്ങളുടെ അക്കൗണ്ട് നിരോധിച്ചിരിക്കുന്നു.",
		"mr": "तुमच्या खात्यावर बंदी घालण्यात आली आहे.",
		"mn": "Таны дансыг хориглосон байна.",
		"ne": "तपाइँको खाता प्रतिबन्धित गरीएको छ।",
		"pa": "ਤੁਹਾਡੇ ਖਾਤੇ ਤੇ ਪਾਬੰਦੀ ਲਗਾਈ ਗਈ ਹੈ.",
		"si": "ඔබේ ගිණුම තහනම් කර ඇත.",
		"sw": "Akaunti yako imepigwa marufuku.",
		"ta": "உங்கள் கணக்கு தடை செய்யப்பட்டுள்ளது.",
		"te": "మీ ఖాతా నిషేధించబడింది.",
		"ur": "آپ کے اکاؤنٹ پر پابندی لگا دی گئی ہے۔",
		"zu": "I-akhawunti yakho ivinjelwe.",
	},
	"myAccount": {
		"en": "My Account",
		"es": "Mi cuenta",
		"zh": "我的账户",
		"hi": "मेरा खाता",
		"ar": "حسابي",
		"pt": "Minha conta",
		"bn": "আমার অ্যাকাউন্ট",
		"ru": "Мой аккаунт",
		"ja": "マイアカウント",
		"de": "Mein Konto",
		"ms": "Akaun saya",
		"vi": "Tài khoản của tôi",
		"fr": "Mon compte",
		"tr": "Hesabım",
		"it": "Il mio account",
		"th": "บัญชีของฉัน",
		"fa": "حساب من",
		"pl": "Moje konto",
		"uk": "Мій рахунок",
		"uz": "Mening hisobim",
		"ro": "Contul meu",
		"az": "Mənim Hesabım",
		"hr": "Moj račun",
		"bg": "Моята сметка",
		"sr": "Мој налог",
		"hy": "Իմ հաշիվը",
		"eu": "Nire kontua",
		"be": "Мой рахунак",
		"ca": "El meu compte",
		"cs": "Můj účet",
		"da": "Min konto",
		"nl": "Mijn rekening",
		"et": "Minu konto",
		"tl": "Aking Account",
		"gl": "A miña conta",
		"ka": "Ჩემი ანგარიში",
		"el": "Ο λογαριασμός μου",
		"hu": "Fiókom",
		"is": "Minn reikningur",
		"lv": "Mans Konts",
		"lt": "Mano sąskaita",
		"mk": "Мојот акаунт",
		"no": "Min konto",
		"sk": "Môj účet",
		"sl": "Moj račun",
		"sv": "Mitt konto",
		"fi": "Tilini",
		"af": "My rekening",
		"sq": "Llogaria ime",
		"am": "አካውንቴ",
		"my": "ငါ့အကောင့်",
		"gu": "મારું ખાતું",
		"he": "החשבון שלי",
		"id": "Akun saya",
		"kn": "ನನ್ನ ಖಾತೆ",
		"kk": "Менің аккаунтым",
		"km": "គណនី​របស់ខ្ញុំ",
		"ky": "Менин эсебим",
		"lo": "ບັນ​ຊີ​ຂອງ​ຂ້ອຍ",
		"ml": "എന്റെ അക്കൗണ്ട്",
		"mr": "माझे खाते",
		"mn": "Миний данс",
		"ne": "मेरो खाता",
		"pa": "ਮੇਰਾ ਖਾਤਾ",
		"si": "මගේ ගිණුම",
		"sw": "Akaunti yangu",
		"ta": "என் கணக்கு",
		"te": "నా ఖాతా",
		"ur": "میرا اکاونٹ",
		"zu": "I-akhawunti yami",
	},
	"currentlyLoggedInAs": {
		"en": "Currently Logged In As",
		"es": "Actualmente conectado como",
		"zh": "当前登录身份",
		"hi": "वर्तमान में लॉग इन As",
		"ar": "مسجّل الدخول حاليًا باسم",
		"pt": "Atualmente logado como",
		"bn": "বর্তমানে হিসাবে লগ ইন",
		"ru": "Вы вошли как",
		"ja": "現在ログインしている",
		"de": "Derzeit eingeloggt als",
		"ms": "Sedang Dilog masuk Sebagai",
		"vi": "Hiện đã đăng nhập với tư cách",
		"fr": "Actuellement connecté en tant que",
		"tr": "Şu Anda Oturum Açıldı",
		"it": "Attualmente connesso come",
		"th": "กำลังเข้าสู่ระบบในฐานะ",
		"fa": "در حال حاضر به عنوان وارد شده است",
		"pl": "Obecnie zalogowany jako",
		"uk": "В даний час Ви ввійшли як",
		"uz": "Hozirda tizimga kirgan",
		"ro": "Conectat în prezent ca",
		"az": "Hal -hazırda Giriş",
		"hr": "Trenutno prijavljeni kao",
		"bg": "В момента сте влезли като",
		"sr": "Тренутно пријављени као",
		"hy": "Ներկայումս գրանցված է որպես",
		"eu": "Une honetan hasi duzu saioa",
		"be": "У цяперашні час Вы ўвайшлі як",
		"ca": "En aquest moment heu iniciat la sessió com",
		"cs": "Aktuálně přihlášen jako",
		"da": "I øjeblikket logget ind som",
		"nl": "Momenteel aangemeld als",
		"et": "Praegu sisse logitud kui",
		"tl": "Kasalukuyang Naka-log In Bilang",
		"gl": "Iniciado sesión como",
		"ka": "ამჟამად შესულია როგორც",
		"el": "Προς το παρόν συνδεδεμένος ως",
		"hu": "Jelenleg bejelentkezve",
		"is": "Eins og er skráð inn sem",
		"lv": "Pašlaik pieteicies kā",
		"lt": "Šiuo metu prisijungęs kaip",
		"mk": "Во моментов најавени како",
		"no": "Foreløpig logget inn som",
		"sk": "Aktuálne prihlásený ako",
		"sl": "Trenutno prijavljeni kot",
		"sv": "För närvarande inloggad som",
		"fi": "Tällä hetkellä kirjautunut sisään nimellä",
		"af": "Tans aangemeld as",
		"sq": "Aktualisht i Identifikuar Si",
		"am": "በአሁኑ ጊዜ እንደ ገብቷል",
		"my": "လက်ရှိတွင် Log in အဖြစ်",
		"gu": "હાલમાં લ Logગ ઇન તરીકે",
		"he": "כרגע מחובר בשם",
		"id": "Saat Ini Masuk Sebagai",
		"kn": "ಪ್ರಸ್ತುತ ಲಾಗ್ ಇನ್ ಆಗಿ",
		"kk": "Қазіргі уақытта ретінде кірген",
		"km": "បច្ចុប្បន្នបានចូលជា",
		"ky": "Учурда Каттоо эсебине кирген",
		"lo": "ປັດຈຸບັນເຂົ້າສູ່ລະບົບເປັນ",
		"ml": "നിലവിൽ ലോഗിൻ ചെയ്‌തത് പോലെ",
		"mr": "सध्या म्हणून लॉग इन केले आहे",
		"mn": "Одоогоор нэвтэрсэн байна",
		"ne": "हाल को रूप मा लग इन",
		"pa": "ਵਰਤਮਾਨ ਵਿੱਚ ਦੇ ਰੂਪ ਵਿੱਚ ਲਾਗਇਨ ਕੀਤਾ",
		"si": "දැනට ලොග් වී ඇති ආකාරයට",
		"sw": "Hivi sasa umeingia kama",
		"ta": "தற்போது உள்நுழைந்தது",
		"te": "ప్రస్తుతం లాగ్ ఇన్ చేయబడింది",
		"ur": "فی الحال بطور لاگ ان۔",
		"zu": "Okwamanje ungene ngemvume njengo-",
	},
	"businessDashboardIsComingSoon": {
		"en": "Business Dashboard is coming soon!",
		"es": "¡Business Dashboard llegará pronto!",
		"zh": "业务仪表板即将推出！",
		"hi": "व्यापार डैशबोर्ड जल्द ही आ रहा है!",
		"ar": "لوحة تحكم الأعمال ستتوفر قريبًا!",
		"pt": "O Business Dashboard estará disponível em breve!",
		"bn": "ব্যবসা ড্যাশবোর্ড শীঘ্রই আসছে!",
		"ru": "Business Dashboard скоро появится!",
		"ja": "ビジネスダッシュボードはもうすぐです！",
		"de": "Das Business-Dashboard kommt bald!",
		"ms": "Papan Pemuka Perniagaan akan hadir tidak lama lagi!",
		"vi": "Trang tổng quan doanh nghiệp sắp ra mắt!",
		"fr": "Le tableau de bord d'entreprise arrive bientôt!",
		"tr": "Business Dashboard yakında geliyor!",
		"it": "Dashboard aziendale è in arrivo!",
		"th": "แดชบอร์ดธุรกิจกำลังจะมาเร็ว ๆ นี้!",
		"fa": "داشبورد کسب و کار به زودی راه اندازی می شود!",
		"pl": "Panel biznesowy już wkrótce!",
		"uk": "Бізнес -панель скоро з’явиться!",
		"uz": "Biznes boshqaruv paneli tez orada keladi!",
		"ro": "Tabloul de bord pentru afaceri vine în curând!",
		"az": "Business Dashboard tezliklə gəlir!",
		"hr": "Poslovna nadzorna ploča uskoro stiže!",
		"bg": "Business Dashboard идва скоро!",
		"sr": "Бусинесс Дасхбоард стиже ускоро!",
		"hy": "Բիզնեսի կառավարման վահանակը շուտով կգա:",
		"eu": "Enpresen panela laster da!",
		"be": "Business Dashboard хутка з'явіцца!",
		"ca": "Business Dashboard aviat arribarà.",
		"cs": "Business Dashboard již brzy!",
		"da": "Business Dashboard kommer snart!",
		"nl": "Bedrijfsdashboard komt eraan!",
		"et": "Äri juhtpaneel on peagi saadaval!",
		"tl": "Malapit na ang Business Dashboard!",
		"gl": "O Dashboard empresarial estará en breve.",
		"ka": "ბიზნეს დაფა მალე გამოჩნდება!",
		"el": "Ο Πίνακας Επιχειρήσεων έρχεται σύντομα!",
		"hu": "Az üzleti irányítópult hamarosan elérhető!",
		"is": "Viðskipta mælaborð kemur fljótlega!",
		"lv": "Biznesa informācijas panelis būs pieejams drīz!",
		"lt": "Verslo informacijos suvestinė netrukus!",
		"mk": "Бизнис контролната табла доаѓа наскоро!",
		"no": "Business Dashboard kommer snart!",
		"sk": "Business Dashboard bude čoskoro k dispozícii!",
		"sl": "Poslovna nadzorna plošča prihaja kmalu!",
		"sv": "Business Dashboard kommer snart!",
		"fi": "Business Dashboard on tulossa pian!",
		"af": "Business Dashboard kom binnekort!",
		"sq": "Paneli i Biznesit vjen së shpejti!",
		"am": "የቢዝነስ ዳሽቦርድ በቅርቡ ይመጣል!",
		"my": "Business Dashboard မကြာမီလာတော့မည်။",
		"gu": "વ્યવસાય ડેશબોર્ડ ટૂંક સમયમાં આવી રહ્યું છે!",
		"he": "לוח המחוונים העסקי יגיע בקרוב!",
		"id": "Dasbor Bisnis akan segera hadir!",
		"kn": "ವ್ಯಾಪಾರ ಡ್ಯಾಶ್‌ಬೋರ್ಡ್ ಶೀಘ್ರದಲ್ಲೇ ಬರಲಿದೆ!",
		"kk": "Іскерлік бақылау тақтасы жақында шығады!",
		"km": "ផ្ទាំងព័ត៌មានពាណិជ្ជកម្មនឹងមកដល់ក្នុងពេលឆាប់ៗនេះ!",
		"ky": "Бизнес тактасы жакында келет!",
		"lo": "ແຜງ ໜ້າ ປັດທຸລະກິດຈະມາໃນໄວ soon ນີ້!",
		"ml": "ബിസിനസ് ഡാഷ്‌ബോർഡ് ഉടൻ വരുന്നു!",
		"mr": "व्यवसाय डॅशबोर्ड लवकरच येत आहे!",
		"mn": "Бизнесийн хяналтын самбар удахгүй гарна!",
		"ne": "व्यापार ड्यासबोर्ड चाँडै आउँदैछ!",
		"pa": "ਵਪਾਰ ਡੈਸ਼ਬੋਰਡ ਜਲਦੀ ਆ ਰਿਹਾ ਹੈ!",
		"si": "ව්‍යාපාර උපකරණ පුවරුව ළඟදීම පැමිණේ!",
		"sw": "Dashibodi ya Biashara inakuja hivi karibuni!",
		"ta": "வணிக டாஷ்போர்டு விரைவில் வருகிறது!",
		"te": "బిజినెస్ డాష్‌బోర్డ్ త్వరలో వస్తుంది!",
		"ur": "بزنس ڈیش بورڈ جلد آرہا ہے!",
		"zu": "Ideshibhodi yebhizinisi iyeza maduze!",
	},
	"forItemManagementPleaseUseTopRightDropdownMenu": {
		"en": "For item management, please use the top-right dropdown menu.",
		"es": "Para la gestión de artículos, utilice el menú desplegable de la parte superior derecha.",
		"zh": "对于项目管理，请使用右上角的下拉菜单。",
		"hi": "आइटम प्रबंधन के लिए, कृपया शीर्ष-दाएं ड्रॉपडाउन मेनू का उपयोग करें।",
		"ar": "لإدارة العناصر ، يرجى استخدام القائمة المنسدلة العلوية اليمنى.",
		"pt": "Para gerenciamento de itens, use o menu suspenso no canto superior direito.",
		"bn": "আইটেম পরিচালনার জন্য, দয়া করে উপরের ডানদিকে ড্রপডাউন মেনু ব্যবহার করুন।",
		"ru": "Для управления товарами используйте раскрывающееся меню в правом верхнем углу.",
		"ja": "アイテム管理については、右上のドロップダウンメニューを使用してください。",
		"de": "Für die Artikelverwaltung verwenden Sie bitte das Dropdown-Menü oben rechts.",
		"ms": "Untuk pengurusan item, sila gunakan menu lungsur kanan atas.",
		"vi": "Để quản lý mặt hàng, vui lòng sử dụng menu thả xuống trên cùng bên phải.",
		"fr": "Pour la gestion des articles, veuillez utiliser le menu déroulant en haut à droite.",
		"tr": "Öğe yönetimi için lütfen sağ üstteki açılır menüyü kullanın.",
		"it": "Per la gestione degli articoli, utilizza il menu a discesa in alto a destra.",
		"th": "สำหรับการจัดการรายการ โปรดใช้เมนูดรอปดาวน์ด้านขวาบน",
		"fa": "برای مدیریت موارد ، لطفاً از منوی کشویی بالا سمت راست استفاده کنید.",
		"pl": "Aby zarządzać przedmiotami, użyj menu rozwijanego w prawym górnym rogu.",
		"uk": "Для управління елементами використовуйте спадне меню у верхньому правому куті.",
		"uz": "Ob'ektlarni boshqarish uchun yuqori o'ngdagi ochiladigan menyudan foydalaning.",
		"ro": "Pentru gestionarea articolelor, vă rugăm să utilizați meniul derulant din partea dreaptă sus.",
		"az": "Maddə idarəçiliyi üçün yuxarı sağ açılan menyudan istifadə edin.",
		"hr": "Za upravljanje stavkama upotrijebite padajući izbornik u gornjem desnom kutu.",
		"bg": "За управление на артикули, моля, използвайте горното дясно падащо меню.",
		"sr": "За управљање ставкама користите горњи десни падајући мени.",
		"hy": "Նյութերի կառավարման համար օգտագործեք վերևի աջ անկյունում բացվող ընտրացանկը:",
		"eu": "Elementuak kudeatzeko, erabili goiko eskuineko goitibeherako menua.",
		"be": "Для кіравання элементамі выкарыстоўвайце выпадальнае меню ў правым верхнім куце.",
		"ca": "Per a la gestió d’elements, utilitzeu el menú desplegable superior dret.",
		"cs": "Pro správu položek použijte rozbalovací nabídku vpravo nahoře.",
		"da": "For elementhåndtering skal du bruge rullemenuen øverst til højre.",
		"nl": "Gebruik voor itembeheer het vervolgkeuzemenu rechtsboven.",
		"et": "Üksuste haldamiseks kasutage paremas ülanurgas olevat rippmenüüd.",
		"tl": "Para sa pamamahala ng item, mangyaring gamitin ang kanang tuktok na dropdown na menu.",
		"gl": "Para a xestión de elementos, use o menú despregable superior dereito.",
		"ka": "ერთეულის მართვისთვის გთხოვთ გამოიყენოთ ზედა მარცხენა ჩამოსაშლელი მენიუ.",
		"el": "Για τη διαχείριση στοιχείων, χρησιμοποιήστε το αναπτυσσόμενο μενού πάνω δεξιά.",
		"hu": "Az elemek kezeléséhez használja a jobb felső sarokban található legördülő menüt.",
		"is": "Vinsamlegast hafðu valmyndina efst til hægri fyrir vörustjórnun.",
		"lv": "Lai pārvaldītu vienumus, lūdzu, izmantojiet nolaižamo izvēlni augšējā labajā stūrī.",
		"lt": "Norėdami valdyti elementus, naudokite viršutiniame dešiniajame išskleidžiamajame meniu.",
		"mk": "За управување со ставки, користете го паѓачкото мени горе-десно.",
		"no": "For elementhåndtering, bruk rullegardinmenyen øverst til høyre.",
		"sk": "Na správu položiek použite rozbaľovaciu ponuku vpravo hore.",
		"sl": "Za upravljanje elementov uporabite zgornji desni spustni meni.",
		"sv": "För objekthantering, använd rullgardinsmenyn längst upp till höger.",
		"fi": "Tuotteiden hallintaan käytä oikeassa yläkulmassa olevaa avattavaa valikkoa.",
		"af": "Gebruik die keuselys regs bo vir itembestuur.",
		"sq": "Për menaxhimin e artikujve, ju lutemi përdorni menunë zbritëse lart-djathtas.",
		"am": "ለንጥል አስተዳደር ፣ እባክዎን ከላይ በስተቀኝ ያለውን ተቆልቋይ ምናሌ ይጠቀሙ።",
		"my": "ပစ္စည်းစီမံခန့်ခွဲမှုအတွက် ကျေးဇူးပြု၍ ညာဘက်အပေါ်အောက်ဆွဲချမီနူးကိုသုံးပါ။",
		"gu": "આઇટમ મેનેજમેન્ટ માટે, કૃપા કરીને ઉપર-જમણે ડ્રોપડાઉન મેનૂનો ઉપયોગ કરો.",
		"he": "לניהול פריטים, אנא השתמש בתפריט הנפתח הימני העליון.",
		"id": "Untuk pengelolaan item, gunakan menu tarik-turun di kanan atas.",
		"kn": "ಐಟಂ ನಿರ್ವಹಣೆಗಾಗಿ, ದಯವಿಟ್ಟು ಮೇಲಿನ-ಬಲ ಡ್ರಾಪ್‌ಡೌನ್ ಮೆನುವನ್ನು ಬಳಸಿ.",
		"kk": "Элементтерді басқару үшін жоғарғы оң жақ ашылмалы мәзірді пайдаланыңыз.",
		"km": "សម្រាប់ការគ្រប់គ្រងវត្ថុសូមប្រើម៉ឺនុយទម្លាក់ចុះខាងស្តាំខាងលើ",
		"ky": "Элементти башкаруу үчүн, жогорку оң жактагы ачылуучу менюну колдонуңуз.",
		"lo": "ສໍາລັບການຈັດການລາຍການ, ກະລຸນາໃຊ້ເມນູເລື່ອນລົງທາງເທິງເບື້ອງຂວາ.",
		"ml": "ഇനം മാനേജ്മെന്റിനായി, മുകളിൽ വലത് ഡ്രോപ്പ്ഡൗൺ മെനു ഉപയോഗിക്കുക.",
		"mr": "आयटम व्यवस्थापनासाठी, कृपया वर-उजवा ड्रॉपडाउन मेनू वापरा.",
		"mn": "Барааны менежментийн хувьд баруун дээд буланд байрлах цэсийг ашиглана уу.",
		"ne": "आइटम व्यवस्थापन को लागी, कृपया माथिल्लो दायाँ ड्रपडाउन मेनु को उपयोग गर्नुहोस्।",
		"pa": "ਆਈਟਮ ਪ੍ਰਬੰਧਨ ਲਈ, ਕਿਰਪਾ ਕਰਕੇ ਉੱਪਰ-ਸੱਜੇ ਡ੍ਰੌਪਡਾਉਨ ਮੀਨੂ ਦੀ ਵਰਤੋਂ ਕਰੋ.",
		"si": "අයිතම කළමනාකරණය සඳහා, කරුණාකර ඉහළ දකුණේ පතන මෙනුව භාවිතා කරන්න.",
		"sw": "Kwa usimamizi wa kipengee, tafadhali tumia menyu ya kulia ya juu kulia.",
		"ta": "உருப்படி மேலாண்மைக்கு, மேல்-வலது கீழ்தோன்றும் மெனுவைப் பயன்படுத்தவும்.",
		"te": "అంశాల నిర్వహణ కోసం, దయచేసి ఎగువ-కుడి డ్రాప్‌డౌన్ మెనుని ఉపయోగించండి.",
		"ur": "آئٹم مینجمنٹ کے لیے ، براہ کرم اوپر دائیں ڈراپ ڈاؤن مینو استعمال کریں۔",
		"zu": "Ukuphathwa kwento, sicela usebenzise imenyu yokudonsela phezulu kwesokudla.",
	},
	"manageItems": {
		"en": "Manage Items",
		"es": "Administrar artículos",
		"zh": "管理项目",
		"hi": "आइटम प्रबंधित करें",
		"ar": "إدارة العناصر",
		"pt": "Gerenciar itens",
		"bn": "আইটেমগুলি পরিচালনা করুন",
		"ru": "Управлять предметами",
		"ja": "アイテムの管理",
		"de": "Artikel verwalten",
		"ms": "Urus Item",
		"vi": "Quản lý các mặt hàng",
		"fr": "Gérer les articles",
		"tr": "Öğeleri Yönet",
		"it": "Gestisci oggetti",
		"th": "จัดการรายการ",
		"fa": "مدیریت موارد",
		"pl": "Zarządzaj przedmiotami",
		"uk": "Керувати елементами",
		"uz": "Ob'ektlarni boshqarish",
		"ro": "Gestionați articolele",
		"az": "Maddələri idarə edin",
		"hr": "Upravljanje stavkama",
		"bg": "Управление на елементи",
		"sr": "Управљање ставкама",
		"hy": "Կառավարեք տարրերը",
		"eu": "Kudeatu elementuak",
		"be": "Кіраванне элементамі",
		"ca": "Gestiona els elements",
		"cs": "Spravovat položky",
		"da": "Administrer varer",
		"nl": "Items beheren",
		"et": "Üksuste haldamine",
		"tl": "Pamahalaan ang Mga Item",
		"gl": "Xestionar elementos",
		"ka": "ნივთების მართვა",
		"el": "Διαχείριση στοιχείων",
		"hu": "Elemek kezelése",
		"is": "Stjórna hlutum",
		"lv": "Pārvaldīt vienumus",
		"lt": "Tvarkyti elementus",
		"mk": "Управување со ставки",
		"no": "Administrer elementer",
		"sk": "Spravovať položky",
		"sl": "Upravljanje elementov",
		"sv": "Hantera objekt",
		"fi": "Hallitse kohteita",
		"af": "Bestuur items",
		"sq": "Menaxhoni artikujt",
		"am": "ንጥሎችን ያቀናብሩ",
		"my": "ပစ္စည်းများစီမံပါ",
		"gu": "વસ્તુઓ મેનેજ કરો",
		"he": "נהל פריטים",
		"id": "Kelola Item",
		"kn": "ವಸ್ತುಗಳನ್ನು ನಿರ್ವಹಿಸಿ",
		"kk": "Элементтерді басқару",
		"km": "គ្រប់គ្រងធាតុ",
		"ky": "Элементтерди башкаруу",
		"lo": "ຈັດການລາຍການ",
		"ml": "ഇനങ്ങൾ കൈകാര്യം ചെയ്യുക",
		"mr": "आयटम व्यवस्थापित करा",
		"mn": "Зүйлийг удирдах",
		"ne": "वस्तुहरु प्रबन्ध गर्नुहोस्",
		"pa": "ਆਈਟਮਾਂ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰੋ",
		"si": "අයිතම කළමනාකරණය කරන්න",
		"sw": "Dhibiti Vitu",
		"ta": "பொருட்களை நிர்வகிக்கவும்",
		"te": "అంశాలను నిర్వహించండి",
		"ur": "اشیاء کا انتظام کریں۔",
		"zu": "Phatha izinto",
	},
	"login": {
		"en": "Log In",
		"es": "Iniciar sesión",
		"zh": "登录",
		"hi": "लॉग इन करें",
		"ar": "تسجيل دخول",
		"pt": "Conecte-se",
		"bn": "প্রবেশ করুন",
		"ru": "Авторизоваться",
		"ja": "ログインする",
		"de": "Einloggen",
		"ms": "Log masuk",
		"vi": "Đăng nhập",
		"fr": "Connexion",
		"tr": "Giriş yapmak",
		"it": "Accesso",
		"th": "เข้าสู่ระบบ",
		"fa": "وارد شدن",
		"pl": "Zaloguj sie",
		"uk": "Увійти",
		"uz": "Kirish",
		"ro": "Autentificare",
		"az": "Daxil ol",
		"hr": "Prijaviti se",
		"bg": "Влизам",
		"sr": "Пријавите се",
		"hy": "Մուտք գործել",
		"eu": "Saioa hasi",
		"be": "Увайсці",
		"ca": "Iniciar Sessió",
		"cs": "Přihlásit se",
		"da": "Log på",
		"nl": "Log in",
		"et": "Logi sisse",
		"tl": "Mag log in",
		"gl": "Iniciar sesión",
		"ka": "Შესვლა",
		"el": "Σύνδεση",
		"hu": "Belépés",
		"is": "Skrá inn",
		"lv": "Pieslēgties",
		"lt": "Prisijungti",
		"mk": "Логирај Се",
		"no": "Logg Inn",
		"sk": "Prihlásiť sa",
		"sl": "Vpiši se",
		"sv": "Logga in",
		"fi": "Kirjaudu sisään",
		"af": "Teken aan",
		"sq": "Identifikohu",
		"am": "ግባ",
		"my": "လော့ဂ်အင်",
		"gu": "પ્રવેશ કરો",
		"he": "התחברות",
		"id": "Gabung",
		"kn": "ಲಾಗ್ ಇನ್ ಮಾಡಿ",
		"kk": "Кіру",
		"km": "ចូល",
		"ky": "Кирүү",
		"lo": "ເຂົ້າ​ສູ່​ລະ​ບົບ",
		"ml": "ലോഗിൻ",
		"mr": "लॉग इन करा",
		"mn": "Нэвтрэх",
		"ne": "लग - इन",
		"pa": "ਲਾਗਿਨ",
		"si": "ඇතුල් වන්න",
		"sw": "Ingia",
		"ta": "உள்நுழைய",
		"te": "ప్రవేశించండి",
		"ur": "لاگ ان کریں",
		"zu": "Ngena ngemvume",
	},
	"logout": {
		"en": "Log Out",
		"es": "Cerrar sesión",
		"zh": "登出",
		"hi": "लॉग आउट",
		"ar": "تسجيل خروج",
		"pt": "Sair",
		"bn": "প্রস্থান",
		"ru": "Выйти",
		"ja": "ログアウト",
		"de": "Ausloggen",
		"ms": "Log keluar",
		"vi": "Đăng xuất",
		"fr": "Se déconnecter",
		"tr": "Çıkış Yap",
		"it": "Disconnettersi",
		"th": "ออกจากระบบ",
		"fa": "خروج",
		"pl": "Wyloguj",
		"uk": "Вийти",
		"uz": "Chiqish",
		"ro": "Deconectați-vă",
		"az": "Çıxış",
		"hr": "Odjavite se",
		"bg": "Излез от профила си",
		"sr": "Одјавити се",
		"hy": "Ելք",
		"eu": "Saioa amaitu",
		"be": "Выйсці",
		"ca": "Tancar sessió",
		"cs": "Odhlásit se",
		"da": "Log ud",
		"nl": "Uitloggen",
		"et": "Logi välja",
		"tl": "Mag-log Out",
		"gl": "Pechar sesión",
		"ka": "გასვლა",
		"el": "Αποσύνδεση",
		"hu": "Kijelentkezés",
		"is": "Að skrá þig út",
		"lv": "Izlogoties",
		"lt": "Atsijungti",
		"mk": "Одјавете се",
		"no": "Logg ut",
		"sk": "Odhlásiť sa",
		"sl": "Odjava",
		"sv": "Logga ut",
		"fi": "Kirjautua ulos",
		"af": "Teken uit",
		"sq": "Shkyç",
		"am": "ውጣ",
		"my": "ထွက်ရန်",
		"gu": "લૉગ આઉટ",
		"he": "להתנתק",
		"id": "Keluar",
		"kn": "ಲಾಗ್ ಔಟ್",
		"kk": "Шығу",
		"km": "ចាកចេញ",
		"ky": "Чыгуу",
		"lo": "ອອກ​ຈາກ​ລະ​ບົບ",
		"ml": "ലോഗ് .ട്ട് ചെയ്യുക",
		"mr": "बाहेर पडणे",
		"mn": "Гарах",
		"ne": "बाहिर निस्कनु",
		"pa": "ਲਾੱਗ ਆਊਟ, ਬਾਹਰ ਆਉਣਾ",
		"si": "ඉවත් වන්න",
		"sw": "Ingia",
		"ta": "வெளியேறு",
		"te": "లాగ్ అవుట్",
		"ur": "لاگ آوٹ",
		"zu": "Phuma",
	},
	"downloadQRCode": {
		"en": "Download QR Code",
		"es": "Descargar código QR",
		"zh": "下载二维码",
		"hi": "क्यूआर कोड डाउनलोड करें",
		"ar": "تنزيل QR Code",
		"pt": "Baixe o código QR",
		"bn": "কিউআর কোড ডাউনলোড করুন",
		"ru": "Загрузить QR-код",
		"ja": "QRコードをダウンロード",
		"de": "QR-Code herunterladen",
		"ms": "Muat turun Kod QR",
		"vi": "Tải xuống mã QR",
		"fr": "Télécharger le code QR",
		"tr": "QR Kodunu İndirin",
		"it": "Scarica il codice QR",
		"th": "ดาวน์โหลด QR Code",
		"fa": "QR Code را بارگیری کنید",
		"pl": "Pobierz kod QR",
		"uk": "Завантажте QR -код",
		"uz": "QR kodini yuklab oling",
		"ro": "Descărcați codul QR",
		"az": "QR kodunu yükləyin",
		"hr": "Preuzmite QR kod",
		"bg": "Изтеглете QR код",
		"sr": "Преузмите КР код",
		"hy": "Ներբեռնեք QR կոդը",
		"eu": "Deskargatu QR kodea",
		"be": "Спампаваць QR -код",
		"ca": "Descarregueu el codi QR",
		"cs": "Stáhněte si QR kód",
		"da": "Download QR -kode",
		"nl": "QR-code downloaden",
		"et": "Laadige alla QR -kood",
		"tl": "Mag-download ng QR Code",
		"gl": "Descarga o código QR",
		"ka": "ჩამოტვირთეთ QR კოდი",
		"el": "Λήψη κώδικα QR",
		"hu": "QR -kód letöltése",
		"is": "Sæktu QR kóða",
		"lv": "Lejupielādējiet QR kodu",
		"lt": "Atsisiųskite QR kodą",
		"mk": "Преземете QR -код",
		"no": "Last ned QR -koden",
		"sk": "Stiahnite si QR kód",
		"sl": "Prenesite QR kodo",
		"sv": "Ladda ner QR -kod",
		"fi": "Lataa QR -koodi",
		"af": "Laai die QR -kode af",
		"sq": "Shkarkoni kodin QR",
		"am": "የ QR ኮድ ያውርዱ",
		"my": "QR Code ကို download လုပ်ပါ",
		"gu": "QR કોડ ડાઉનલોડ કરો",
		"he": "הורד קוד QR",
		"id": "Unduh Kode QR",
		"kn": "ಕ್ಯೂಆರ್ ಕೋಡ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
		"kk": "QR кодын жүктеп алыңыз",
		"km": "ទាញយកលេខកូដ QR",
		"ky": "QR кодун жүктөп алыңыз",
		"lo": "ດາວໂລດລະຫັດ QR",
		"ml": "QR കോഡ് ഡൗൺലോഡ് ചെയ്യുക",
		"mr": "QR कोड डाउनलोड करा",
		"mn": "QR кодыг татаж авах",
		"ne": "QR कोड डाउनलोड गर्नुहोस्",
		"pa": "QR ਕੋਡ ਡਾਉਨਲੋਡ ਕਰੋ",
		"si": "QR කේතය බාගන්න",
		"sw": "Pakua Msimbo wa QR",
		"ta": "QR குறியீட்டைப் பதிவிறக்கவும்",
		"te": "QR కోడ్‌ను డౌన్‌లోడ్ చేయండి",
		"ur": "کیو آر کوڈ ڈاؤن لوڈ کریں۔",
		"zu": "Landa i-QR Code",
	},
	"searchItemOrCategory": {
		"en": "Search Item or Category",
		"es": "Buscar artículo o categoría",
		"zh": "搜索项目或类别",
		"hi": "खोज वस्तु या श्रेणी",
		"ar": "بحث عن عنصر أو فئة",
		"pt": "Pesquisar item ou categoria",
		"bn": "আইটেম বা বিভাগ অনুসন্ধান করুন",
		"ru": "Искать элемент или категорию",
		"ja": "アイテムまたはカテゴリを検索",
		"de": "Artikel oder Kategorie suchen",
		"ms": "Cari Item atau Kategori",
		"vi": "Mục hoặc Danh mục Tìm kiếm",
		"fr": "Rechercher un article ou une catégorie",
		"tr": "Öğe veya Kategori Ara",
		"it": "Cerca articolo o categoria",
		"th": "ค้นหารายการหรือหมวดหมู่",
		"fa": "مورد یا دسته را جستجو کنید",
		"pl": "Wyszukaj przedmiot lub kategorię",
		"uk": "Шукати елемент або категорію",
		"uz": "Qidiruv elementi yoki toifasi",
		"ro": "Căutați element sau categorie",
		"az": "Element və ya Kateqoriya axtarın",
		"hr": "Stavka ili kategorija pretraživanja",
		"bg": "Търсете елемент или категория",
		"sr": "Претражите ставку или категорију",
		"hy": "Որոնել նյութ կամ կատեգորիա",
		"eu": "Bilatu elementua edo kategoria",
		"be": "Пункт пошуку або катэгорыя",
		"ca": "Cerca un element o una categoria",
		"cs": "Hledat položku nebo kategorii",
		"da": "Søg element eller kategori",
		"nl": "Zoek item of categorie",
		"et": "Otsige üksust või kategooriat",
		"tl": "Item sa Paghahanap o Kategoryang",
		"gl": "Buscar elemento ou categoría",
		"ka": "მოძებნეთ ერთეული ან კატეგორია",
		"el": "Αναζήτηση στοιχείου ή κατηγορίας",
		"hu": "Tétel vagy kategória keresése",
		"is": "Leitaðu atriði eða flokk",
		"lv": "Meklēt vienumu vai kategoriju",
		"lt": "Ieškoti elemento ar kategorijos",
		"mk": "Барај ставка или категорија",
		"no": "Søk element eller kategori",
		"sk": "Hľadajte položku alebo kategóriu",
		"sl": "Išči element ali kategorijo",
		"sv": "Sök objekt eller kategori",
		"fi": "Etsi kohde tai luokka",
		"af": "Soek item of kategorie",
		"sq": "Kërko artikull ose kategori",
		"am": "ንጥል ወይም ምድብ ይፈልጉ",
		"my": "ပစ္စည်း (သို့) အမျိုးအစားကိုရှာပါ",
		"gu": "આઇટમ અથવા કેટેગરી શોધો",
		"he": "חפש פריט או קטגוריה",
		"id": "Cari Item atau Kategori",
		"kn": "ಹುಡುಕಾಟ ಐಟಂ ಅಥವಾ ವರ್ಗ",
		"kk": "Элементті немесе санатты іздеңіз",
		"km": "ស្វែងរកធាតុឬប្រភេទ",
		"ky": "Элементти же категорияны издөө",
		"lo": "ຄົ້ນຫາລາຍການຫຼືCategoryວດູ່",
		"ml": "ഇനം അല്ലെങ്കിൽ വിഭാഗം തിരയുക",
		"mr": "आयटम किंवा श्रेणी शोधा",
		"mn": "Зүйл эсвэл ангилал хайх",
		"ne": "खोज आइटम वा श्रेणी",
		"pa": "ਖੋਜ ਆਈਟਮ ਜਾਂ ਸ਼੍ਰੇਣੀ",
		"si": "අයිතමය හෝ ප්‍රවර්ගය සොයන්න",
		"sw": "Tafuta Bidhaa au Jamii",
		"ta": "தேடல் பொருள் அல்லது வகை",
		"te": "శోధన అంశం లేదా వర్గం",
		"ur": "آئٹم یا زمرہ تلاش کریں۔",
		"zu": "Sesha Into noma Isigaba",
	},
	"itemName": {
		"en": "Item Name",
		"es": "Nombre del árticulo",
		"zh": "项目名称",
		"hi": "आइटम नाम",
		"ar": "اسم العنصر",
		"pt": "Nome do item",
		"bn": "আইটেম নাম",
		"ru": "Имя элемента",
		"ja": "項目名",
		"de": "Artikelname",
		"ms": "Nama barang",
		"vi": "Tên mục",
		"fr": "Nom de l'article",
		"tr": "Öğe adı",
		"it": "Nome dell'oggetto",
		"th": "ชื่อสินค้า",
		"fa": "نام آیتم",
		"pl": "Nazwa przedmiotu",
		"uk": "Назва виробу",
		"uz": "Ob'ekt nomi",
		"ro": "Nume articol",
		"az": "Maddə Adı",
		"hr": "Ime proizvoda",
		"bg": "Име на предмета",
		"sr": "Назив производа",
		"hy": "Նյութի անվանումը",
		"eu": "Elementuaren izena",
		"be": "Назва тавару",
		"ca": "Nom de l'ítem",
		"cs": "Název položky",
		"da": "Tingens navn",
		"nl": "Itemnaam",
		"et": "Asja nimi",
		"tl": "Pangalan ng Item",
		"gl": "Nome do elemento",
		"ka": "ნივთის სახელი",
		"el": "Ονομα προϊόντος",
		"hu": "Termék név",
		"is": "Nafn hlutar",
		"lv": "Priekšmeta nosaukums",
		"lt": "Daikto pavadinimas",
		"mk": "Име на предметот",
		"no": "Gjenstandsnavn",
		"sk": "Názov položky",
		"sl": "Ime predmeta",
		"sv": "Föremålsnamn",
		"fi": "Tuotteen nimi",
		"af": "Item naam",
		"sq": "Emri i artikullit",
		"am": "የእቃ ስም",
		"my": "ပစ္စည်းအမည်",
		"gu": "વસ્તુનુ નામ",
		"he": "שם הפריט",
		"id": "Nama barang",
		"kn": "ವಸ್ತುವಿನ ಹೆಸರು",
		"kk": "Элемент атауы",
		"km": "ឈ្មោះ​ធាតុ",
		"ky": "Элементтин аталышы",
		"lo": "ຊື່ລາຍການ",
		"ml": "ഇനത്തിന്റെ പേര്",
		"mr": "आयटमचे नाव",
		"mn": "Барааны нэр",
		"ne": "आइटम को नाम",
		"pa": "ਆਈਟਮ ਦਾ ਨਾਮ",
		"si": "භාණ්ඩයෙ නම",
		"sw": "Jina la Bidhaa",
		"ta": "பொருளின் பெயர்",
		"te": "వస్తువు పేరు",
		"ur": "شے کا نام",
		"zu": "Igama lento",
	},
	"itemType": {
		"en": "Item Type",
		"es": "Tipo de artículo",
		"zh": "物品种类",
		"hi": "वस्तु परक",
		"ar": "نوع العنصر",
		"pt": "Tipo de item",
		"bn": "বস্তু",
		"ru": "Тип вещи",
		"ja": "アイテムタイプ",
		"de": "Gegenstandsart",
		"ms": "Jenis barang",
		"vi": "Loại sản phẩm",
		"fr": "Type d'élément",
		"tr": "Öğe türü",
		"it": "Tipo di elemento",
		"th": "ประเภทรายการ",
		"fa": "نوع آیتم",
		"pl": "Typ przedmiotu",
		"uk": "Тип елемента",
		"uz": "Element turi",
		"ro": "Categorie de obiect",
		"az": "Maddə Növü",
		"hr": "Vrsta stavke",
		"bg": "Тип артикул",
		"sr": "Тип ставка",
		"hy": "Նյութի տեսակը",
		"eu": "Elementu mota",
		"be": "Тып прадмета",
		"ca": "Tipus d’element",
		"cs": "Typ položky",
		"da": "Varetype",
		"nl": "Type voorwerp",
		"et": "Eseme tüüp",
		"tl": "Uri ng Item",
		"gl": "Tipo de elemento",
		"ka": "საქონლის ტიპი",
		"el": "Τύπος αντικειμένου",
		"hu": "Tárgy típus",
		"is": "Gerð hlutar",
		"lv": "Vienuma veids",
		"lt": "Elemento tipas",
		"mk": "Тип на ставка",
		"no": "Elementtype",
		"sk": "Typ položky",
		"sl": "Vrsta predmeta",
		"sv": "Objekttyp",
		"fi": "Kohteen tyyppi",
		"af": "Art tipe",
		"sq": "Lloji i artikullit",
		"am": "የእቃ ዓይነት",
		"my": "ပစ္စည်းအမျိုးအစား",
		"gu": "આઇટમનો પ્રકાર",
		"he": "סוג פריט",
		"id": "Tipe barang",
		"kn": "ಐಟಂ ಪ್ರಕಾರ",
		"kk": "Элемент түрі",
		"km": "ប្រភេទធាតុ",
		"ky": "Элемент Түрү",
		"lo": "ປະເພດລາຍການ",
		"ml": "സാധനത്തിന്റെ ഇനം",
		"mr": "आयटम प्रकार",
		"mn": "Барааны төрөл",
		"ne": "आइटम प्रकार",
		"pa": "ਆਈਟਮ ਦੀ ਕਿਸਮ",
		"si": "අයිතමය වර්ගය",
		"sw": "Aina ya Bidhaa",
		"ta": "பொருள் வகை",
		"te": "వస్తువు రకము",
		"ur": "چیز کی قسم",
		"zu": "Uhlobo lwento",
	},
	"catgory": {
		"en": "Category",
		"es": "Categoría",
		"zh": "类别",
		"hi": "श्रेणी",
		"ar": "فئة",
		"pt": "Categoria",
		"bn": "বিভাগ",
		"ru": "Категория",
		"ja": "カテゴリー",
		"de": "Kategorie",
		"ms": "Kategori",
		"vi": "Loại",
		"fr": "Catégorie",
		"tr": "Kategori",
		"it": "Categoria",
		"th": "หมวดหมู่",
		"fa": "دسته بندی",
		"pl": "Kategoria",
		"uk": "Категорія",
		"uz": "Turkum",
		"ro": "Categorie",
		"az": "Kateqoriya",
		"hr": "Kategorija",
		"bg": "Категория",
		"sr": "Категорија",
		"hy": "Կատեգորիա",
		"eu": "Kategoria",
		"be": "Катэгорыя",
		"ca": "Categoria",
		"cs": "Kategorie",
		"da": "Kategori",
		"nl": "Categorie",
		"et": "Kategooria",
		"tl": "Kategoryang",
		"gl": "Categoría",
		"ka": "კატეგორია",
		"el": "Κατηγορία",
		"hu": "Kategória",
		"is": "Flokkur",
		"lv": "Kategorija",
		"lt": "Kategorija",
		"mk": "Категорија",
		"no": "Kategori",
		"sk": "Kategória",
		"sl": "Kategorija",
		"sv": "Kategori",
		"fi": "Kategoria",
		"af": "Kategorie",
		"sq": "Kategoria",
		"am": "ምድብ",
		"my": "အမျိုးအစား",
		"gu": "શ્રેણી",
		"he": "קטגוריה",
		"id": "Kategori",
		"kn": "ವರ್ಗ",
		"kk": "Санат",
		"km": "ប្រភេទ",
		"ky": "Категория",
		"lo": "ປະເພດ",
		"ml": "വിഭാഗം",
		"mr": "श्रेणी",
		"mn": "Ангилал",
		"ne": "श्रेणी",
		"pa": "ਸ਼੍ਰੇਣੀ",
		"si": "වර්ගය",
		"sw": "Jamii",
		"ta": "வகை",
		"te": "వర్గం",
		"ur": "قسم",
		"zu": "Isigaba",
	},
	"unitPrice": {
		"en": "Unit Price",
		"es": "Precio unitario",
		"zh": "单价",
		"hi": "यूनिट मूल्य",
		"ar": "سعر الوحدة",
		"pt": "Preço unitário",
		"bn": "একক দাম",
		"ru": "Цена за единицу",
		"ja": "単価",
		"de": "Stückpreis",
		"ms": "Harga seunit",
		"vi": "Đơn giá",
		"fr": "Prix unitaire",
		"tr": "Birim fiyat",
		"it": "Prezzo unitario",
		"th": "ราคาต่อหน่วย",
		"fa": "قیمت واحد",
		"pl": "Cena jednostkowa",
		"uk": "Ціна за одиницю",
		"uz": "Donasining narxi",
		"ro": "Preț unitar",
		"az": "Vahid qiymət",
		"hr": "Jedinična cijena",
		"bg": "Единична цена",
		"sr": "Цена по јединици",
		"hy": "Միավոր գինը",
		"eu": "Prezio unitarioa",
		"be": "Цана за адзінку",
		"ca": "Preu unitari",
		"cs": "Jednotková cena",
		"da": "Pris per stk",
		"nl": "Stuksprijs",
		"et": "Ühikuhind",
		"tl": "Presyo ng isang piraso",
		"gl": "Prezo por unidade",
		"ka": "Ერთეულის ფასი",
		"el": "Τιμή μονάδας",
		"hu": "Egységár",
		"is": "Einingaverð",
		"lv": "Vienības cena",
		"lt": "Vieneto kaina",
		"mk": "Единечната цена",
		"no": "Enhetspris",
		"sk": "Jednotková cena",
		"sl": "Cena na enoto",
		"sv": "Enhetspris",
		"fi": "Yksikköhinta",
		"af": "Eenheidsprys",
		"sq": "Çmimi i njësisë",
		"am": "ነጠላ ዋጋ",
		"my": "တစ်ခုချင်းစျေးနှုန်း",
		"gu": "એકમ કિંમત",
		"he": "מחיר ליחידה",
		"id": "Harga satuan",
		"kn": "ಘಟಕ ಬೆಲೆ",
		"kk": "Тауар өлшемінің бағасы",
		"km": "តម្លៃ​ឯកតា",
		"ky": "Бирдик баасы",
		"lo": "ລາ​ຄາ​ຕໍ່​ຫນ່ວຍ",
		"ml": "യൂണിറ്റ് വില",
		"mr": "युनिट किंमत",
		"mn": "Нэгжийн үнэ",
		"ne": "एकाइ मूल्य",
		"pa": "ਯੂਨਿਟ ਮੁੱਲ",
		"si": "ඒකක මිල",
		"sw": "Bei ya Kitengo",
		"ta": "அலகு விலை",
		"te": "యూనిట్ ధర",
		"ur": "اکائی قیمت",
		"zu": "Intengo yokukodwa",
	},
	"itemCountable": {
		"en": "Item Countable",
		"es": "Artículo contable",
		"zh": "项目可数",
		"hi": "आइटम गणनीय",
		"ar": "عنصر قابل للعد",
		"pt": "Item contável",
		"bn": "আইটেম গণনাযোগ্য",
		"ru": "Подсчет предметов",
		"ja": "アイテムカウント可能",
		"de": "Artikel zählbar",
		"ms": "Item Boleh Dikira",
		"vi": "Mặt hàng có thể đếm được",
		"fr": "Élément dénombrable",
		"tr": "Öğe Sayılabilir",
		"it": "Articolo numerabile",
		"th": "รายการที่นับได้",
		"fa": "مورد قابل شمارش",
		"pl": "Przedmiot policzalny",
		"uk": "Пункт злічуваний",
		"uz": "Hisoblanadigan element",
		"ro": "Articol numărabil",
		"az": "Maddə sayılır",
		"hr": "Stavka se može prebrojiti",
		"bg": "Артикул за преброяване",
		"sr": "Ставка пребројива",
		"hy": "Նյութը հաշվելի է",
		"eu": "Elementu zenbagarria",
		"be": "Пункт падліковы",
		"ca": "Article comptable",
		"cs": "Počitatelná položka",
		"da": "Vare tælles",
		"nl": "Artikel telbaar",
		"et": "Üksus loendatav",
		"tl": "Mabibilang ang Item",
		"gl": "Artigo contable",
		"ka": "ერთეულის დათვლა",
		"el": "Στοιχείο μετρήσιμο",
		"hu": "Tétel számlálható",
		"is": "Atriði teljanlegt",
		"lv": "Vienums saskaitāms",
		"lt": "Skaičiuojama prekė",
		"mk": "Ставка која може да се изброи",
		"no": "Vare som kan telles",
		"sk": "Položka počítateľná",
		"sl": "Artikel štet",
		"sv": "Artikel räknas",
		"fi": "Kohde laskettavissa",
		"af": "Item telbaar",
		"sq": "Artikulli i Numërueshëm",
		"am": "ንጥል ሊቆጠር የሚችል",
		"my": "ရေတွက်နိုင်သောပစ္စည်း",
		"gu": "આઇટમ ગણનાપાત્ર",
		"he": "פריט ניתן לספירה",
		"id": "Barang Dapat Dihitung",
		"kn": "ಐಟಂ ಎಣಿಕೆ",
		"kk": "Есепке алынатын элемент",
		"km": "ធាតុដែលអាចរាប់បាន",
		"ky": "Item Countable",
		"lo": "ລາຍການນັບໄດ້",
		"ml": "ഇനം എണ്ണാവുന്നതാണ്",
		"mr": "आयटम मोजण्यायोग्य",
		"mn": "Тооцоолох боломжтой зүйл",
		"ne": "आइटम गणना योग्य",
		"pa": "ਆਈਟਮ ਗਿਣਤੀਯੋਗ",
		"si": "අයිතමය ගණන් කළ හැකිය",
		"sw": "Bidhaa Inaweza Kuhesabiwa",
		"ta": "பொருள் எண்ணக்கூடியது",
		"te": "అంశం లెక్కించదగినది",
		"ur": "آئٹم قابل شمار",
		"zu": "Into Iyabalwa",
	},
	"service": {
		"en": "Service",
		"es": "Servicio",
		"zh": "服务",
		"hi": "सेवा",
		"ar": "خدمة",
		"pt": "Serviço",
		"bn": "সেবা",
		"ru": "Услуга",
		"ja": "サービス",
		"de": "Service",
		"ms": "Perkhidmatan",
		"vi": "Dịch vụ",
		"fr": "Service",
		"tr": "Hizmet",
		"it": "Servizio",
		"th": "บริการ",
		"fa": "سرویس",
		"pl": "Praca",
		"uk": "Обслуговування",
		"uz": "Xizmat",
		"ro": "Serviciu",
		"az": "Xidmət",
		"hr": "Servis",
		"bg": "Обслужване",
		"sr": "Сервице",
		"hy": "Ծառայություն",
		"eu": "Zerbitzua",
		"be": "Абслугоўванне",
		"ca": "Servei",
		"cs": "Servis",
		"da": "Service",
		"nl": "Onderhoud",
		"et": "Teenindus",
		"tl": "Serbisyo",
		"gl": "Servizo",
		"ka": "მომსახურება",
		"el": "Υπηρεσία",
		"hu": "Szolgáltatás",
		"is": "Þjónusta",
		"lv": "apkalpošana",
		"lt": "Paslauga",
		"mk": "Сервис",
		"no": "Service",
		"sk": "Služba",
		"sl": "Storitev",
		"sv": "Service",
		"fi": "Palvelu",
		"af": "Diens",
		"sq": "Shërbim",
		"am": "አገልግሎት",
		"my": "ဝန်ဆောင်မှု",
		"gu": "સેવા",
		"he": "שֵׁרוּת",
		"id": "Melayani",
		"kn": "ಸೇವೆ",
		"kk": "Қызмет",
		"km": "សេវាកម្ម",
		"ky": "Кызмат",
		"lo": "ການບໍລິການ",
		"ml": "സേവനം",
		"mr": "सेवा",
		"mn": "Үйлчилгээ",
		"ne": "सेवा",
		"pa": "ਸੇਵਾ",
		"si": "සේවා",
		"sw": "Huduma",
		"ta": "சேவை",
		"te": "సేవ",
		"ur": "خدمت۔",
		"zu": "Insiza",
	},
	"product": {
		"en": "Product",
		"es": "Producto",
		"zh": "产品",
		"hi": "उत्पाद",
		"ar": "المنتج",
		"pt": "produtos",
		"bn": "পণ্য",
		"ru": "Продукт",
		"ja": "製品",
		"de": "Produkt",
		"ms": "Produk",
		"vi": "Sản phẩm",
		"fr": "Produit",
		"tr": "Ürün",
		"it": "Prodotto",
		"th": "ผลิตภัณฑ์",
		"fa": "تولید - محصول",
		"pl": "Produkt",
		"uk": "Продукт",
		"uz": "Mahsulot",
		"ro": "Produs",
		"az": "Məhsul",
		"hr": "Proizvod",
		"bg": "Продукт",
		"sr": "Производ",
		"hy": "Արտադրանք",
		"eu": "Produktua",
		"be": "Прадукт",
		"ca": "Producte",
		"cs": "Produkt",
		"da": "Produkt",
		"nl": "Product",
		"et": "Toode",
		"tl": "Produkto",
		"gl": "Produto",
		"ka": "პროდუქტი",
		"el": "Προϊόν",
		"hu": "Termék",
		"is": "Vara",
		"lv": "Produkts",
		"lt": "Produktas",
		"mk": "Производ",
		"no": "Produkt",
		"sk": "Výrobok",
		"sl": "Izdelek",
		"sv": "Produkt",
		"fi": "Tuote",
		"af": "Produk",
		"sq": "Produkt",
		"am": "ምርት",
		"my": "ကုန်ပစ္စည်း",
		"gu": "ઉત્પાદન",
		"he": "מוצר",
		"id": "Produk",
		"kn": "ಉತ್ಪನ್ನ",
		"kk": "Өнім",
		"km": "ផលិតផល",
		"ky": "Продукт",
		"lo": "ຜະລິດຕະພັນ",
		"ml": "ഉൽപ്പന്നം",
		"mr": "उत्पादन",
		"mn": "Бүтээгдэхүүн",
		"ne": "उत्पादन",
		"pa": "ਉਤਪਾਦ",
		"si": "නිෂ්පාදන",
		"sw": "Bidhaa",
		"ta": "தயாரிப்பு",
		"te": "ఉత్పత్తి",
		"ur": "پروڈکٹ",
		"zu": "Umkhiqizo",
	},
	"yes": {
		"en": "Yes",
		"es": "sí",
		"zh": "是的",
		"hi": "हां",
		"ar": "نعم",
		"pt": "sim",
		"bn": "হ্যাঁ",
		"ru": "да",
		"ja": "はい",
		"de": "Jawohl",
		"ms": "Ya",
		"vi": "đúng",
		"fr": "Oui",
		"tr": "Evet",
		"it": "sì",
		"th": "ใช่",
		"fa": "آره",
		"pl": "tak",
		"uk": "Так",
		"uz": "Ha",
		"ro": "da",
		"az": "Bəli",
		"hr": "Da",
		"bg": "Да",
		"sr": "да",
		"hy": "Այո",
		"eu": "Bai",
		"be": "Так",
		"ca": "Sí",
		"cs": "Ano",
		"da": "Ja",
		"nl": "Ja",
		"et": "Jah",
		"tl": "Oo",
		"gl": "Si",
		"ka": "დიახ",
		"el": "Ναί",
		"hu": "Igen",
		"is": "Já",
		"lv": "Jā",
		"lt": "Taip",
		"mk": "Да",
		"no": "Ja",
		"sk": "Áno",
		"sl": "Da",
		"sv": "Ja",
		"fi": "Joo",
		"af": "Ja",
		"sq": "po",
		"am": "አዎ",
		"my": "ဟုတ်တယ်",
		"gu": "હા",
		"he": "כן",
		"id": "Ya",
		"kn": "ಹೌದು",
		"kk": "Иә",
		"km": "បាទ",
		"ky": "Ооба",
		"lo": "ແມ່ນແລ້ວ",
		"ml": "അതെ",
		"mr": "होय",
		"mn": "Тийм ээ",
		"ne": "हो",
		"pa": "ਹਾਂ",
		"si": "ඔව්",
		"sw": "Ndio",
		"ta": "ஆம்",
		"te": "అవును",
		"ur": "جی ہاں",
		"zu": "Yebo",
	},
	"no": {
		"en": "No",
		"es": "No",
		"zh": "不",
		"hi": "नहीं",
		"ar": "لا",
		"pt": "Não",
		"bn": "না",
		"ru": "Нет",
		"ja": "番号",
		"de": "Nein",
		"ms": "Tidak",
		"vi": "Không",
		"fr": "Non",
		"tr": "Numara",
		"it": "No",
		"th": "เลขที่",
		"fa": "خیر",
		"pl": "Nie",
		"uk": "Немає",
		"uz": "Yo'q",
		"ro": "Nu",
		"az": "Yox",
		"hr": "Ne",
		"bg": "Не",
		"sr": "Не",
		"hy": "Ոչ",
		"eu": "Ez",
		"be": "Не",
		"ca": "No",
		"cs": "Ne",
		"da": "Ingen",
		"nl": "Nee",
		"et": "Ei",
		"tl": "Hindi",
		"gl": "Non",
		"ka": "არა",
		"el": "Οχι",
		"hu": "Nem",
		"is": "Nei",
		"lv": "Nē",
		"lt": "Ne",
		"mk": "Бр",
		"no": "Nei",
		"sk": "Nie",
		"sl": "Ne",
		"sv": "Nej",
		"fi": "Ei",
		"af": "Geen",
		"sq": "Jo",
		"am": "አይ",
		"my": "မဟုတ်ဘူး",
		"gu": "ના",
		"he": "לא",
		"id": "Tidak",
		"kn": "ಇಲ್ಲ",
		"kk": "Жоқ",
		"km": "ទេ",
		"ky": "Жок",
		"lo": "ບໍ່",
		"ml": "ഇല്ല",
		"mr": "नाही",
		"mn": "Үгүй",
		"ne": "होइन",
		"pa": "ਨਹੀਂ",
		"si": "නැත",
		"sw": "Hapana",
		"ta": "இல்லை",
		"te": "లేదు",
		"ur": "نہیں",
		"zu": "Cha",
	},
	"businessDashboard": {
		"en": "Business Dashboard"
	},
	"paymentAmount": {
		"en": "Payment Amount"
	},
	"cashFlow": {
		"en": "Cash Flow"
	},
	"saleAmountByItem": {
		"en": "Sale Amount by Item"
	},
	"saleCountByItem": {
		"en": "Sale Count by Item"
	}
};

const LanguagePack = props => {
	let lang = props?.lang?.slice(0, 2).toLowerCase() ?? 'en'
	if (!translation[props.text])
		return '';
	if (!translation[props.text][lang])
		lang = 'en';
	return translation[props.text][lang];
}

export default LanguagePack;